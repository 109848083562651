import {NgModule} from '@angular/core';
import {NotificationService} from "./notification.service";
import {NotificationComponent} from "./notification.component";
import {BrowserModule} from "@angular/platform-browser";

@NgModule({
  declarations: [NotificationComponent],
  imports: [BrowserModule],
  providers: [NotificationService],
  exports: [NotificationComponent]
})
export class NotificationModule {}
