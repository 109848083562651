import {Component, Inject, OnDestroy, OnInit} from '@angular/core';
import {ParentInterface} from "../../../stations/station/station-spectrum/parent-interface";
import {Observable, Subscriber, Subscription} from "rxjs";
import {HttpResponse} from "@angular/common/http";
import {ApiResponse} from "../../../api.service";
import {Station, StationConfig} from "../../../stations/stations.component";
import {GroupConfig, GroupsComponent} from "../../groups.component";
import {SpectrumComponent} from "../../../common/station-spectrum/spectrum.component";
import {GroupComponent} from "../group.component";
import {WebSocketService} from "../../../common/websocket.service";
import {StorageService} from "../../../storage.service";
import {KSPParsed} from "../../../stations/station/station.component";
import {LoaderService} from "../../../loader.service";


interface SpectrumController {
  websocket: WebSocketService;
  subscription: Subscription;
  dataObservable: Observable<any>;
  dataSubscriber: Subscriber<any>;
  station: Station;
}

@Component({
  selector: 'app-group-spectrum',
  templateUrl: './group-spectrum.component.html',
  styleUrls: ['./group-spectrum.component.scss'],
})
export class GroupSpectrumComponent implements OnInit, OnDestroy, ParentInterface {

  protected init = false;
  protected spectrumControllers: SpectrumController[] = [];
  protected shouldRestartSocket = false;

  constructor(@Inject(GroupComponent) public parent: GroupComponent,
              private storageService: StorageService,
              private loaderService: LoaderService) {
  }

  parseKSP(ksp: any[], subscriber: Subscriber<any>) {
    // noinspection DuplicatedCode
    const kspParsed: KSPParsed = {
      timestampService: undefined,
      realLatency: undefined,
      vfoBandwidth: undefined,
      centerFrequency: undefined,
      vfoFrequency: undefined,
      maxAmplitude: undefined,
      doaMax: undefined,
      doaConfidence: undefined,
      updateRate: undefined,
      latency: undefined,
      spectrum: undefined,
      doaResult: undefined,
      location: undefined,
      metrics: {
        run: {
          temperature: null,
          humidity: null,
          uptime: null
        },
        gs: {
          power: null,
          battery: null,
          temperature: null,
          humidity: null,
          uptime: null
        }
      }
    };
    for (let data of ksp) {
      const [name, value] = data;
      switch (name) {
        case "ts":
          kspParsed.timestampService = value;
          break;
        case "max_amplitude":
          kspParsed.maxAmplitude = value;
          break;
        case "DoA Max":
          kspParsed.doaMax = value;
          break;
        case "update_rate":
          kspParsed.updateRate = value * 1000;  // convert to ms
          break;
        case "latency":
          kspParsed.latency = value;
          break;
        case "DoA Confidence":
          kspParsed.doaConfidence = value;
          break;
        case "center_frequency":
          kspParsed.centerFrequency = value;
          break;
        case "vfo_freq":
          kspParsed.vfoFrequency = value[0];
          break;
        case "vfo_bw":
          kspParsed.vfoBandwidth = value[0];
          break;
        case "spectrum":
          kspParsed.spectrum = value;
          break;
        case "DoA Result":
          kspParsed.doaResult = value;
          break;
        case "location":
          kspParsed.location = value;
      }
    }
    subscriber.next(kspParsed);
  }

  ngOnInit() {
    if (this.parent.item?.stations) {
      this.init = true;
      for (let station of this.parent.item.stations) {
        const spectrumController: any = {};
        const observable = new Observable(subscriber => {
          const websocket = new WebSocketService(this.storageService);
          // const serial = '1000000032358ae7';
          const subscription = websocket.subscribeStation(station.serial).subscribe(
            (message: any) => {
              if (message.ksp) {
                this.parseKSP(message.ksp, subscriber);
              }
            }
          );
          spectrumController.websocket = websocket;
          spectrumController.subscription = subscription;
          spectrumController.dataSubscriber = subscriber;
        });
        spectrumController.station = station;
        spectrumController.dataObservable = observable;
        this.spectrumControllers.push(spectrumController);
      }

      // setTimeout(() => {
      //   window.onfocus = () => {
      //     for (let controller of this.spectrumControllers) {
      //       // this.loaderService.startLoading();
      //       if (this.shouldRestartSocket) {
      //         controller.websocket.restart();
      //         this.shouldRestartSocket = false;
      //       }
      //     }
      //   };
      //   window.onblur = () => {
      //     if (document.hidden) {
      //       this.shouldRestartSocket = true;
      //       for (let controller of this.spectrumControllers) {
      //         // this.loaderService.startLoading();
      //           controller.websocket.stop();
      //       }
      //     }
      //   };
      // });

    } else {
      // TODO(s1z): this is a bullcrap, fix this to async!
      setTimeout(() => this.ngOnInit(), 100);
    }
  }

  ngOnDestroy() {
    for (let controller of this.spectrumControllers) {
      controller.subscription.unsubscribe();
      controller.websocket.stop();
      controller.dataSubscriber.complete();
    }
  }

  public setNewVfoFrequency(vfoFrequency: number): Observable<HttpResponse<ApiResponse<GroupConfig>>>|undefined {
    return this.parent.setNewVfoFrequency(vfoFrequency);
  }

  public getConfig(): GroupConfig|undefined {
    return this.parent.getConfig();
  }

}
