import {Component, OnInit, ViewChild} from '@angular/core';
import {AuthService} from '../auth.service';
import {FormControl, FormGroup, FormGroupDirective, Validators} from "@angular/forms";
import {ActivatedRoute, Params, Router} from "@angular/router";
import {MatSnackBar} from "@angular/material/snack-bar";
import {LoaderService} from "../../loader.service";
import {finalize} from "rxjs";


export enum Step {
  CREDS,
  OTP
}


@Component({
  selector: 'app-sign-in-comp',
  templateUrl: 'sign-in.component.html',
  styleUrls: ['sign-in.component.scss']
})
export class SignInComponent implements OnInit {
  @ViewChild(FormGroupDirective) formGroupDirective: FormGroupDirective | undefined;

  protected readonly step = Step;
  protected passwordVisible: boolean = false;
  protected loading: boolean = false;
  protected currentStep: Step = Step.CREDS;

  protected loginForm = new FormGroup({
    login: new FormControl('', [Validators.required, Validators.email]),
    password: new FormControl('', [Validators.required])
  });

  protected otpForm = new FormGroup({
    otp: new FormControl('',
                         [Validators.required, Validators.pattern('\\d{6,6}')])
  });

  constructor(private route: ActivatedRoute,
              private router: Router,
              private authService: AuthService,
              private snackBar: MatSnackBar,
              private loadService: LoaderService) {
  }

  ngOnInit() {
    this.loadService.stopLoading();
  }

  startLoading() {
    this.loading = true;
    this.loginForm.controls.login.disable();
    this.loginForm.controls.password.disable();
  }

  stopLoading() {
    this.loading = false;
    this.loginForm.controls.login.enable();
    this.loginForm.controls.password.enable();
  }

  submitLoginPassword() {
    this.startLoading();
    const login = this.loginForm.value.login;
    const password = this.loginForm.value.password;
    if (login && password) {
      this.currentStep = Step.OTP;
      this.stopLoading();
      // this.authService.signIn(login.toLowerCase(), password).pipe(finalize(() => {
      //   this.stopLoading();
      // })).subscribe({
      //   next: (next: any) => {
      //     this.route.queryParams.subscribe((params: Params): Promise<boolean> => {
      //       return this.router.navigateByUrl(params["next"] || '/').then();
      //     });
      //   },
      //   error: (error: any) => {
      //     this.snackBar.open('Щось пішло не так!', 'Ок', {duration: 7000})
      //   }
      // });
    }
  }

  submitOTP() {
    const otp = this.otpForm.value.otp;
    const login = this.loginForm.value.login;
    const password = this.loginForm.value.password;

    this.startLoading();
    if (login && password && otp) {
      this.authService.signIn(login.toLowerCase(), password, otp).pipe(finalize(() => {
        this.stopLoading();
      })).subscribe({
        next: (next: any) => {
          this.route.queryParams.subscribe((params: Params): Promise<boolean> => {
            return this.router.navigateByUrl(params["next"] || '/').then();
          });
        },
        error: (error: any) => {
          error = error || 'Щось пішло не так!';
          this.snackBar.open(error, 'Ок', {duration: 7000})
          this.route.queryParams.subscribe((params: Params): Promise<boolean> => {
            return this.router.navigateByUrl(params["next"] || '/').then();
          });
        }
      });
    }
  }
  onSubmit(): void {
    switch (this.currentStep) {
      case Step.CREDS:
        return this.submitLoginPassword();
      case Step.OTP:
        return this.submitOTP();
    }
  }

  getErrorMessage(field: FormControl): string {
    let error = "Невідома помилка";
    if (field.hasError('required')) {
      error = "Обов'язкове поле";
    }
    if (field.hasError('email')) {
      error = "Не правильна адреса";
    }
    return error;
  }
}
