<div class="mat-elevation-z8">
<mat-card class="login-hero">
    <mat-card-header>
        <mat-card-title>Авторизація</mat-card-title>
    </mat-card-header>
    <div class="placeholder-1"></div>
    <mat-card-content *ngIf="currentStep == step.CREDS" >
        <form [formGroup]="loginForm" (ngSubmit)="onSubmit()">
            <p>
                <mat-form-field appearance="outline" class="width-full">
                    <mat-label>Електронна пошта</mat-label>
                    <input matInput placeholder="me@example.com" formControlName="login">
                    <mat-icon matSuffix>email</mat-icon>
                    <mat-error *ngIf="loginForm.invalid && loginForm.controls.login.invalid">
                      {{getErrorMessage(loginForm.controls.login)}}
                    </mat-error>
                </mat-form-field>
            </p>
            <p>
                <mat-form-field appearance="outline" class="width-full">
                    <mat-label>Пароль</mat-label>
                    <input matInput [type]="passwordVisible ? 'text': 'password'" formControlName="password">
                    <button type="button" mat-icon-button matSuffix (click)="passwordVisible = !passwordVisible"
                            [attr.aria-label]="'Сховати пароль'" [attr.aria-pressed]="!passwordVisible">
                        <mat-icon>{{passwordVisible ? 'visibility': 'visibility_off'}}</mat-icon>
                    </button>
                    <mat-error *ngIf="loginForm.invalid && loginForm.controls.password.invalid">
                      {{getErrorMessage(loginForm.controls.password)}}
                    </mat-error>
                </mat-form-field>
            </p>
            <p>
                <button mat-flat-button color="primary" class="width-full hero-submit"
                        [disabled]="!loginForm.valid || loading">Далі</button>
            </p>
            <div class="placeholder-1"></div>
        </form>
    </mat-card-content>
    <mat-card-content *ngIf="currentStep == step.OTP" >
      <form [formGroup]="otpForm" (ngSubmit)="onSubmit()">
        <p>
            <mat-form-field appearance="outline" class="width-full">
                <mat-label>Код підтвердження</mat-label>
                <input matInput placeholder="000000" formControlName="otp" inputmode="numeric">
                <mat-icon matSuffix>code</mat-icon>
            </mat-form-field>
        </p>
        <p>
            <button mat-flat-button color="primary" class="width-full hero-submit"
                    [disabled]="!otpForm.valid || loading">Увійти</button>
        </p>
        <div class="placeholder-1"></div>
      </form>
    </mat-card-content>
    <mat-card-footer class="overflow-hidden">
        <mat-progress-bar *ngIf="loading" mode="indeterminate" class="progress-bar"></mat-progress-bar>
    </mat-card-footer>
</mat-card>
</div>
