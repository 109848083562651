import {Component, Inject} from "@angular/core";
import {MAT_DIALOG_DATA, MatDialogModule, MatDialogRef} from "@angular/material/dialog";
import {MatButtonModule} from "@angular/material/button";
import {MatFormFieldModule} from "@angular/material/form-field";
import {MatInputModule} from "@angular/material/input";
import {NgForOf, NgIf} from "@angular/common";
import {FormControl, FormGroup, ReactiveFormsModule, Validators} from "@angular/forms";


interface InfoDialogData {
  title: string;
  paragraphs: string[];
}


@Component({
  selector: 'app-info-dialog',
  template: `
    <h2 mat-dialog-title>{{ title }}</h2>
    <mat-dialog-content class="mat-typography">
      <p *ngFor="let paragraph of paragraphs">{{ paragraph }}</p>
    </mat-dialog-content>
    <mat-dialog-actions align="end">
      <button mat-button [mat-dialog-close]="true">Ok</button>
    </mat-dialog-actions>

  `,
  styles: ["h2 {min-width: 280px};p{text-align:center}"],
  standalone: true,
  imports: [MatDialogModule, MatButtonModule, MatFormFieldModule, MatInputModule, NgIf, ReactiveFormsModule, NgForOf],
})
export class DialogInfo {
  title: string;
  paragraphs: string[];
  constructor(@Inject(MAT_DIALOG_DATA) protected data: InfoDialogData) {
    this.title = data.title;
    this.paragraphs = data.paragraphs;
  }
}
