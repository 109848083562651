import {
  checkIntersection,
  colinearPointWithinSegment, Point
} from 'line-intersect';


export interface SignalEvent {
  timestamp: number;
  stationId: number;
  coords: number[][];
}


export class IntersectService {
  stations: {[key: number]: SignalEvent|undefined} = {};
  intersections: Point[] = [];
  constructor(private L: any, private map: any, private intersection: any) {
  }

  calculateIntersect(coords: number[][][]) {
    console.log("coords:", coords);
    for (let i = 0; i < coords.length - 1; i++) {
      for (let j = 1; j < coords.length; j++) {
        const result = checkIntersection(
          coords[0][0][0], coords[0][0][1], coords[0][1][0], coords[0][1][1],
          coords[1][0][0], coords[1][0][1], coords[1][1][0], coords[1][1][1],
        );
        if (result.type == 'intersecting') {
          this.intersection?.setLatLng([result.point.x, result.point.y]);
          this.intersection?.remove();
          this.intersection?.addTo(this.map);
        }
        console.log(result.type);
      }
    }
    // const result = checkIntersection(
    //   coords[0][0][0], coords[0][0][1], coords[0][1][0], coords[0][1][1],
    //   coords[1][0][0], coords[1][0][1], coords[1][1][0], coords[1][1][1],
    // );
    // if (result.type == 'intersecting') {
    //   console.log(result.point);
    //   this.intersection?.setLatLng([result.point.x, result.point.y]);
    //   this.intersection?.remove();
    //   this.intersection?.addTo(this.map);
    // }
  }

  addEvent(event: SignalEvent) {
    const timestamp = event.timestamp;
    const latLngs: number[][][] = [event.coords];
    if (event.stationId in this.stations) {
      this.stations[event.stationId] = undefined;
    }
    for (let stationId of Object.keys(this.stations)) {
      const event = this.stations[parseInt(stationId)];
      if (event !== undefined) {
        if (Math.abs(timestamp - event.timestamp) < 1000) {
          latLngs.push(event.coords);
        }
      }
    }
    if (event.stationId in this.stations) {
      this.stations[event.stationId] = event;
    }
    if (latLngs.length > 1) {
      this.calculateIntersect(latLngs);
    }
  }

  addStation(id: number) {
    this.stations[id] = undefined;
  }

  removeStation(id: number) {
    delete this.stations[id];
  }
}
