<!-- SETTINGS -->
<div>
    <div class="container-fluid mw-2k">
      <div class="row align-items-center">
        <div class="col-6 col-sm-6 col-md-6 col-lg-6 col-xl-6 col-xxl-6">
          <div class="p-15-05em" [formGroup]="form">
            <mat-slide-toggle formControlName="proModeEnabled"
                              aria-label="PRO Режим"
                              color="primary">PRO Режим</mat-slide-toggle>
          </div>
        </div>
        <div class="col-6 col-sm-6 col-md-6 col-lg-6 col-xl-6 col-xxl-6 text-right">
          <button mat-button aria-label="More actions"
                  *ngIf="parent.item?.id" [matMenuTriggerFor]="more">
            Додатково
          </button>
          <mat-menu #more="matMenu">
            <button mat-menu-item (click)="resetConfig()">
              <mat-icon>settings_backup_restore</mat-icon>
              <span>За замовчанням</span>
            </button>
            <button mat-menu-item (click)="restartDevice()">
              <mat-icon>power_settings_new</mat-icon>
              <span>Перезапуск</span>
            </button>
          </mat-menu>
        </div>
      </div>
      <mat-divider></mat-divider>

      <form #formNoob="ngForm" [formGroup]="form" (ngSubmit)="save()">
        <div class="row" *ngIf="!form.controls['proModeEnabled']?.value">
          <div class="d-none d-xxl-flex col-xxl-2"></div>
          <!-- General -->
          <div class="col-sm-12 col-md-6 col-lg-4 col-xl-3 col-xxl-2 p-1em">
            <div>
              <h3>Загальні:</h3>
            </div>
            <div class="pb-1em" *ngIf="parent.item">
              <mat-form-field class="w-100" appearance="outline" floatLabel="always" formGroupName="conf">
                <mat-label>Серійний номер</mat-label>
                <input matInput [readonly]="true"
                       [value]="parent.item.serial">
              </mat-form-field>
            </div>
            <div class="pb-1em">
              <mat-form-field class="w-100" appearance="outline" floatLabel="always" formGroupName="conf">
                <mat-label>Ім'я Пеленгатора</mat-label>
                <input matInput formControlName="station_id"
                       placeholder="Пеленгатор-1">
                <mat-error *ngIf="form.getError('required', ['station_id'])">Введіть правильне значення</mat-error>
                <!--<mat-hint>Ім'я</mat-hint>-->
              </mat-form-field>
            </div>

            <!-- location -->
            <div>
              <div class="pb-1em" formGroupName="conf">
                <mat-form-field class="w-100" appearance="outline">
                  <mat-label>Позиціонування</mat-label>
                  <mat-select required formControlName="location_source">
                    <mat-option [value]="'Static'">Вручну</mat-option>
                    <mat-option [value]="'gpsd'">Авто (GPS)</mat-option>
                    <mat-option [value]="'None'">Відключено</mat-option>
                  </mat-select>
                </mat-form-field>
              </div>
              <div *ngIf="form.controls['conf'].get('location_source')?.value === 'Static'">
                <!-- only when static -->
                <div class="pb-1em" formGroupName="conf">
                  <mat-form-field class="w-100" appearance="outline" floatLabel="always">
                    <mat-label>Координати</mat-label>
                    <input matInput formControlName="latlng"
                           placeholder="52.0000, 32.0000">
                    <mat-error *ngIf="form.getError('required', ['latlng'])">
                      Обов'язкове поле
                    </mat-error>
                  </mat-form-field>
                </div>
              </div>
              <div *ngIf="form.controls['conf'].get('location_source')?.value === 'gpsd'">
                <mat-divider></mat-divider>
                <div class="p-15-1em mw-100" formGroupName="conf">
                  <mat-slide-toggle formControlName="fixed_heading_check"
                                    class="mw-100" color="primary">Статичний напрямок</mat-slide-toggle>
                </div>
                <mat-divider></mat-divider>
              </div>
              <div *ngIf="form.controls['conf'].get('location_source')?.value === 'gpsd' &&
                          !form.controls['conf'].get('fixed_heading_check')?.value">
                <div class="pb-1em" formGroupName="conf">
                  <mat-form-field class="w-100" appearance="outline" floatLabel="always">
                    <mat-label>Мінімальна швидкість</mat-label>
                    <input matInput formControlName="gps_min_speed"
                           placeholder="2">
                    <span matTextSuffix>м\с</span>
                    <mat-error *ngIf="form.getError('required', ['gps_min_speed'])">Обов'язкове поле</mat-error>
                  </mat-form-field>
                </div>
                <div class="pb-1em" formGroupName="conf">
                  <mat-form-field class="w-100" appearance="outline" floatLabel="always">
                    <mat-label>Мінімальна тривалість швидкості</mat-label>
                    <input matInput formControlName="gps_min_speed_duration"
                           placeholder="3">
                    <span matTextSuffix>с</span>
                    <mat-error *ngIf="form.getError('required', ['gps_min_speed_duration'])">Обов'язкове поле</mat-error>
                  </mat-form-field>
                </div>
              </div>
              <div *ngIf="form.controls['conf'].get('location_source')?.value === 'Static' ||
                          (form.controls['conf'].get('location_source')?.value === 'gpsd' &&
                           form.controls['conf'].get('fixed_heading_check')?.value)">
                <div class="pb-1em" formGroupName="conf">
                  <mat-form-field class="w-100" appearance="outline" floatLabel="always">
                    <mat-label>Напрямок</mat-label>
                    <input matInput formControlName="heading" #heading
                           placeholder="0.0">
                    <span matTextSuffix>&nbsp;º</span>
                    <mat-error *ngIf="form.getError('required', ['heading'])">Обов'язкове поле</mat-error>
                    <mat-error *ngIf="form.getError('min', ['heading'])">Має бути між 0 до 360</mat-error>
                    <mat-error *ngIf="form.getError('max', ['heading'])">Має бути між 0 до 360</mat-error>
                  </mat-form-field>
                </div>
              </div>
            </div>

          </div>

          <!-- Receiver -->
          <div class="col-sm-12 col-md-6 col-lg-4 col-xl-3 col-xxl-2 p-1em">
            <div>
              <h3>Параметри прийому:</h3>
            </div>
            <div class="pb-1em" formGroupName="conf">
              <mat-form-field floatLabel="always" class="w-100" appearance="outline">
                <mat-label>Центральна Частота</mat-label>
                <input matInput formControlName="center_freq"
                       placeholder="412.123" required>
                <span matTextSuffix>МГц</span>
                <mat-error *ngIf="form.getError('required', ['center_freq'])">Обов'язкове поле</mat-error>
              </mat-form-field>
            </div>
            <div class="pb-1em" formGroupName="conf">
              <mat-form-field class="w-100" floatLabel="always" appearance="outline">
                <mat-label>Підсилення</mat-label>
                <mat-select required formControlName="uniform_gain">
                  <mat-option *ngFor="let item of gainParams" [value]="item.value">{{ item.label }}</mat-option>
                </mat-select>
                <mat-error *ngIf="form.getError('required', ['uniform_gain'])">Обов'язкове поле</mat-error>
              </mat-form-field>
            </div>
            <div class="pb-1em" formGroupName="conf">
              <mat-form-field floatLabel="always" class="w-100" appearance="outline">
                <mat-label>Робоча Частота (VFO)</mat-label>
                <input matInput required formControlName="vfo_freq_0"
                       placeholder="412.123" required>
                <span matTextSuffix>МГц</span>
                <mat-error *ngIf="form.getError('required', ['vfo_freq_0'])">Обов'язкове поле</mat-error>
              </mat-form-field>
            </div>
            <div class="pb-1em" formGroupName="conf">
              <mat-form-field floatLabel="always" class="w-100" appearance="outline">
                <mat-label>Чутливість РЧ (VFO Squelch)</mat-label>
                <input matInput formControlName="vfo_squelch_0"
                       placeholder="-50" required>
                <span matTextSuffix>Дб</span>
                <mat-error *ngIf="form.getError('required', ['vfo_squelch_0'])">Обов'язкове поле</mat-error>
              </mat-form-field>
            </div>
            <div class="pb-1em" formGroupName="conf">
              <mat-form-field class="w-100" floatLabel="always" appearance="outline">
                <mat-label>Тип роботи РЧ</mat-label>
                <mat-select formControlName="vfo_mode" required>
                  <mat-option *ngFor="let item of vfoParams" [value]="item.value">{{ item.label }}</mat-option>
                </mat-select>
                <mat-error *ngIf="form.getError('required', ['conf', 'vfo_mode'])">Обов'язкове поле</mat-error>
              </mat-form-field>
            </div>
            <div class="pb-1em" formGroupName="conf">
              <mat-form-field class="w-100" appearance="outline" floatLabel="always">
                <mat-label>Зменшення бітрейту спектру</mat-label>
                <input matInput formControlName="dsp_decimation" placeholder="1">
                <mat-error *ngIf="form.getError('required', ['dsp_decimation'])">Обов'язкове поле</mat-error>
              </mat-form-field>
            </div>
            <div formGroupName="conf">
              <mat-divider></mat-divider>
              <div class="p-15-1em mw-100">
                <mat-slide-toggle formControlName="en_optimize_short_bursts"
                                  class="mw-100" color="primary">Імпульс оптимізація</mat-slide-toggle>
              </div>
              <mat-divider></mat-divider>
            </div>
            <mat-divider></mat-divider>
            <div class="p-15-1em mw-100" formGroupName="daq">
              <mat-slide-toggle formControlName="cfg_en_bias_tee"
                                class="mw-100" color="primary">ВЧ Живлення (Bias tee)</mat-slide-toggle>
            </div>
            <mat-divider></mat-divider>
          </div>

          <!-- DOA -->
          <div class="col-sm-12 col-md-6 col-lg-4 col-xl-3 col-xxl-2 p-1em">
            <div>
              <h3>Параметри пеленгації:</h3>
            </div>
            <div class="pb-1em" formGroupName="conf">
              <mat-form-field class="w-100" appearance="outline">
                <mat-label>Антенний масив</mat-label>
                <mat-select formControlName="ant_arrangement" required>
                  <mat-option [value]="'ULA'">Лінійний (ULA)</mat-option>
                  <mat-option [value]="'UCA'">Круговий (UCA)</mat-option>
                  <mat-option [value]="'Custom'" disabled>Інший (Custom)</mat-option>
                </mat-select>
                <mat-error *ngIf="form.getError('required', ['ant_arrangement'])">Обов'язкове поле</mat-error>
              </mat-form-field>
            </div>

            <div *ngIf="form.controls['conf'].get('ant_arrangement')?.value === 'Custom'">
              <div class="pb-1em" formGroupName="conf">
                <mat-form-field class="w-100" appearance="outline" floatLabel="always">
                  <mat-label>Кординати X</mat-label>
                  <input matInput
                         formControlName="custom_array_x_meters" class="text-right"
                         placeholder="0.21,0.06,-0.17,-0.17,0.07">
                  <mat-error *ngIf="form.getError('required', ['custom_array_x_meters'])">Обов'язкове поле</mat-error>
                </mat-form-field>
              </div>
              <div class="pb-1em" formGroupName="conf">
                <mat-form-field class="w-100" appearance="outline" floatLabel="always">
                  <mat-label>Кординати Y</mat-label>
                  <input matInput
                         formControlName="custom_array_y_meters" class="text-right"
                         placeholder="0.00,-0.20,-0.12,0.12,0.20">
                  <mat-error *ngIf="form.getError('required', ['custom_array_y_meters'])">Обов'язкове поле</mat-error>
                </mat-form-field>
              </div>
            </div>

            <div class="pb-1em" *ngIf="form.controls['conf'].get('ant_arrangement')?.value === 'ULA'">
              <mat-form-field class="w-100" appearance="outline" floatLabel="always" formGroupName="conf">
                <mat-label >Відстань між антенами</mat-label>
                <input formControlName="ant_spacing_meters" matInput placeholder="0.314">
                <span matTextSuffix>м</span>
                <mat-error *ngIf="form.getError('required', ['conf', 'ant_spacing_meters'])">Обов'язкове поле</mat-error>
              </mat-form-field>
            </div>

            <div class="pb-1em" *ngIf="form.controls['conf'].get('ant_arrangement')?.value === 'UCA'">
              <mat-form-field class="w-100" appearance="outline" floatLabel="always" formGroupName="conf">
                <mat-label>Радіус масиву</mat-label>
                <input formControlName="ant_spacing_meters" matInput placeholder="0.314">
                <span matTextSuffix>м</span>
                <mat-error *ngIf="form.getError('required', ['conf', 'ant_spacing_meters'])">Обов'язкове поле</mat-error>
              </mat-form-field>
            </div>
            <div *ngIf="form.controls['conf'].get('ant_arrangement')?.value === 'ULA'">
              <div class="pb-1em" formGroupName="conf">
                <mat-form-field class="w-100" appearance="outline">
                  <mat-label>Напрямок</mat-label>
                  <mat-select formControlName="ula_direction" required>
                    <mat-option [value]="'Both'">Всі</mat-option>
                    <mat-option [value]="'Forward'">Фронт</mat-option>
                    <mat-option [value]="'Backward'">Тил</mat-option>
                  </mat-select>
                  <mat-error *ngIf="form.getError('required', ['ula_direction'])">Обов'язкове поле</mat-error>
                </mat-form-field>
              </div>
            </div>
            <div class="pb-1em" formGroupName="conf">
              <mat-form-field class="w-100" appearance="outline" floatLabel="always">
                <mat-label>Компенсація азімуту</mat-label>
                <span matTextSuffix>гр</span>
                <input formControlName="array_offset" matInput placeholder="0">
                <span matTextSuffix>º</span>
                <mat-error *ngIf="form.getError('required', ['array_offset'])">Обов'язкове поле</mat-error>
              </mat-form-field>
            </div>
            <div>
              <mat-divider></mat-divider>
              <div class="p-15-1em mw-100" formGroupName="conf">
                <mat-slide-toggle formControlName="en_doa"
                                  class="mw-100" color="primary">DoA (Визначення Напрямку)</mat-slide-toggle>
              </div>
              <mat-divider></mat-divider>
            </div>

          </div>

          <!-- Visualization -->
          <!-- TODO(s1z): Fix style here -->
          <div class="col-sm-12 col-md-6 col-lg-4 col-xl-3 col-xxl-2 p-1em overflow-hidden">
            <div>
              <h3>Відображення:</h3>
            </div>
            <div class="pb-1em" formGroupName="conf">
              <mat-form-field class="w-100" appearance="outline" floatLabel="always">
                <mat-label>Граф тип</mat-label>
                <mat-select formControlName="doa_fig_type" required>
                  <mat-option [value]="'Compass'">Компас</mat-option>
                  <mat-option [value]="'Polar'">Полар</mat-option>
                  <mat-option [value]="'Linear'">Лінійний</mat-option>
                </mat-select>
                <mat-error *ngIf="form.getError('required', ['doa_fig_type'])">Обов'язкове поле</mat-error>
              </mat-form-field>
            </div>
            <div class="pb-1em" formGroupName="conf">
              <mat-form-field class="w-100" appearance="outline" floatLabel="always">
                <mat-label>Полоса пропускання (VFO BW)</mat-label>
                <span matTextSuffix>Гц</span>
                <input matInput formControlName="vfo_bw_0" placeholder="12000">
                <mat-error *ngIf="form.getError('required', ['vfo_bw_0'])">Обов'язкове поле</mat-error>
              </mat-form-field>
            </div>
          </div>
        </div>
      </form>

      <!-- Pro Mode -->
      <form  #formPro="ngForm" [formGroup]="form" (ngSubmit)="save()">
        <div *ngIf="form.controls['proModeEnabled']?.value">
          <div class="row" >

            <!-- RF Receiver Configuration -->
            <div class="col-sm-12 col-md-6 col-lg-4 col-xl-3 col-xxl-2 p-1em">
              <div>
                <h3>Параметри прийому:</h3>
              </div>
              <div class="pb-1em" formGroupName="conf">
                <mat-form-field floatLabel="always" class="w-100" appearance="outline">
                  <mat-label>Центральна Частота</mat-label>
                  <input matInput formControlName="center_freq"
                         placeholder="412.123" required>
                  <span matTextSuffix>МГц</span>
                  <mat-error *ngIf="form.getError('required', ['center_freq'])">Обов'язкове поле</mat-error>
                </mat-form-field>
              </div>
              <div class="pb-1em" formGroupName="conf">
                <mat-form-field class="w-100" floatLabel="always" appearance="outline">
                  <mat-label>Підсилення</mat-label>
                  <mat-select required formControlName="uniform_gain">
                    <mat-option *ngFor="let item of gainParams" [value]="item.value">{{ item.label }}</mat-option>
                  </mat-select>
                  <mat-error *ngIf="form.getError('required', ['uniform_gain'])">Обов'язкове поле</mat-error>
                </mat-form-field>
              </div>

              <!-- DAQ Config switch -->
              <div>
                <mat-divider></mat-divider>
                <div class="p-15-1em mw-100">
                  <mat-slide-toggle [ngModel]="daq_conf_visible_basic" [ngModelOptions]="{standalone: true}"
                                    (click)="toggleDaqConfVisibleBasic()"
                                    class="mw-100" color="primary">Базові DAQ</mat-slide-toggle>
                </div>
                <mat-divider></mat-divider>
              </div>

              <!-- DAQ Config -->
              <div *ngIf="daq_conf_visible_basic">
                <div>
                  <h3>Кастомні DAQ:</h3>
                </div>
                <div class="pb-1em" formGroupName="daq">
                  <mat-form-field floatLabel="always" class="w-100" appearance="outline">
                    <mat-label>Data Block Розмір</mat-label>
                    <span matTextSuffix>мс</span>
                    <input matInput formControlName="cfg_data_block_len"
                           placeholder="436.906" required>
                    <mat-error *ngIf="form.getError('required', ['cfg_data_block_len'])">
                      Обов'язкове поле
                    </mat-error>
                  </mat-form-field>
                </div>
                <div class="pb-1em" formGroupName="daq">
                  <mat-form-field floatLabel="always" class="w-100" appearance="outline">
                    <mat-label>Інтервал рекалібрації</mat-label>
                    <span matTextSuffix>хв</span>
                    <input matInput formControlName="cfg_recal_interval"
                           placeholder="5.0" required>
                    <mat-error *ngIf="form.getError('required', ['cfg_recal_interval'])">
                      Обов'язкове поле
                    </mat-error>
                  </mat-form-field>
                </div>
                <div>
                  <mat-divider></mat-divider>
                  <div class="p-15-1em mw-100">
                    <mat-slide-toggle [ngModel]="daq_conf_visible_advanced" [ngModelOptions]="{standalone: true}"
                                      (click)="toggleDaqConfVisibleAdvanced()"
                                      class="mw-100" color="primary">Розширені DAQ</mat-slide-toggle>
                  </div>
                  <mat-divider></mat-divider>
                </div>

                <! -- advanced daq -->
                <div *ngIf="daq_conf_visible_advanced">
                  <div class="pt-1em">
                    <h3>DAQ Обладнання</h3>
                  </div>
                  <div class="pb-1em" formGroupName="daq">
                    <mat-form-field class="w-100" floatLabel="always" appearance="outline">
                      <mat-label>ВЧ каналів</mat-label>
                      <mat-select required formControlName="cfg_rx_channels">
                        <mat-option [value]="5">5 каналів</mat-option>
                        <mat-option [value]="4">4 канали</mat-option>
                        <mat-option [value]="3">3 канали</mat-option>
                        <mat-option [value]="2">2 канали</mat-option>
                        <mat-option [value]="1">1 канал</mat-option>
                      </mat-select>
                      <mat-error *ngIf="form.getError('required', ['cfg_rx_channels'])">
                        Обов'язкове поле
                      </mat-error>
                    </mat-form-field>
                  </div>
                  <div formGroupName="daq">
                    <mat-divider></mat-divider>
                    <div class="p-15-1em mw-100">
                      <mat-slide-toggle formControlName="cfg_en_bias_tee"
                                        class="mw-100" color="primary">ВЧ Живлення (Bias tee)</mat-slide-toggle>
                    </div>
                    <mat-divider></mat-divider>
                  </div>
                  <div class="pt-1em">
                    <h3>DAQ Обробка</h3>
                  </div>
                  <div class="pb-1em pt-2em" formGroupName="daq">
                    <mat-form-field class="w-100" floatLabel="always" appearance="outline">
                      <mat-label>Розмір буферу</mat-label>
                      <mat-select required formControlName="cfg_daq_buffer_size">
                        <mat-option *ngFor="let item of daqBufferSizes"
                                    [value]="item.value">{{ item.label }}</mat-option>
                      </mat-select>
                      <mat-error *ngIf="form.getError('required', ['cfg_daq_buffer_size'])">Обов'язкове поле</mat-error>
                    </mat-form-field>
                  </div>
                  <div class="pb-1em pt-2em" formGroupName="daq">
                    <mat-form-field class="w-100" floatLabel="always" appearance="outline">
                      <mat-label>Розмір семплу</mat-label>
                      <mat-select required formControlName="cfg_sample_rate">
                        <mat-option *ngFor="let item of daqSampleRate"
                                    [value]="item.value">{{ item.label }}</mat-option>
                      </mat-select>
                      <mat-error *ngIf="form.getError('required', ['cfg_sample_rate'])">Обов'язкове поле</mat-error>
                    </mat-form-field>
                  </div>
                  <div>
                    <mat-divider></mat-divider>
                    <div class="p-15-1em mw-100" formGroupName="daq">
                      <mat-slide-toggle formControlName="en_noise_source_ctr"
                                        class="mw-100" color="primary">Контроль шумогенератора</mat-slide-toggle>
                    </div>
                    <mat-divider></mat-divider>
                  </div>
                  <div class="pt-1em">
                    <h3>Препроцессінг</h3>
                  </div>
                  <div class="pb-1em" formGroupName="daq">
                    <mat-form-field floatLabel="always" class="w-100" appearance="outline">
                      <mat-label>Когерентний процессінг інтервал</mat-label>
                      <span matTextSuffix>sample</span>
                      <input matInput formControlName="cfg_cpi_size"
                             placeholder="1048576" required>
                      <mat-error *ngIf="form.getError('required', ['cfg_cpi_size'])">
                        Обов'язкове поле
                      </mat-error>
                    </mat-form-field>
                  </div>
                  <div class="pb-1em" formGroupName="daq">
                    <mat-form-field floatLabel="always" class="w-100" appearance="outline">
                      <mat-label>Коефіцієнт зменшення</mat-label>
                      <span matTextSuffix>sample</span>
                      <input matInput formControlName="cfg_decimation_ratio"
                             placeholder="1" required>
                      <mat-error *ngIf="form.getError('required', ['cfg_decimation_ratio'])">
                        Обов'язкове поле
                      </mat-error>
                    </mat-form-field>
                  </div>
                  <div class="pb-1em" formGroupName="daq">
                    <mat-form-field floatLabel="always" class="w-100" appearance="outline">
                      <mat-label>FIR відносна здатність</mat-label>
                      <input matInput formControlName="cfg_fir_bw"
                             placeholder="1" required>
                      <mat-error *ngIf="form.getError('required', ['cfg_fir_bw'])">
                        Обов'язкове поле
                      </mat-error>
                    </mat-form-field>
                  </div>
                  <div class="pb-1em" formGroupName="daq">
                    <mat-form-field floatLabel="always" class="w-100" appearance="outline">
                      <mat-label>FIR Tap розмір</mat-label>
                      <input matInput formControlName="cfg_fir_tap_size"
                             placeholder="1" required>
                      <mat-error *ngIf="form.getError('required', ['cfg_fir_tap_size'])">
                        Обов'язкове поле
                      </mat-error>
                    </mat-form-field>
                  </div>
                  <div class="pb-1em pt-2em" formGroupName="daq">
                    <mat-form-field class="w-100" floatLabel="always" appearance="outline">
                      <mat-label>FIR Вікно</mat-label>
                      <mat-select required formControlName="cfg_fir_window">
                        <mat-option *ngFor="let item of daqFirWindow"
                                    [value]="item.value">{{ item.label }}</mat-option>
                      </mat-select>
                      <mat-error *ngIf="form.getError('required', ['cfg_fir_window'])">Обов'язкове поле</mat-error>
                    </mat-form-field>
                  </div>
                  <div formGroupName="daq">
                    <mat-divider></mat-divider>
                    <div class="p-15-1em mw-100">
                      <mat-slide-toggle formControlName="en_filter_reset"
                                        class="mw-100" color="primary">Очитска пам'яті FIR фільтру</mat-slide-toggle>
                    </div>
                    <mat-divider></mat-divider>
                  </div>
                  <div class="pt-1em">
                    <h3>Калібрація</h3>
                  </div>
                  <div class="pb-1em" formGroupName="daq">
                    <mat-form-field floatLabel="always" class="w-100" appearance="outline">
                      <mat-label>Розмір семплу кореляції</mat-label>
                      <span matTextSuffix>sample</span>
                      <input matInput formControlName="cfg_corr_size"
                             placeholder="65536">
                      <mat-error *ngIf="form.getError('required', ['cfg_corr_size'])">
                        Обов'язкове поле
                      </mat-error>
                    </mat-form-field>
                  </div>
                  <div class="pb-1em" formGroupName="daq">
                    <mat-form-field class="w-100" floatLabel="always" appearance="outline">
                      <mat-label>Канонічний канал</mat-label>
                      <mat-select required formControlName="cfg_std_ch_ind">
                        <mat-option [value]="0">0 канал</mat-option>
                        <mat-option [value]="1">1 канал</mat-option>
                        <mat-option [value]="2">2 канал</mat-option>
                        <mat-option [value]="3">3 канал</mat-option>
                        <mat-option [value]="4">4 канал</mat-option>
                      </mat-select>
                      <mat-error *ngIf="form.getError('required', ['cfg_std_ch_ind'])">
                        Обов'язкове поле
                      </mat-error>
                    </mat-form-field>
                  </div>
                  <div formGroupName="daq">
                    <mat-divider></mat-divider>
                    <div class="p-15-1em mw-100">
                      <mat-slide-toggle formControlName="en_iq_cal"
                                        class="mw-100" color="primary">IQ Калібрація</mat-slide-toggle>
                    </div>
                    <mat-divider></mat-divider>
                  </div>
                  <div class="pb-1em" formGroupName="daq">
                    <mat-form-field floatLabel="always" class="w-100" appearance="outline">
                      <mat-label>Захоплення підсилювача</mat-label>
                      <span matTextSuffix>frame</span>
                      <input matInput formControlName="cfg_gain_lock"
                             placeholder="0">
                      <mat-error *ngIf="form.getError('required', ['cfg_gain_lock'])">
                        Обов'язкове поле
                      </mat-error>
                    </mat-form-field>
                  </div>
                  <div formGroupName="daq">
                    <mat-divider></mat-divider>
                    <div class="p-15-1em mw-100">
                      <mat-slide-toggle formControlName="en_req_track_lock_intervention"
                                        class="mw-100" color="primary">Програмна зупинка калібрації</mat-slide-toggle>
                    </div>
                    <mat-divider></mat-divider>
                  </div>
                  <div class="pb-1em" formGroupName="daq">
                    <mat-form-field class="w-100" floatLabel="always" appearance="outline">
                      <mat-label>Тип відслідковування</mat-label>
                      <mat-select required formControlName="cfg_cal_track_mode">
                        <mat-option *ngFor="let item of daqCalTrackModes"
                                    [value]="item.value">{{ item.label }}</mat-option>
                      </mat-select>
                      <mat-error *ngIf="form.getError('required', ['cfg_cal_track_mode'])">
                        Обов'язкове поле
                      </mat-error>
                    </mat-form-field>
                  </div>
                  <div class="pb-1em" formGroupName="daq">
                    <mat-form-field class="w-100" floatLabel="always" appearance="outline">
                      <mat-label>Амплітудна калібрація</mat-label>
                      <mat-select required formControlName="cfg_amplitude_cal_mode">
                        <mat-option *ngFor="let item of daqAmplCalModes"
                                    [value]="item.value">{{ item.label }}</mat-option>
                      </mat-select>
                      <mat-error *ngIf="form.getError('required', ['cfg_amplitude_cal_mode'])">
                        Обов'язкове поле
                      </mat-error>
                    </mat-form-field>
                  </div>
                  <div class="pb-1em" formGroupName="daq">
                    <mat-form-field floatLabel="always" class="w-100" appearance="outline">
                      <mat-label>Інтервал пакету калібрації</mat-label>
                      <input matInput formControlName="cfg_cal_frame_interval"
                             placeholder="687">
                      <mat-error *ngIf="form.getError('required', ['cfg_cal_frame_interval'])">
                        Обов'язкове поле
                      </mat-error>
                    </mat-form-field>
                  </div>
                  <div class="pb-1em" formGroupName="daq">
                    <mat-form-field floatLabel="always" class="w-100" appearance="outline">
                      <mat-label>Кількість пакетів калібрації</mat-label>
                      <input matInput formControlName="cfg_cal_frame_burst_size"
                             placeholder="10">
                      <mat-error *ngIf="form.getError('required', ['cfg_cal_frame_burst_size'])">
                        Обов'язкове поле
                      </mat-error>
                    </mat-form-field>
                  </div>
                  <div class="pb-1em" formGroupName="daq">
                    <mat-form-field floatLabel="always" class="w-100" appearance="outline">
                      <mat-label>Допуск різниці амплітуд</mat-label>
                      <span matTextSuffix>дБ</span>
                      <input matInput formControlName="cfg_amplitude_tolerance"
                             placeholder="2">
                      <mat-error *ngIf="form.getError('required', ['cfg_amplitude_tolerance'])">
                        Обов'язкове поле
                      </mat-error>
                    </mat-form-field>
                  </div>
                  <div class="pb-1em" formGroupName="daq">
                    <mat-form-field floatLabel="always" class="w-100" appearance="outline">
                      <mat-label>Допуск різниці фаз</mat-label>
                      <span matTextSuffix>град</span>
                      <input matInput formControlName="cfg_phase_tolerance"
                             placeholder="1">
                      <span matTextSuffix>&nbsp;º</span>
                      <mat-error *ngIf="form.getError('required', ['cfg_phase_tolerance'])">
                        Обов'язкове поле
                      </mat-error>
                      <mat-error *ngIf="form.getError('min', ['cfg_phase_tolerance'])">
                        Має бути цілим числом > 0
                      </mat-error>
                    </mat-form-field>
                  </div>
                  <div class="pb-1em" formGroupName="daq">
                    <mat-form-field floatLabel="always" class="w-100" appearance="outline">
                      <mat-label>Макс. Синхро помилок</mat-label>
                      <input matInput formControlName="cfg_max_sync_fails"
                             placeholder="10">
                      <mat-error *ngIf="form.getError('required', ['cfg_max_sync_fails'])">
                        Обов'язкове поле
                      </mat-error>
                    </mat-form-field>
                  </div>

                </div>
                <!-- advanced daq end -->


              </div>

            </div>

            <!-- VFO Configuration -->
            <div class="col-sm-12 col-md-6 col-lg-4 col-xl-3 col-xxl-2 p-1em">
              <!-- spectrum_calculation -->
              <div>
                <h3>Робоча частота (РЧ):</h3>
              </div>
              <div class="pb-1em" formGroupName="conf">
                <mat-form-field class="w-100" appearance="outline" floatLabel="always">
                  <mat-label>Вивід на спектр</mat-label>
                  <mat-select formControlName="spectrum_calculation" required>
                    <mat-option [value]="'Single'">Один канал</mat-option>
                    <mat-option [value]="'All'">Всі (Тільки для тесту)</mat-option>
                  </mat-select>
                  <mat-error *ngIf="form.getError('required', ['spectrum_calculation'])">Обов'язкове поле</mat-error>
                </mat-form-field>
              </div>
              <div class="pb-1em" formGroupName="conf">
                <mat-form-field class="w-100" appearance="outline" floatLabel="always">
                  <mat-label>Тип роботи генератора</mat-label>
                  <mat-select formControlName="vfo_mode" required>
                    <mat-option [value]="'Standard'">Стандартний</mat-option>
                    <mat-option [value]="'Auto'">Вибір максимального сигналу</mat-option>
                  </mat-select>
                  <mat-error *ngIf="form.getError('required', ['vfo_mode'])">Обов'язкове поле</mat-error>
                </mat-form-field>
              </div>
              <div class="pb-1em" formGroupName="conf">
                <mat-form-field class="w-100" appearance="outline" floatLabel="always">
                  <mat-label>Активний генератор</mat-label>
                  <mat-select formControlName="active_vfos" type="number" required>
                    <mat-option [value]="1">1</mat-option>
                  </mat-select>
                  <mat-error *ngIf="form.getError('required', ['active_vfos'])">Обов'язкове поле</mat-error>
                </mat-form-field>
              </div>
              <div class="pb-1em" formGroupName="conf">
                <mat-form-field class="w-100" appearance="outline" floatLabel="always">
                  <mat-label>Вихід генератора</mat-label>
                  <mat-select formControlName="output_vfo" type="number" required>
                    <mat-option [value]="0">0</mat-option>
                  </mat-select>
                  <mat-error *ngIf="form.getError('required', ['output_vfo'])">Обов'язкове поле</mat-error>
                </mat-form-field>
              </div>
              <div class="pb-1em" formGroupName="conf">
                <mat-form-field class="w-100" appearance="outline" floatLabel="always">
                  <mat-label>Зменшення бітрейту спектру</mat-label>
                  <input matInput formControlName="dsp_decimation" placeholder="1">
                  <mat-error *ngIf="form.getError('required', ['dsp_decimation'])">Обов'язкове поле</mat-error>
                </mat-form-field>
              </div>
              <div>
                <mat-divider></mat-divider>
                <div class="p-15-1em mw-100" formGroupName="conf">
                  <mat-slide-toggle formControlName="en_optimize_short_bursts"
                                    class="mw-100" color="primary">Імпульс оптимізація</mat-slide-toggle>
                </div>
                <mat-divider></mat-divider>
              </div>
            </div>

            <!-- DoA Configuration -->
            <div class="col-sm-12 col-md-6 col-lg-4 col-xl-3 col-xxl-2 p-1em">
              <div>
                <h3>Параметри пеленгації:</h3>
              </div>
              <div class="pb-1em" formGroupName="conf">
                <mat-form-field class="w-100" appearance="outline" floatLabel="always">
                  <mat-label>Антенний масив</mat-label>
                  <mat-select formControlName="ant_arrangement" required>
                    <mat-option [value]="'ULA'">Лінійний (ULA)</mat-option>
                    <mat-option [value]="'UCA'">Круговий (UCA)</mat-option>
                    <mat-option [value]="'Custom'">Інший (Custom)</mat-option>
                  </mat-select>
                  <mat-error *ngIf="form.getError('required', ['ant_arrangement'])">Обов'язкове поле</mat-error>
                </mat-form-field>
              </div>
              <div class="pb-1em" *ngIf="form.controls['conf'].get('ant_arrangement')?.value !== 'Custom'">
                <mat-form-field class="w-100" appearance="outline" floatLabel="always" formGroupName="conf">
                  <mat-label *ngIf="form.controls['conf'].get('ant_arrangement')?.value  === 'ULA'">Відстань між антенами</mat-label>
                  <mat-label *ngIf="form.controls['conf'].get('ant_arrangement')?.value  === 'UCA'">Радіус масиву</mat-label>
                  <input formControlName="ant_spacing_meters" matInput placeholder="0.314">
                  <span matTextSuffix>м</span>
                  <mat-error *ngIf="form.getError('required', ['ant_spacing_meters'])">Обов'язкове поле</mat-error>
                </mat-form-field>
              </div>

              <div *ngIf="form.controls['conf'].get('ant_arrangement')?.value  === 'Custom'">
                <div class="pb-1em" formGroupName="conf">
                  <mat-form-field class="w-100" appearance="outline" floatLabel="always">
                    <mat-label>Кординати X</mat-label>
                    <input matInput formControlName="custom_array_x_meters" class="text-right"
                           placeholder="0.21,0.06,-0.17,-0.17,0.07">
                    <mat-error *ngIf="form.getError('required', ['custom_array_x_meters'])">Обов'язкове поле</mat-error>
                  </mat-form-field>
                </div>
                <div class="pb-1em" formGroupName="conf">
                  <mat-form-field class="w-100" appearance="outline" floatLabel="always">
                    <mat-label>Кординати Y</mat-label>
                    <input matInput formControlName="custom_array_y_meters" class="text-right"
                           placeholder="0.00,-0.20,-0.12,0.12,0.20">
                    <mat-error *ngIf="form.getError('required', ['custom_array_y_meters'])">Обов'язкове поле</mat-error>
                  </mat-form-field>
                </div>
              </div>

              <div *ngIf="form.controls['conf'].get('ant_arrangement')?.value  === 'ULA'">
                <div class="pb-1em" formGroupName="conf">
                  <mat-form-field class="w-100" appearance="outline">
                    <mat-label>Напрямок</mat-label>
                    <mat-select formControlName="ula_direction" required>
                      <mat-option [value]="'Both'">Всі</mat-option>
                      <mat-option [value]="'Forward'">Фронт</mat-option>
                      <mat-option [value]="'Backward'">Тил</mat-option>
                    </mat-select>
                    <mat-error *ngIf="form.getError('required', ['ula_direction'])">Обов'язкове поле</mat-error>
                  </mat-form-field>
                </div>
              </div>

              <div>
                <mat-divider></mat-divider>
                <div class="p-15-1em mw-100" formGroupName="conf">
                  <mat-slide-toggle formControlName="en_doa"
                                    class="mw-100" color="primary">DoA (Визначення Напрямку)</mat-slide-toggle>
                </div>
                <mat-divider></mat-divider>
              </div>

              <div *ngIf="form.controls['conf'].get('en_doa')?.value">
                <div class="pb-1em pt-2em" formGroupName="conf">
                  <mat-form-field class="w-100" appearance="outline">
                    <mat-label>Алгоритм визначення</mat-label>
                    <mat-select formControlName="doa_method" required>
                      <mat-option [value]="'MUSIC'">MUSIC</mat-option>
                      <mat-option [value]="'Bartlett'">Bartlett</mat-option>
                      <mat-option [value]="'Capon'">Capon</mat-option>
                      <mat-option [value]="'MEM'">MEM</mat-option>
                      <mat-option [value]="'TNA'">TNA</mat-option>
                    </mat-select>
                    <mat-error *ngIf="form.getError('required', ['doa_method'])">Обов'язкове поле</mat-error>
                  </mat-form-field>
                </div>
                <div class="pb-1em" formGroupName="conf">
                  <mat-form-field class="w-100" appearance="outline">
                    <mat-label>Декореляція</mat-label>
                    <mat-select formControlName="doa_decorrelation_method" required>
                      <mat-option [value]="'Off'">Виключено</mat-option>
                      <mat-option [value]="'FBA'">F-B Averaging</mat-option>
                      <mat-option [value]="'TOEP'">Toeplizification</mat-option>
                      <mat-option [value]="'FBSS'">Spatial Smoothing</mat-option>
                      <mat-option [value]="'FBTOEP'">F-B Toeplitz</mat-option>
                    </mat-select>
                    <mat-error *ngIf="form.getError('required', ['doa_decorrelation_method'])">Обов'язкове поле</mat-error>
                  </mat-form-field>
                </div>
                <div class="pb-1em" formGroupName="conf">
                  <mat-form-field class="w-100" appearance="outline" floatLabel="always">
                    <mat-label>Компенсація азімуту</mat-label>
                    <span matTextSuffix>гр</span>
                    <input formControlName="array_offset" matInput placeholder="0">
                    <span matTextSuffix>º</span>
                    <mat-error *ngIf="form.getError('required', ['array_offset'])">Обов'язкове поле</mat-error>
                  </mat-form-field>
                </div>
                <div class="pb-1em" formGroupName="conf">
                  <mat-form-field class="w-100" appearance="outline">
                    <mat-label>Кількість сигналів</mat-label>
                    <mat-select formControlName="expected_num_of_sources" required>
                      <mat-option [value]="1">1</mat-option>
                      <mat-option [value]="2">2</mat-option>
                      <mat-option [value]="3">3</mat-option>
                      <mat-option [value]="4">4</mat-option>
                    </mat-select>
                    <mat-error *ngIf="form.getError('required', ['expected_num_of_sources'])">Обов'язкове поле</mat-error>
                  </mat-form-field>
                </div>
              </div>
            </div>

            <!-- Display Options -->
            <div class="col-sm-12 col-md-6 col-lg-4 col-xl-3 col-xxl-2 p-1em">
              <div>
                <h3>Відображення:</h3>
              </div>
              <div class="pb-1em" formGroupName="conf">
                <mat-form-field class="w-100" appearance="outline" floatLabel="always">
                  <mat-label>Граф тип</mat-label>
                  <mat-select formControlName="doa_fig_type" required>
                    <mat-option [value]="'Compass'">Компас</mat-option>
                    <mat-option [value]="'Polar'">Полар</mat-option>
                    <mat-option [value]="'Linear'">Лінійний</mat-option>
                  </mat-select>
                  <mat-error *ngIf="form.getError('required', ['doa_fig_type'])">Обов'язкове поле</mat-error>
                </mat-form-field>
              </div>
              <div formGroupName="conf">
                <mat-divider></mat-divider>
                <div class="p-15-1em mw-100">
                  <mat-slide-toggle formControlName="en_peak_hold"
                                    class="mw-100" color="primary">Тримати пік спектру</mat-slide-toggle>
                </div>
                <mat-divider></mat-divider>
              </div>
            </div>

          <!--</div>-->
          <!--<div class="row">-->

            <!-- Station Information -->
            <div class="col-sm-12 col-md-6 col-lg-4 col-xl-3 col-xxl-2 p-1em">
              <div>
                <h3>Системна інформація:</h3>
              </div>
              <div class="pb-1em" formGroupName="conf">
                <mat-form-field class="w-100" appearance="outline" floatLabel="always">
                  <mat-label>Ім'я Пеленгатора</mat-label>
                  <input matInput formControlName="station_id"
                         placeholder="Пеленгатор-1">
                  <mat-error *ngIf="form.getError('required', ['station_id'])">Введіть правильне значення</mat-error>
                </mat-form-field>
              </div>
              <div class="pb-1em" formGroupName="conf">
                <mat-form-field class="w-100" appearance="outline" floatLabel="always">
                  <mat-label>Протокол даних</mat-label>
                  <mat-select formControlName="doa_data_format" required>
                    <mat-option [value]="'Eter Cloud'">Eter Cloud</mat-option>
                    <mat-option [value]="'Kraken App'" disabled>Kraken App</mat-option>
                    <mat-option [value]="'Kraken Pro Local'" disabled>Kraken Pro Local</mat-option>
                    <mat-option [value]="'Kraken Pro Remote'" disabled>Kraken Pro Remote</mat-option>
                    <mat-option [value]="'Kerberos App'" disabled>Kerberos App</mat-option>
                    <mat-option [value]="'DF Aggregator'" disabled>DF Aggregator</mat-option>
                    <mat-option [value]="'RDF Mapper'" disabled>RDF Mapper</mat-option>
                    <mat-option [value]="'Full POST'" disabled>Full POST</mat-option>
                  </mat-select>
                  <mat-error *ngIf="form.getError('required', ['doa_data_format'])">Обов'язкове поле</mat-error>
                </mat-form-field>
              </div>
              <div class="pb-1em" *ngIf="form.controls['conf'].get('doa_data_format')?.value === 'Kraken Pro Remote'">
                <mat-form-field class="w-100" appearance="outline" floatLabel="always" formGroupName="conf">
                  <mat-label>Ключ Kraken Pro</mat-label>
                  <input matInput formControlName="krakenpro_key"
                         placeholder="0">
                  <mat-error *ngIf="form.getError('required', ['krakenpro_key'])">Введіть правильне значення</mat-error>
                </mat-form-field>
              </div>
              <div class="pb-1em" *ngIf="form.controls['conf'].get('doa_data_format')?.value === 'RDF Mapper' ||
                                         form.controls['conf'].get('doa_data_format')?.value === 'Full POST'">
                <mat-form-field class="w-100" appearance="outline" floatLabel="always" formGroupName="conf">
                  <mat-label>URL Сервера (RDF Mapper)</mat-label>
                  <input matInput formControlName="rdf_mapper_server"
                         placeholder="0">
                  <mat-error *ngIf="form.getError('required', ['rdf_mapper_server'])">Введіть правильне значення</mat-error>
                </mat-form-field>
              </div>
              <div>
                <div class="pb-1em" formGroupName="conf">
                  <mat-form-field class="w-100" appearance="outline">
                    <mat-label>Позиціонування</mat-label>
                    <mat-select required formControlName="location_source">
                      <mat-option [value]="'Static'">Вручну</mat-option>
                      <mat-option [value]="'gpsd'">Авто (GPS)</mat-option>
                      <mat-option [value]="'None'">Відключено</mat-option>
                    </mat-select>
                  </mat-form-field>
                </div>
                <div *ngIf="form.controls['conf'].get('location_source')?.value === 'Static'">
                  <!-- only when static -->
                  <!--<div class="pb-1em" formGroupName="conf">
                    <mat-form-field class="w-100" appearance="outline" floatLabel="always">
                      <mat-label>Широта</mat-label>
                      <input matInput formControlName="latitude"
                             placeholder="52.0000">
                      <mat-error *ngIf="form.getError('required', ['latitude'])">
                        Обов'язкове поле
                      </mat-error>
                    </mat-form-field>
                  </div>
                  <div class="pb-1em" formGroupName="conf">
                    <mat-form-field class="w-100" appearance="outline" floatLabel="always">
                      <mat-label>Довгота</mat-label>
                      <input matInput formControlName="longitude"
                             placeholder="43.0000">
                      <mat-error *ngIf="form.getError('required', ['longitude'])">Обов'язкове поле</mat-error>
                    </mat-form-field>
                  </div>-->
                  <div class="pb-1em" formGroupName="conf">
                    <mat-form-field class="w-100" appearance="outline" floatLabel="always">
                      <mat-label>Координати</mat-label>
                      <input matInput formControlName="latlng"
                             placeholder="52.0000, 32.0000">
                      <mat-error *ngIf="form.getError('required', ['latlng'])">
                        Обов'язкове поле
                      </mat-error>
                    </mat-form-field>
                  </div>
                </div>
                <div *ngIf="form.controls['conf'].get('location_source')?.value === 'gpsd'">
                  <mat-divider></mat-divider>
                  <div class="p-15-1em mw-100" formGroupName="conf">
                    <mat-slide-toggle formControlName="fixed_heading_check"
                                      class="mw-100" color="primary">Статичний напрямок</mat-slide-toggle>
                  </div>
                  <mat-divider></mat-divider>
                </div>
                <div *ngIf="form.controls['conf'].get('location_source')?.value === 'gpsd' &&
                          !form.controls['conf'].get('fixed_heading_check')?.value">
                  <div class="pb-1em" formGroupName="conf">
                    <mat-form-field class="w-100" appearance="outline" floatLabel="always">
                      <mat-label>Мінімальна швидкість</mat-label>
                      <input matInput formControlName="gps_min_speed"
                             placeholder="2">
                      <span matTextSuffix>м\с</span>
                      <mat-error *ngIf="form.getError('required', ['gps_min_speed'])">Обов'язкове поле</mat-error>
                    </mat-form-field>
                  </div>
                  <div class="pb-1em" formGroupName="conf">
                    <mat-form-field class="w-100" appearance="outline" floatLabel="always">
                      <mat-label>Мінімальна тривалість швидкості</mat-label>
                      <input matInput formControlName="gps_min_speed_duration"
                             placeholder="3">
                      <span matTextSuffix>с</span>
                      <mat-error *ngIf="form.getError('required', ['gps_min_speed_duration'])">Обов'язкове поле</mat-error>
                    </mat-form-field>
                  </div>
                </div>
                <div *ngIf="form.controls['conf'].get('location_source')?.value === 'Static' ||
                          (form.controls['conf'].get('location_source')?.value === 'gpsd' &&
                           form.controls['conf'].get('fixed_heading_check')?.value)">
                  <div class="pb-1em" formGroupName="conf">
                    <mat-form-field class="w-100" appearance="outline" floatLabel="always">
                      <mat-label>Напрямок</mat-label>
                      <input matInput formControlName="heading" #heading
                             placeholder="0.0">
                      <span matTextSuffix>&nbsp;º</span>
                      <mat-error *ngIf="form.getError('required', ['heading'])">Обов'язкове поле</mat-error>
                      <mat-error *ngIf="form.getError('min', ['heading'])">Має бути між 0 до 360</mat-error>
                      <mat-error *ngIf="form.getError('max', ['heading'])">Має бути між 0 до 360</mat-error>
                    </mat-form-field>
                  </div>
                </div>
              </div>
            </div>

            <!-- VFO -->
            <div class="col-sm-12 col-md-6 col-lg-4 col-xl-3 col-xxl-2 p-1em">
              <div>
                <h3>Параметри генератора (VFO):</h3>
              </div>
              <div class="pb-1em" formGroupName="conf">
                <mat-form-field class="w-100" appearance="outline" floatLabel="always">
                  <mat-label>Робоча частота VFO-0</mat-label>
                  <input matInput formControlName="vfo_freq_0"
                         placeholder="460.800">
                  <span matTextSuffix>МГц</span>
                  <mat-error *ngIf="form.getError('required', ['vfo_freq_0'])">Обов'язкове поле</mat-error>
                </mat-form-field>
              </div>
              <div class="pb-1em" formGroupName="conf">
                <mat-form-field class="w-100" appearance="outline" floatLabel="always">
                  <mat-label>Роздільна здатність VFO-0</mat-label>
                  <input matInput formControlName="vfo_bw_0"
                         placeholder="12000">
                  <span matTextSuffix>Гц</span>
                  <mat-error *ngIf="form.getError('required', ['vfo_bw_0'])">Обов'язкове поле</mat-error>
                </mat-form-field>
              </div>
              <div class="pb-1em" formGroupName="conf">
                <mat-form-field class="w-100" appearance="outline" floatLabel="always">
                  <mat-label>Чутливість VFO-0</mat-label>
                  <input matInput formControlName="vfo_squelch_0"
                         placeholder="-60">
                  <span matTextSuffix>дБ</span>
                  <mat-error *ngIf="form.getError('required', ['vfo_squelch_0'])">Обов'язкове поле</mat-error>
                </mat-form-field>
              </div>
            </div>

          </div>

        </div>
      </form>
    </div>
</div>

