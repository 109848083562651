import { Pipe, PipeTransform } from '@angular/core';
import {StationStatus} from "../stations/stations.component";

@Pipe({name: 'statusToString'})
export class AppStationStatusPipe implements PipeTransform {

  transform(value: StationStatus|undefined): string {
    switch (value) {
      case StationStatus.NEW:
        return "НОВИЙ";
      case StationStatus.UNKNOWN:
        return "НЕВІДОМО";
      case StationStatus.OKAY:
        return "ОНЛАЙН";
      case StationStatus.WARNING:
        return "УВАГА";
      case StationStatus.FAIL:
        return "ОФФЛАЙН";
      default:
        // console.warn("Unknown StationStatus received:", value);
    }
    return "НЕВІДОМО";
  }
}

@Pipe({name: 'statusToClassString'})
export class AppStationStatusClassPipe implements PipeTransform {

  transform(value: StationStatus|undefined): string {
    switch (value) {
      case StationStatus.UNKNOWN:
        return "";
      case StationStatus.NEW:
        return "new";
      case StationStatus.OKAY:
        return "success";
      case StationStatus.WARNING:
        return "warning";
      case StationStatus.FAIL:
        return "error";
      default:
        // console.warn("Unknown StationStatus received:", value);
    }
    return "";
  }
}
