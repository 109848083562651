import {AfterViewInit, ChangeDetectorRef, Component, Input, OnDestroy} from '@angular/core';
import {MatRipple} from "@angular/material/core";
import {Station, StationStatus} from "../stations.component";
import {config} from "rxjs";
import {ApiResponse, ApiService} from "../../api.service";
import {HttpResponse} from "@angular/common/http";
import {Unary} from "@angular/compiler";

@Component({
  selector: 'app-station-preview',
  templateUrl: './station-preview.component.html',
  styleUrls: ['./station-preview.component.scss']
})
export class StationPreviewComponent implements AfterViewInit, OnDestroy {
  @Input() id: number|undefined = undefined;
  @Input() name: string|undefined = undefined;
  @Input() status: StationStatus|undefined = undefined;
  @Input() groupId: string|undefined = undefined;
  @Input() groupName: string|undefined = undefined;
  @Input() hideGroupName: boolean = false;
  @Input() uptime: number|null|undefined = undefined;
  @Input() serial: string|undefined = undefined;
  @Input() frequency: number|undefined = undefined;
  @Input() location: string|undefined = undefined;
  @Input() connected: boolean = false;
  @Input() power: boolean|null|undefined = undefined;
  @Input() battery: number|null|undefined = undefined;
  @Input() temperature_run: number|null|undefined = undefined;
  @Input() temperature_gs: number|null|undefined = undefined;
  @Input() uplink_a: boolean|null|undefined = undefined;
  @Input() uplink_b: boolean|null|undefined = undefined;
  protected readonly config = config;
  protected readonly undefined = undefined;

  private interval: any;

  constructor(private apiService: ApiService,
              private cdr: ChangeDetectorRef) {
  }

  renderItem() {
    this.status = this.connected ? StationStatus.OKAY : StationStatus.FAIL;
    this.cdr.detectChanges();
  }

  updateStation() {
    this.apiService.getStation(this.id).subscribe({
      next: (response: HttpResponse<ApiResponse<Station>> | undefined) => {
        const item = response?.body?.data?.object;
        if (item) {
          this.name = item.name;
          this.groupId = item.groupId;
          this.groupName = item.groupName;
          this.uptime = item.uptime_run;
          this.serial = item.serial;
          if (item.conf) {
            this.frequency = item.conf.center_freq;
          }
          this.location = item.location;
          this.power = item.power;
          this.battery = item.battery;
          this.temperature_run = item.temperature_run;
          this.temperature_gs = item.temperature_gs;
          this.uplink_a = item.uplink_a;
          this.uplink_b = item.uplink_b;
          this.renderItem();
        }
      }
    });
  }

  ngAfterViewInit() {
    this.renderItem();
    this.interval = setInterval(()=>{
      this.updateStation();
    }, 10000);
  }

  ngOnDestroy() {
    clearInterval(this.interval);
  }

}
