import {AfterViewInit, Component, Inject, OnInit} from '@angular/core';
import {LoaderService} from "../loader.service";
import {Router} from "@angular/router";
import {
  LINK_GROUP_NEW,
  LINK_GROUPS,
  LINK_MAIN,
  LINK_STATIONS,
  ROUTE_GROUP_ID,
  ROUTE_GROUP_NEW
} from "../app.urls";
import {Station, StationStatus} from "../stations/stations.component";
import {ApiResponse, ApiService} from "../api.service";
import {HttpResponse} from "@angular/common/http";
import {finalize} from "rxjs";
import {MAT_DIALOG_DATA, MatDialog, MatDialogModule, MatDialogRef} from "@angular/material/dialog";
import {MatButtonModule} from "@angular/material/button";
import {DialogStationInfo} from "../stations/station/station.component";
import {Dialog} from "@angular/cdk/dialog";
import {MatFormFieldModule} from "@angular/material/form-field";
import {MatInputModule} from "@angular/material/input";
import {NgIf} from "@angular/common";
import {FormControl, FormGroup, ReactiveFormsModule, Validators} from "@angular/forms";
import {NotificationService} from "../notification/notification.service";
import {PageEvent} from "@angular/material/paginator";
import {Paginator} from "../common/app.paginator";
import {MatSnackBar} from "@angular/material/snack-bar";

export interface GroupConfig {
  center_freq: number,
  uniform_gain: number,
  en_doa: boolean,
  ant_arrangement: string,
  ula_direction: string,
  ant_spacing_meters: number,
  array_offset: number,
  compass_offset: number,
  doa_fig_type: string,
  vfo_mode: string,
  vfo_bw_0: number,
  vfo_freq_0: number,
  vfo_squelch_0: number,
  //
  name: string | undefined;
  id: number
}

export interface Group {
  id: number,
  name: string,
  conf: GroupConfig,
  stations: Station[],
}


@Component({
  selector: 'app-create-new-group',
  templateUrl: './groups-create-new.component.html',
  standalone: true,
  imports: [MatDialogModule, MatButtonModule, MatFormFieldModule, MatInputModule, NgIf, ReactiveFormsModule],
})
export class DialogCreateNew {
  public createForm: FormGroup = new FormGroup({
    name: new FormControl('', [Validators.required]),
  });

  constructor(public dialogRef: MatDialogRef<DialogCreateNew>) {}

  submit() {
    this.dialogRef.close(this.createForm.getRawValue().name);
  }
}


@Component({
  selector: 'app-groups',
  templateUrl: './groups.component.html',
  styleUrls: ['./groups.component.scss']
})
export class GroupsComponent implements AfterViewInit {

  public items: Group[] = [];
  protected paginator: Paginator|undefined;
  protected done = false;

  constructor(private loadService: LoaderService, private apiService: ApiService,
              protected router: Router, protected dialog: MatDialog,
              private snackBar: MatSnackBar, private loaderService: LoaderService) {
  }

  ngAfterViewInit() {
    this.getGroups();
  }

  protected createNew() {
    const dialogRef = this.dialog.open(DialogCreateNew);
    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        this.loadService.startLoading();
        this.apiService.createGroup(result).pipe(finalize(() => {
            this.loadService.stopLoading();
        })).subscribe({
          next: (response: HttpResponse<ApiResponse<Group>>) => {
            const group = response.body?.data?.object;
            if (group && group.id) {
              this.snackBar.open('Комплекс створено!', 'Ок', {duration: 7000})
              this.router.navigateByUrl(`${LINK_GROUPS}/${group.id}`).then();
              return;
            }
            this.getGroups();
            return;
          },
          error: (error: any) => {
            this.snackBar.open('Щось пішло не так!', 'Ок', {duration: 7000})
          }
        });
      }
    });
  }

  handlePageEvent(event: PageEvent) {
    console.log("groups::handlePageEvent:", event.pageIndex);
    this.getGroups(event.pageIndex);
  }

  public getGroups(pageIndex: number|undefined = undefined) {
    this.loadService.startLoading();
    this.apiService.getGroups(pageIndex).pipe(finalize(() => {
      this.loadService.stopLoading();
    })).subscribe({
      next: (response: HttpResponse<ApiResponse<Group[]>>) => {
        if (response.body?.data?.objects) {
          this.items = response.body?.data.objects;
        }
        if (response.body?.data?.paginator) {
          this.paginator = response.body?.data.paginator;
        }
        this.done = true;
      },
      error: (error: any) => {}
    });
  }

  protected readonly LINK_MAIN = LINK_MAIN;
  protected readonly LINK_GROUPS = LINK_GROUPS;
}
