<div class="station-status-block">
  <div class="station-status">
    <div class="status-text">
      <span class="{{ status | statusToClassString }}"><span>{{status | statusToString}}</span></span>
    </div>
  </div>
  <div class="station-uptime"><mat-icon>access_time</mat-icon><span>{{uptime|uptimeToString}}</span></div>
</div>
<div class="station-info-block">
  <div class="station-info-name"><span>{{ name }}</span></div>
  <div class="station-info-group" *ngIf="!hideGroupName">
    <span class="sub-icon-text">
      <mat-icon>device_hub</mat-icon>
      <span>{{ groupName || '----------' }}</span>
    </span>
  </div>
  <div class="station-info-other">
    <span class="sub-icon-text">
      <mat-icon>reorder</mat-icon>
      <span>{{ serial || '----------'}}</span>
    </span>
    <span class="sub-icon-text">
      <mat-icon>bar_chart</mat-icon>
      <span>{{frequency || '----------'}} МГц</span>
    </span>
    <span class="sub-icon-text">
      <mat-icon>location_on</mat-icon>
      <span>{{ location || '----------'}}</span>
    </span>
  </div>
</div>
<div class="station-box-block">
  <div class="station-box-a-info">
    <div class="status-text"><span [class.success]="temperature_run"><span>RUN</span><mat-icon class="internet">thermostat</mat-icon><span>{{ temperature_run || '--' }} Cº</span></span></div>
  </div>
  <div class="station-box-b-info">
    <div class="status-text"><span [class.success]="uplink_a"><mat-icon class="internet">language</mat-icon><span>A</span></span></div>
    <div class="status-text"><span [class.success]="uplink_b"><mat-icon class="internet">language</mat-icon><span>B</span></span></div>
    <div class="status-text"><span [class.success]="power"><mat-icon class="internet">bolt</mat-icon></span></div>
    <div class="status-text"><span [class.success]="battery"><mat-icon class="internet">battery_std</mat-icon><span>{{ battery || '--' }} %</span></span></div>
    <div class="status-text"><span [class.success]="temperature_gs"><span>GS</span><mat-icon class="internet">thermostat</mat-icon><span>{{ temperature_gs || '--' }} Cº</span></span></div>
  </div>
</div>

