<div class="app-content">
  <app-navbar>
    <div class="app-content-breadcrumbs">
      <app-breadcrumbs>
        <ul>
          <li><a mat-button [routerLink]="LINK_MAIN">Головна</a></li>
          <li><a mat-button [routerLink]="LINK_GROUPS">Комплекси</a></li>
          <li><a mat-button disabled>Комплекс</a></li>
        </ul>

        <a *ngIf="activeTab == 1" [disabled]="!form?.valid || form?.pristine" (click)="save()"
           extra mat-mini-fab color="primary"
           aria-label="Add" class="d-sm-flex d-md-none">
          <mat-icon>check</mat-icon>
        </a>

        <a *ngIf="activeTab == 1" [disabled]="!form?.valid || form?.pristine" (click)="save()"
           extended extra mat-fab color="primary"
           aria-label="Add" class="d-none d-md-flex">
          <mat-icon>check</mat-icon>
          <span>Зберегти</span>
        </a>

      </app-breadcrumbs>
    </div>
    <div class="app-content-container">
      <div class="page">
        <div class="tab-nav-header">
          <div class="tab-nav-item-name">
            <span *ngIf="item" class="item-name">{{ item.name }}</span>
            <span *ngIf="!item" class="item-name-placeholder">&nbsp;</span>
            <span *ngIf="item" class="status-text">
              <span class="{{ status|statusToClassString }}">
                <mat-icon>info</mat-icon>
                <span>{{ status|statusToString }}</span>
              </span>
            </span>
          </div>
          <div class="tab-nav-tabs">
            <mat-tab-group (selectedTabChange)="onNavTabChange($event)"
                           [selectedIndex]="activeTab"
                           animationDuration="0ms" mat-stretch-tabs="false"
                           mat-align-tabs="end">
              <mat-tab><ng-template matTabLabel>Пеленгатори</ng-template></mat-tab>
              <mat-tab><ng-template matTabLabel>Налаштування</ng-template></mat-tab>
              <mat-tab><ng-template matTabLabel>Візуалізація</ng-template></mat-tab>
            </mat-tab-group>
          </div>
        </div>
        <mat-divider></mat-divider>
        <div class="station-details"><router-outlet (activate)="onOutletLoaded($event)"></router-outlet></div>
      </div>
    </div>
    <!--<div class="app-content-footer">hello! im footer</div>-->
  </app-navbar>
</div>
