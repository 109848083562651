<div class="app-content">
  <app-navbar>
    <div class="app-content-breadcrumbs">
      <app-breadcrumbs>
        <ul>
          <li><a mat-button [routerLink]="LINK_MAIN">Головна</a></li>
          <li><a mat-button disabled>Мапа</a></li>
          <li *ngIf="activeGroup"><a mat-button disabled>{{ activeGroup.name }}</a></li>
        </ul>

        <a extra mat-mini-fab color="primary" disabled
           aria-label="Add" class="d-sm-flex d-md-none">
          <mat-icon>pin_drop</mat-icon>
        </a>
        <a extended extra mat-fab color="primary" disabled
           aria-label="Add" class="d-none d-md-flex">
          <mat-icon>pin_drop</mat-icon>
          Калібрувати
        </a>
      </app-breadcrumbs>
    </div>
    <div class="app-content-container">
      <div class="page">
        <mat-drawer-container>
          <mat-drawer #drawer mode="over" class="map-sidenav">
            <div class="map-nav-wrapper">
              <mat-paginator *ngIf="paginator"
                             [length]="paginator.totalObjects"
                             [pageIndex]="paginator.currPage"
                             [pageSize]="paginator.pageSize"
                             (page)="handlePageEvent($event)"
                             [showFirstLastButtons]="true"
                             aria-label="Оберіть сторінку"
                             class="paginator-no-text margin-t-1em "/>
              <mat-list>
                <a mat-list-item matRipple *ngFor="let group of groups"
                   [class.active]="group == activeGroup"
                   (click)="setActiveGroup(group)">{{ group.name }}</a>
              </mat-list>
            </div>
          </mat-drawer>
          <div class="map-wrapper">
            <div id="map"></div>
            <div class="map-menu">
              <div>
                <button type="button" color="basic" mat-mini-fab (click)="drawer.toggle()">
                  <mat-icon>menu</mat-icon>
                </button>
              </div>
              <div>
                <button type="button" color="basic" mat-mini-fab (click)="zoomIn()">
                  <mat-icon>add</mat-icon>
                </button>
              </div>
              <div>
                <button type="button" color="basic" mat-mini-fab (click)="zoomOut()">
                  <mat-icon>remove</mat-icon>
                </button>
              </div>
              <div>
                <button type="button" color="basic" mat-mini-fab (click)="toggleMyLocation()">
                  <mat-icon *ngIf="position?.status === 0">location_disabled</mat-icon>
                  <mat-icon *ngIf="position?.status === 1">my_location</mat-icon>
                  <mat-icon *ngIf="position?.status === 2">location_searching</mat-icon>
                </button>
              </div>
            </div>
          </div>
        </mat-drawer-container>
      </div>
    </div>
    <!--<div class="app-content-footer">hello! im footer</div>-->
  </app-navbar>
</div>
