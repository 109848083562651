<div class="app-content">
  <app-navbar>
    <div class="app-content-breadcrumbs">
      <app-breadcrumbs>
        <ul>
          <li><a mat-button [routerLink]="LINK_MAIN">Головна</a></li>
          <li><a mat-button disabled>Сканери</a></li>
        </ul>
        <a extra mat-mini-fab color="primary" aria-label="Add" class="d-sm-flex d-md-none">
          <mat-icon>add</mat-icon>
        </a>

        <a extended extra mat-fab color="primary" aria-label="Add" class="d-none d-md-flex">
          <mat-icon>add</mat-icon>
          Додати
        </a>
      </app-breadcrumbs>
    </div>
    <div class="app-content-container">
      <div class="page no-items">
        <div>
          В розробці
        </div>
      </div>
    </div>
    <!--<div class="app-content-footer">hello! im footer</div>-->
  </app-navbar>
</div>
