import { Component } from '@angular/core';
import {LINK_MAIN} from "../app.urls";

@Component({
  selector: 'app-scanners',
  templateUrl: './scanners.component.html',
  styleUrls: ['./scanners.component.scss']
})
export class ScannersComponent {

  protected readonly LINK_MAIN = LINK_MAIN;
}
