import { Component,
         OnInit } from '@angular/core';
import { NotificationService } from './notification.service';
import { Notification,
         NotificationType } from './notification';

@Component({
  selector: 'app-notification',
  templateUrl: './notification.component.html',
  styleUrls: ['./notification.component.scss']
})
export class NotificationComponent implements OnInit {

  public notifications: Array<Notification> = new Array<Notification>();
  public type = NotificationType;

  constructor(private notificationService: NotificationService) {
    this.notificationService.notifications.subscribe((notification: Notification) => {
      this.notifications.push(notification);

      this.removeDelayed(notification, 5000);
    });
  }

  ngOnInit() {
  }

  remove(notification: Notification) {
    this.notifications.splice(this.notifications.indexOf(notification), 1);
  }

  removeDelayed(notification: Notification, delay: number) {
    setTimeout(() => { this.remove(notification); }, delay);
  }

}
