import { Inject,
         Injectable } from '@angular/core';
import { DOCUMENT } from '@angular/common';
import {Observable} from 'rxjs';


@Injectable()
export class LoaderService {

  isLoading = false;
  loadingStatus = '';
  globalSpinnerElement:HTMLElement|null = null;
  progressStatusElement: HTMLElement|null = null;
  loaderCalls: number = 0;

  constructor(@Inject(DOCUMENT) private document: Document) {
    this.globalSpinnerElement = this.document.getElementById('global-spinner');
    this.progressStatusElement = this.document.getElementById('progress-status');
  }

  incrementCalls() {
    this.loaderCalls += 1;
  }

  decrementCalls() {
    this.loaderCalls -= 1;
    if (this.loaderCalls < 0) {
      this.loaderCalls = 0;
    }
  }

  startLoading() {
    this.incrementCalls();
    this.clearStatus();
    if (this.globalSpinnerElement) {
      this.globalSpinnerElement.classList.remove('none');
      this.globalSpinnerElement.style.animationPlayState = 'running';
      this.globalSpinnerElement.style.webkitAnimationPlayState = 'running';
    }
  }

  stopLoading() {
    this.decrementCalls();
    this.clearStatus();
    if (this.globalSpinnerElement && this.loaderCalls == 0) {
        this.globalSpinnerElement.classList.add('none');
        this.globalSpinnerElement.style.webkitAnimationPlayState = 'paused';
    }
  }

  setStatus(value: number) {
    if (this.progressStatusElement) {
        this.progressStatusElement.innerText = value + '%';
    }
  }

  clearStatus() {
    if (this.progressStatusElement) {
      this.progressStatusElement.innerText = '';
    }
  }
}
