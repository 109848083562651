import {NotificationService} from "./notification/notification.service";
import {Injectable} from "@angular/core";
import {WEB_SOCKET_ENDPOINT_DOA_v1_1} from "./app.urls";
import {StorageService} from "./storage.service";
import {Observable, Subject, Subscriber} from "rxjs";


@Injectable()
export class StationsDoAService {

  private worker: Worker|undefined;
  private session: string|null;
  private workerObservable: Observable<any>|undefined;
  private workerSubscriber: Subscriber<any>|undefined;
  private subject: Subject<any> | undefined;

  constructor(private storageService: StorageService,
              private notificationService: NotificationService) {
    this.session = this.storageService.getSession();
    const url = WEB_SOCKET_ENDPOINT_DOA_v1_1 + '?session=' + this.session;
    if (window.Worker && this.session) {
      this.worker = new Worker('/assets/workers/map-worker.js');
      this.worker.onmessage = (event: MessageEvent) => this.onMessage(event);
      this.worker?.postMessage(["INIT", url]);
      this.subject = new Subject();
      this.workerObservable = new Observable(subscriber => {
        this.workerSubscriber = subscriber;
      });
    } else {
      notificationService.warning('Не вдалось отримати Worker API!');
    }
  }

  public subscribeStation(stationSerial: string) {
    this.worker?.postMessage(["SUBSCRIBE", stationSerial]);
    return this.subject;
  }

  public unsubscribeStation(stationSerial: string) {
    this.worker?.postMessage(["UNSUBSCRIBE", stationSerial]);
  }

  private onMessage(event: MessageEvent) {
    this.subject?.next(event.data)
  }
}
