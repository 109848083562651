import {Injectable} from "@angular/core";
import {finalize, forkJoin, Observable, Subscription} from "rxjs";

@Injectable()
export class AsyncProgressBarService {

  busy: boolean = false;
  jobs: Observable<any>[] = [];
  subscription: Subscription|undefined;

  constructor() {
  }

  addAsyncJob(job: Observable<any>) {
    this.subscription?.unsubscribe();
    this.jobs.push(job);
    this.busy = true;
    setTimeout(() => {
      this.subscription = forkJoin(this.jobs).subscribe({
        complete: () => {
          this.jobs = [];
          this.busy = false;
        }
      });
    }, 0);
  }
}
