import {
  AfterViewInit,
  ChangeDetectorRef,
  Component, Inject,
  OnDestroy,
  OnInit,
  ViewChild,
  ViewEncapsulation
} from '@angular/core';
import {LoaderService} from "../../../loader.service";
import {
  AbstractControl, FormBuilder, FormControl, FormGroup, FormGroupDirective,
  ValidationErrors, ValidatorFn, Validators
} from "@angular/forms";
import {ActivatedRoute, Router} from "@angular/router";
import {debounceTime, finalize, forkJoin, Observable, Subscription, switchMap} from "rxjs";
import {StationComponent} from "../station.component";
import {Station, StationConfig} from "../../stations.component";
import {ApiResponse, ApiService} from "../../../api.service";
import {HttpResponse} from "@angular/common/http";
import {MatDialog, MatDialogModule} from "@angular/material/dialog";
import {MatButtonModule} from "@angular/material/button";
import {NotificationService} from "../../../notification/notification.service";
import {MatSnackBar} from "@angular/material/snack-bar";

export type ReceiverSettingsInterface = "shmem";
export type ReceiverSettingsArrayType = "ULA" | "UCA" | "Custom";
export type ReceiverSettingsUlaDirection = "Both" | "Backward" | "Forward";
export type ReceiverSettingsDoaMethod = "MUSIC" | "Bartlett" | "Capon" | "MEM" | "TNA";
export type ReceiverSettingsDecorelation = "Off" | "FBA" | "TOEP" | "FBSS" | "FBTOEP";
export type ReceiverSettingsDoaFigType = "Compass" | "Linear" | "Polar";
export type ReceiverSettingsDoaDataFormat = "Kraken App"
                                          | "Kraken Pro Local"
                                          | "Kraken Pro Remote"
                                          | "Eter Cloud"
                                          | "Kerberos App"
                                          | "DF Aggregator"
                                          | "RDF Mapper"
                                          | "Full POST";
export type ReceiverSettingsLocationSource = "Static" | "None" | "gpsd";
export type ReceiverSettingsSpectrumCalc = "Single" | "All";
export type ReceiverSettingsVfoMode = "Standard" | "Auto";

@Component({
  selector: 'app-station-restart-dialog',
  templateUrl: 'station-restart-dialog.component.html',
  styleUrls: [],
  standalone: true,
  imports: [MatButtonModule, MatDialogModule],
})
export class DialogRestartDevice {}


@Component({
  encapsulation: ViewEncapsulation.None,
  selector: 'app-station-settings',
  templateUrl: './station-settings.component.html',
  styleUrls: ['./station-settings.component.scss']
})
export class StationSettingsComponent implements OnDestroy, AfterViewInit {

  @ViewChild('formNoob') formNoob: FormGroupDirective | undefined;
  @ViewChild('formPro') formPro: FormGroupDirective | undefined;

  protected watcher: Subscription | undefined;
  protected watcherFrequency: Subscription | undefined;
  protected watcherVfoFrequency: Subscription | undefined;

  protected daq_conf_visible_basic: boolean = false;
  protected daq_conf_visible_advanced: boolean = false;

  public form: FormGroup = new FormGroup({
    proModeEnabled: new FormControl(false),
    conf: new FormGroup({
      center_freq: new FormControl(null, [Validators.required]),
      uniform_gain: new FormControl(null, [Validators.required]),

      // Comment(s1z): We do not use these for now
      // data_interface: new FormControl('', [Validators.required]),
      // default_ip: new FormControl('', [Validators.required]),

      en_doa: new FormControl('', [Validators.required]),
      ant_arrangement: new FormControl('', [Validators.required]),
      ula_direction: new FormControl('', [Validators.required]),
      ant_spacing_meters: new FormControl('', [Validators.required]),
      custom_array_x_meters: new FormControl('', [Validators.required]),
      custom_array_y_meters: new FormControl('', [Validators.required]),
      array_offset: new FormControl('', [Validators.required]),
      doa_method: new FormControl('', [Validators.required]),
      doa_decorrelation_method: new FormControl('', [Validators.required]),
      doa_fig_type: new FormControl('', [Validators.required]),
      en_peak_hold: new FormControl('', [Validators.required]),
      expected_num_of_sources: new FormControl('', [Validators.required]),
      en_hw_check: new FormControl('', [Validators.required]),
      logging_level: new FormControl('', [Validators.required]),
      disable_tooltips: new FormControl('', [Validators.required]),
      doa_data_format: new FormControl('', [Validators.required]),
      station_id: new FormControl('', [Validators.required]),
      location_source: new FormControl('', [Validators.required]),
      latitude: new FormControl('', [Validators.required]),
      longitude: new FormControl('', [Validators.required]),
      latlng: new FormControl('', [Validators.required]),
      heading: new FormControl('', [Validators.required]),

      // this is just for the heading
      fixed_heading_check: new FormControl(true),

      krakenpro_key: new FormControl('', [Validators.required]),
      rdf_mapper_server: new FormControl('', [Validators.required]),
      gps_min_speed: new FormControl('', [Validators.required]),
      gps_min_speed_duration: new FormControl('', [Validators.required]),
      spectrum_calculation: new FormControl('', [Validators.required]),
      vfo_mode: new FormControl('', [Validators.required]),
      dsp_decimation: new FormControl('', [Validators.required]),
      active_vfos: new FormControl('', [Validators.required]),
      output_vfo: new FormControl('', [Validators.required]),
      en_optimize_short_bursts: new FormControl('', [Validators.required]),
      vfo_bw_0: new FormControl('', [Validators.required]),
      vfo_freq_0: new FormControl('', [Validators.required]),
      vfo_squelch_0: new FormControl('', [Validators.required]),
    }),
    daq: new FormGroup({
      /* Section: Current config.
       * Comment: Get all configs from the device and let user select/edit those.
       *          As far as I can tell if you have chosen a config file and made some changes
       *          it becomes a "custom" config.
       */
      // Preconfigured DAQ Files
      // daq_cfg_files: new FormControl(null, []),

      // Basic Custom DAQ Configuration.
      cfg_data_block_len: new FormControl(null, []),
      cfg_recal_interval: new FormControl(null, []),

      // Advanced Custom DAQ Configuration.
      //   +--HW
      cfg_rx_channels: new FormControl(null, []),
      cfg_en_bias_tee: new FormControl(null, []),
      //   +--DAQ
      cfg_daq_buffer_size: new FormControl(null, []),
      cfg_sample_rate: new FormControl(null, []),
      en_noise_source_ctr: new FormControl(null, []),
      //   +--Pre Processing
      cfg_cpi_size: new FormControl(null, []),
      cfg_decimation_ratio:new FormControl(null, []),
      cfg_fir_bw: new FormControl(null, []),
      cfg_fir_tap_size: new FormControl(null, []),
      cfg_fir_window: new FormControl(null, []),
      en_filter_reset: new FormControl(null, []),
      //   +--Calibration
      cfg_corr_size: new FormControl(null, []),
      cfg_std_ch_ind: new FormControl('', []),
      en_iq_cal: new FormControl(null, []),
      cfg_gain_lock: new FormControl(null, []),
      en_req_track_lock_intervention: new FormControl(null, []),
      cfg_cal_track_mode: new FormControl(null, []),
      cfg_amplitude_cal_mode: new FormControl(null, []),
      cfg_cal_frame_interval: new FormControl(null, []),
      cfg_cal_frame_burst_size: new FormControl(null, []),
      cfg_amplitude_tolerance: new FormControl(null, []),
      cfg_phase_tolerance: new FormControl(1, [Validators.required, Validators.min(1)]),
      cfg_max_sync_fails: new FormControl(null, []),
      // cfg_iq_adjust_source: new FormControl(null, []),
      // cfg_iq_adjust_amplitude: new FormControl(null, []),
      // cfg_iq_adjust_time_delay_ns: new FormControl(null, [])
    })

  });

  protected gainParams = [
      {"label": "0 dB", "value": 0},
      {"label": "0.9 dB", "value": 0.9},
      {"label": "1.4 dB", "value": 1.4},
      {"label": "2.7 dB", "value": 2.7},
      {"label": "3.7 dB", "value": 3.7},
      {"label": "7.7 dB", "value": 7.7},
      {"label": "8.7 dB", "value": 8.7},
      {"label": "12.5 dB", "value": 12.5},
      {"label": "14.4 dB", "value": 14.4},
      {"label": "15.7 dB", "value": 15.7},
      {"label": "16.6 dB", "value": 16.6},
      {"label": "19.7 dB", "value": 19.7},
      {"label": "20.7 dB", "value": 20.7},
      {"label": "22.9 dB", "value": 22.9},
      {"label": "25.4 dB", "value": 25.4},
      {"label": "28.0 dB", "value": 28.0},
      {"label": "29.7 dB", "value": 29.7},
      {"label": "32.8 dB", "value": 32.8},
      {"label": "33.8 dB", "value": 33.8},
      {"label": "36.4 dB", "value": 36.4},
      {"label": "37.2 dB", "value": 37.2},
      {"label": "38.6 dB", "value": 38.6},
      {"label": "40.2 dB", "value": 40.2},
      {"label": "42.1 dB", "value": 42.1},
      {"label": "43.4 dB", "value": 43.4},
      {"label": "43.9 dB", "value": 43.9},
      {"label": "44.5 dB", "value": 44.5},
      {"label": "48.0 dB", "value": 48.0},
      {"label": "49.6 dB", "value": 49.6}
  ];

  protected daqCfgFilesParams = [
    {"label": "Current", "value": "Current"},
    {"label": "DEFAULT", "value": "kraken_default"},
    {"label": "DEV", "value": "kraken_dev"},
  ];

  protected daqBufferSizes = [
    {"label": "1024", "value": 1024},
    {"label": "2048", "value": 2048},
    {"label": "4096", "value": 4096},
    {"label": "8192", "value": 8192},
    {"label": "16384", "value": 16384},
    {"label": "32768", "value": 32768},
    {"label": "65536", "value": 65536},
    {"label": "131072", "value": 131072},
    {"label": "262144", "value": 262144},
    {"label": "524288", "value": 524288},
    {"label": "1048576", "value": 1048576},
  ];

  protected daqSampleRate = [
    {"label": "0.25", "value": 0.25},
    {"label": "0.900001", "value": 0.900001},
    {"label": "1.024", "value": 1.024},
    {"label": "1.4", "value": 1.4},
    {"label": "1.8", "value": 1.8},
    {"label": "1.92", "value": 1.92},
    {"label": "2.048", "value": 2.048},
    {"label": "2.4", "value": 2.4},
    {"label": "2.56", "value": 2.56},
    {"label": "3.2", "value": 3.2}
  ];

  protected daqFirWindow = [
    {"label": "Boxcar", "value": "boxcar"},
    {"label": "Triang", "value":"triang"},
    {"label": "Blackman", "value": "blackman"},
    {"label": "Hamming", "value": "hamming"},
    {"label": "Hann", "value": "hann"},
    {"label": "Bartlett", "value": "bartlett"},
    {"label": "Flattop", "value": "flattop"},
    {"label": "Parzen", "value": "parzen"},
    {"label": "Bohman", "value": "bohman"},
    {"label": "Blackmanharris", "value": "blackmanharris"},
    {"label": "Nuttall", "value": "nuttall"},
    {"label": "Barthann", "value": "barthann"},
  ];

  protected daqCalTrackModes = [
    {label: "Відключено", value: 0},
    {label: "Періодично", value: 2}
  ];
  protected daqAmplCalModes = [
    {label: "За замовчуванням", value: "default"},
    {label: "Відключено", value: "disabled"},
    {label: "Компенсація потужності", value: "channel_power"},
  ];

  protected vfoParams: { label: string, value: string }[] = [
    {label: "Стандартний", value: "Standard"},
    {label: "Авто (Макс)", value: "Auto"}
  ];

  constructor(private route: ActivatedRoute, private router: Router,
              private formBuilder: FormBuilder,
              private loaderService: LoaderService,
              @Inject(StationComponent) protected parent: StationComponent,
              private apiService: ApiService,
              public dialog: MatDialog,
              private snackBar: MatSnackBar) {
  }

  ngOnDestroy() {
    this.destroyWatchers();
  }

  resetConfig() {
    const conf = {
      version: 1,
      center_freq: 460.5,
      uniform_gain: 0.0,
      data_interface: "shmem",
      default_ip: "0.0.0.0",
      en_doa: true,
      ant_arrangement: "ULA",
      ula_direction: "Both",
      ant_spacing_meters: 0.21,
      custom_array_x_meters: "0.21,0.06,-0.17,-0.17,0.07",
      custom_array_y_meters: "0.00,-0.20,-0.12,0.12,0.20",
      array_offset: 0,
      doa_method: "MUSIC",
      doa_decorrelation_method: "Off",
      compass_offset: 0,
      doa_fig_type: "Polar",
      en_peak_hold: false,
      expected_num_of_sources: 1,
      en_hw_check: 0,
      logging_level: 5,
      disable_tooltips: 0,
      doa_data_format: "Eter Cloud",
      location_source: "None",
      latitude: 0.0,
      longitude: 0.0,
      latlng: "0, 0",
      heading: 0.0,
      krakenpro_key: "0ae4ca6b3",
      rdf_mapper_server: "http://MY_RDF_MAPPER_SERVER.com/save.php",
      gps_min_speed: 2,
      gps_min_speed_duration: 3,
      spectrum_calculation: "Single",
      vfo_mode: "Standard",
      dsp_decimation: 1,
      active_vfos: 1,
      output_vfo: 0,
      en_optimize_short_bursts: false,
      vfo_bw_0: 12500,
      vfo_freq_0: 460.5,
      vfo_squelch_0: -80
    };
    const daq = {
      cfg_data_block_len: 436.906,
      cfg_recal_interval: 5.0,
      cfg_rx_channels: 5,
      cfg_en_bias_tee: false,
      cfg_daq_buffer_size: 262144,
      cfg_sample_rate: 2.4,
      en_noise_source_ctr: true,
      cfg_cpi_size: 1048576,
      cfg_decimation_ratio: 1,
      cfg_fir_bw: 1,
      cfg_fir_tap_size: 1,
      cfg_fir_window: "hann",
      en_filter_reset: false,
      cfg_corr_size: 65536,
      cfg_std_ch_ind: 0,
      en_iq_cal: true,
      cfg_gain_lock: 0,
      en_req_track_lock_intervention: false,
      cfg_cal_track_mode: 2,
      cfg_amplitude_cal_mode: "channel_power",
      cfg_cal_frame_interval: 687,
      cfg_cal_frame_burst_size: 10,
      cfg_amplitude_tolerance: 2,
      cfg_phase_tolerance: 1,
      cfg_max_sync_fails: 10
    };
    this.form.patchValue({conf: conf, daq: daq});
  }

  restartDevice() {
    const id = this.parent.item?.id;
    if (id !== undefined) {
      this.dialog.open(DialogRestartDevice,
        {restoreFocus: false}).afterClosed().subscribe((result) => {
        if (result) {
          this.loaderService.startLoading();
          this.apiService.restartStation(id).pipe(finalize(
            () => this.loaderService.stopLoading()
          )).subscribe({
            next: _ => {
              this.snackBar.open('Вдало!', 'Ок');
            },
            error: _ => {
              this.snackBar.open('Щось пішло не так!', 'Ок');
            }
          });
          console.log("result:", this.parent.item?.id);
        }
      });
    }
  }

  renderLatLng(station: Station) {
    const latitude = station.conf?.latitude || 0;
    const longitude = station.conf?.longitude || 0;
    this.form.patchValue({conf: {latlng: `${latitude}, ${longitude}`}})
  }

  renderConfig(station: Station) {
    this.form.patchValue({conf: {...station.conf}});
    this.form.patchValue({daq: {...station.daq}});
    this.renderLatLng(station);
    this.createWatchers();
  }

  ngAfterViewInit() {
    setTimeout(() => {
      if (this.parent.item?.conf) {
        this.renderConfig(this.parent.item)
      }
    }, 0);
  }

  toggleDaqConfVisibleBasic() {
    if (this.daq_conf_visible_basic) {
      // reset everything to default
    }
    this.daq_conf_visible_basic = !this.daq_conf_visible_basic;
  }

  toggleDaqConfVisibleAdvanced() {
    if (this.daq_conf_visible_advanced) {
      // reset everything to default
    }
    this.daq_conf_visible_advanced = !this.daq_conf_visible_advanced;
  }

  save() {
    if (this.parent.item) {
      let updateDoa: any = {};
      let updateDaq: any = {};
      const update = this.form.getRawValue();
      console.log("raw update:", update);
      // junk data below
      delete update.conf["fixed_heading_check"];
      delete update.daq['daq_cfg_files'];

      // update latitude and longitude
      const [lat, lng] = update.conf['latlng'].split(',');
      update.conf['latitude'] = lat.trim();
      update.conf['longitude'] = lng.trim();
      delete update.conf['latlng'];
      // ---
      if (this.parent.item.conf?.id && this.parent.item.daq?.id) {
        const conf: any = {...this.parent.item.conf};
        const daq: any = {...this.parent.item.daq};
        for (let key of Object.keys(update.conf)) {
          if (conf[key] !== update.conf[key]) {
            updateDoa[key] = update.conf[key];
          }
        }
        for (let key of Object.keys(update.daq)) {
          if (daq[key] !== update.daq[key]) {
            updateDaq[key] = update.daq[key];
          }
        }
        this.loaderService.startLoading();
        const observables = [];
        if (Object.keys(updateDoa).length > 0) {
          observables.push(this.apiService.patchStationConfig(this.parent.item.conf.id, updateDoa));
        }
        if (Object.keys(updateDaq).length > 0) {
          observables.push(this.apiService.patchStationDaq(this.parent.item.daq.id, updateDaq))
        }
        forkJoin(observables).pipe(finalize(
          () => this.loaderService.stopLoading()
        )).subscribe({
          next: _ => {
            this.snackBar.open('Збережено!', 'Ок', {duration: 7000});
            this.parent.loadItem();
            this.form.markAsPristine();
          },
          error: _ => this.snackBar.open('Щось пішло не так!', 'Ок', {duration: 7000})
        });
      }
    }
  }

  setAntArrangement() {
    const proModeEnabled = this.form.controls['proModeEnabled']?.value;
    const isCustomAnt = this.form.controls['ant_arrangement']?.value === "Custom";
    if (isCustomAnt) {
      if (!proModeEnabled) {
        this.form.controls['ant_arrangement']?.disable();
        this.form.controls['custom_array_x_meters']?.disable();
        this.form.controls['custom_array_y_meters']?.disable();
      } else {
        this.form.controls['ant_arrangement']?.enable();
        this.form.controls['custom_array_x_meters']?.enable();
        this.form.controls['custom_array_y_meters']?.enable();
      }
    }
  }

  createWatchers() {
    this.setAntArrangement();
    this.watcher?.unsubscribe();
    this.watcherFrequency?.unsubscribe();

    this.watcher = this.form.controls['proModeEnabled']?.valueChanges.pipe(debounceTime(100)).subscribe(proMode => {
      this.setAntArrangement();
    });
    this.watcherFrequency = this.form.controls['conf'].get("center_freq")?.valueChanges.pipe(debounceTime(100)).subscribe(data => {
      this.form.controls['conf'].get("vfo_freq_0")?.patchValue(data, { emitEvent: false});
    });
    this.watcherVfoFrequency = this.form.controls['conf'].get('vfo_freq_0')?.valueChanges.pipe(debounceTime(100)).subscribe(data => {
      this.form.controls['conf'].get("center_freq")?.patchValue(data, { emitEvent: false});
    });
  }

  destroyWatchers() {
    this.watcher?.unsubscribe();
    this.watcherFrequency?.unsubscribe();
    this.watcherVfoFrequency?.unsubscribe();
  }

  protected readonly JSON = JSON;
}
