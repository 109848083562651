<div class="app-content">
  <app-navbar>
    <div class="app-content-breadcrumbs">
      <app-breadcrumbs>
        <ul>
          <li><a mat-button [routerLink]="LINK_MAIN">Головна</a></li>
          <li><a mat-button disabled>Комплекси</a></li>
        </ul>
        <a (click)="createNew()" extra mat-mini-fab color="primary"
           aria-label="Add" class="d-sm-flex d-md-none">
          <mat-icon>add</mat-icon>
        </a>

        <a (click)="createNew()" extended extra mat-fab color="primary"
           aria-label="Add" class="d-none d-md-flex">
          <mat-icon>add</mat-icon>
          Додати
        </a>
      </app-breadcrumbs>
    </div>
    <div class="app-content-container">
      <div class="page">
        <mat-paginator *ngIf="paginator && paginator.totalObjects > paginator.pageSize"
                       [length]="paginator.totalObjects"
                       [pageIndex]="paginator.currPage"
                       [pageSize]="paginator.pageSize"
                       (page)="handlePageEvent($event)"
                       [showFirstLastButtons]="true"
                       aria-label="Оберіть сторінку"/>
        <app-group-preview matRipple *ngFor="let item of items"
                           [routerLink]="LINK_GROUPS + '/' + item.id"
                           [id]="item.id"
                           [name]="item.name"
                           [frequency]="item.conf.center_freq"
                           [stations]="item.stations"/>
        <mat-paginator *ngIf="paginator && paginator.totalObjects > paginator.pageSize"
                       [length]="paginator.totalObjects"
                       [pageIndex]="paginator.currPage"
                       [pageSize]="paginator.pageSize"
                       (page)="handlePageEvent($event)"
                       [showFirstLastButtons]="true"
                       aria-label="Оберіть сторінку"/>
        <div *ngIf="!done" class="no-items-memo">
          <div class="p-05em">
            <span>Завантажуємо, зачекайте...</span>
          </div>
        </div>
        <div *ngIf="items.length < 1 && done" class="no-items-memo">
          <div class="p-1em text-center">
            <span>У вас ще немає жодного комплексу. Створити зараз?</span>
          </div>
          <div>
            <a (click)="createNew()" extended extra mat-fab color="primary"
               aria-label="Add">
              Створити
            </a>
          </div>
        </div>
      </div>
      <!--      <div class="page no-items">-->
      <!--        <div>-->
      <!--          You dont have groups yet!-->
      <!--        </div>-->
      <!--      </div>-->
    </div>
    <!--<div class="app-content-footer">hello! im footer</div>-->
  </app-navbar>
</div>
