import {AfterViewInit, Component, OnDestroy, OnInit, ViewChild, ViewEncapsulation} from '@angular/core';
import {AuthService} from '../auth.service';
import {
  AbstractControl,
  FormControl,
  FormGroup,
  FormGroupDirective,
  ValidationErrors,
  Validators
} from "@angular/forms";
import {ActivatedRoute, Params, Router} from "@angular/router";
import {MatSnackBar} from "@angular/material/snack-bar";
import {LoaderService} from "../../loader.service";
import {debounceTime, finalize, map, of, Subscription, switchMap} from "rxjs";


export enum Step {
  CREDS,
  OTP,
  NONE = 2
}


@Component({
  selector: 'app-register-comp',
  templateUrl: 'register.component.html',
  styleUrls: ['register.component.scss'],
})
export class RegisterComponent implements AfterViewInit, OnDestroy {
  protected readonly step = Step;
  protected passwordVisible: boolean = false;
  protected passwordVisibleAgain: boolean = false;
  protected loading: boolean = false;
  protected currentStep: Step = Step.NONE;
  protected token: string|undefined = undefined;

  private passwordSubscription: Subscription;
  private passwordAgainSubscription: Subscription;
  protected qr = {
    value: ''
  };

  protected loginForm = new FormGroup({
    login: new FormControl('', [Validators.required, Validators.email]),
    password: new FormControl('', [Validators.required]),
    passwordAgain: new FormControl('', [Validators.required]),
  });

  protected otpForm = new FormGroup({
    otp: new FormControl('',
      [Validators.required, Validators.pattern('\\d{6,6}')])
  });

  constructor(private route: ActivatedRoute,
              private router: Router,
              private authService: AuthService,
              private snackBar: MatSnackBar,
              private loadService: LoaderService) {
    this.passwordSubscription = this.loginForm.controls.password.valueChanges.pipe(debounceTime(100)).subscribe(
      _ => this.checkPasswords()
    );
    this.passwordAgainSubscription = this.loginForm.controls.passwordAgain.valueChanges.pipe(debounceTime(100)).subscribe(
      _ => this.checkPasswords()
    );
  }

  ngOnDestroy() {
    this.passwordSubscription.unsubscribe();
    this.passwordAgainSubscription.unsubscribe();
  }

  ngAfterViewInit(){
    this.checkTokenExists();
  }

  checkTokenExists() {
    this.token = this.route.snapshot.params['token'];
    if (this.token) {
      this.authService.checkTokenExists(this.token).subscribe({
        next: (result: boolean) => {
          if (result) {
            return setTimeout(() => {
              this.currentStep = Step.CREDS;
            });
          }
          return this.router.navigateByUrl('/').then();
        },
        error: _ => this.router.navigateByUrl('/').then()
      });
      return;
    }
    this.router.navigateByUrl('/').then();
  }

  checkPasswords() {
    if (this.loginForm.controls.password.value !== this.loginForm.controls.passwordAgain.value) {
      this.loginForm.controls.passwordAgain.setErrors({"differ": "Passwords mismatch"});
    } else {
      this.loginForm.controls.passwordAgain.updateValueAndValidity();
    }
  }

  startLoading() {
    this.loading = true;
    this.loginForm.controls.login.disable();
    this.loginForm.controls.password.disable();
    this.loginForm.controls.passwordAgain.disable();
  }

  stopLoading() {
    this.loading = false;
    this.loginForm.controls.login.enable();
    this.loginForm.controls.password.enable();
    this.loginForm.controls.passwordAgain.enable();
  }

  submitLoginPassword() {
    const login = this.loginForm.value.login;
    const password = this.loginForm.value.password;

    this.startLoading();
    if (login && password && this.token) {
      this.authService.signUn(this.token, login.toLowerCase(), password).pipe(finalize(
        () => this.stopLoading()
      )).subscribe({
        next: (result: string|undefined) => {
          if (result) {
            this.qr.value = result;
            this.currentStep = Step.OTP;
            return;
          }
          this.loginForm.reset();
          this.currentStep = Step.CREDS;
        },
        error: (error: string|undefined) => {
          error = error || 'Щось пішло не так';
          this.snackBar.open(error, 'Ок', {duration: 5000});
        }
      });
    }
  }

  submitOTP() {
    this.startLoading();
    const otp = this.otpForm.controls.otp.value || "";
    if (this.token && otp) {
      this.authService.finishSignUn(this.token, otp).pipe(finalize(
        () => {
          this.stopLoading();

        }
      )).subscribe({
        next: (next: boolean) => {
          if (next) {
            this.snackBar.open('Реєстрація завершена', 'Ок', {duration: 5000});
            this.router.navigateByUrl('/').then();
            return;
          }
          this.snackBar.open('Не вдалось зберегти токен', 'Ок', {duration: 5000});
        },
        error: (error: string|undefined) => {
          error = error || 'Щось пішло не так';
          this.snackBar.open(error, 'Ок', {duration: 5000});
        }
      });
    } else {
      console.log("How that could be?");
    }
  }

  onSubmit(): void {
    switch (this.currentStep) {
      case Step.CREDS:
        return this.submitLoginPassword();
      case Step.OTP:
        return this.submitOTP();
    }
  }

  getErrorMessage(field: FormControl): string {
    let error = "Невідома помилка";
    if (field.hasError('')) {
      error = "Мають бути 6 цифр";
    }
    if (field.hasError('differ')) {
      error = "Паролі не співпадають";
    }
    if (field.hasError('required')) {
      error = "Обов'язкове поле";
    }
    if (field.hasError('email')) {
      error = "Не правильна адреса";
    }
    return error;
  }
}
