import {AfterViewInit, Component, Input, OnInit, ViewChild} from '@angular/core';
import {LoaderService} from "../loader.service";
import {Router} from "@angular/router";
import {LINK_GROUP_NEW, LINK_MAIN, LINK_STATIONS} from "../app.urls";
import {ApiResponse, ApiService} from "../api.service";
import {finalize} from "rxjs";
import {HttpResponse} from "@angular/common/http";
import {NotificationService} from "../notification/notification.service";
import {
  ReceiverSettingsArrayType,
  ReceiverSettingsDecorelation,
  ReceiverSettingsDoaDataFormat,
  ReceiverSettingsDoaFigType,
  ReceiverSettingsDoaMethod,
  ReceiverSettingsInterface, ReceiverSettingsLocationSource, ReceiverSettingsSpectrumCalc,
  ReceiverSettingsUlaDirection, ReceiverSettingsVfoMode
} from "./station/station-settings/station-settings.component";
import {PageEvent} from "@angular/material/paginator";
import {Paginator} from "../common/app.paginator";
import {MatSnackBar} from "@angular/material/snack-bar";


export enum StationStatus {
  UNKNOWN = 0,
  OKAY = 1,
  WARNING = 2,
  FAIL = 3,
  NEW = 5,
}

export interface DaqConfig {
  cfg_data_block_len: number;
  cfg_recal_interval: number;
  cfg_rx_channels: number;
  cfg_en_bias_tee: number;
  cfg_daq_buffer_size: number;
  cfg_sample_rate: number;
  en_noise_source_ctr: number;
  cfg_cpi_size: number;
  cfg_decimation_ratio: number;
  cfg_fir_bw: number;
  cfg_fir_tap_size: number;
  cfg_fir_window: number;
  en_filter_reset: boolean;
  cfg_corr_size: number;
  cfg_std_ch_ind: number;
  en_iq_cal: boolean;
  cfg_gain_lock: number;
  en_req_track_lock_intervention: boolean;
  cfg_cal_track_mode: number;
  cfg_amplitude_cal_mode: string;
  cfg_cal_frame_interval: number;
  cfg_cal_frame_burst_size: number;
  cfg_amplitude_tolerance: number;
  cfg_phase_tolerance: number;
  cfg_max_sync_fails: number;
  cfg_iq_adjust_source: string;
  cfg_iq_adjust_amplitude: string;
  cfg_iq_adjust_time_delay_ns: string;
  id: number | undefined;
}

export interface StationConfig {
  center_freq: number;
  uniform_gain: number;
  data_interface: ReceiverSettingsInterface;
  default_ip: string;
  en_doa: boolean;
  ant_arrangement: ReceiverSettingsArrayType;
  ula_direction: ReceiverSettingsUlaDirection;
  ant_spacing_meters: number;
  custom_array_x_meters: string;
  custom_array_y_meters: string;
  array_offset: number;
  doa_method: ReceiverSettingsDoaMethod;
  doa_decorrelation_method: ReceiverSettingsDecorelation;
  compass_offset: number;
  doa_fig_type: ReceiverSettingsDoaFigType;
  en_peak_hold: boolean;
  expected_num_of_sources: string;
  en_hw_check: number;
  logging_level: number;
  disable_tooltips: number;
  doa_data_format: ReceiverSettingsDoaDataFormat;
  station_id: string;
  location_source: ReceiverSettingsLocationSource;
  latitude: number;
  longitude: number;
  heading: number;
  krakenpro_key: string;
  rdf_mapper_server: string;
  gps_min_speed: number;
  gps_min_speed_duration: number;
  spectrum_calculation: ReceiverSettingsSpectrumCalc;
  vfo_mode: ReceiverSettingsVfoMode;
  dsp_decimation: number;
  active_vfos: string;  // number irl
  output_vfo: string;  // number irl
  en_optimize_short_bursts: boolean;
  vfo_bw_0: number;
  vfo_freq_0: number;
  vfo_squelch_0: number;
  // vfo_bw_1: number;
  // vfo_freq_1: number;
  // vfo_squelch_1: number;
  // vfo_bw_2: number;
  // vfo_freq_2: number;
  // vfo_squelch_2: number;
  // vfo_bw_3: number;
  // vfo_freq_3: number;
  // vfo_squelch_3: number;
  // vfo_bw_4: number;
  // vfo_freq_4: number;
  // vfo_squelch_4: number;
  // vfo_bw_5: number;
  // vfo_freq_5: number;
  // vfo_squelch_5: number;
  // vfo_bw_6: number;
  // vfo_freq_6: number;
  // vfo_squelch_6: number;
  // vfo_bw_7: number;
  // vfo_freq_7: number;
  // vfo_squelch_7: number;
  // vfo_bw_8: number;
  // vfo_freq_8: number;
  // vfo_squelch_8: number;
  // vfo_bw_9: number;
  // vfo_freq_9: number;
  // vfo_squelch_9: number;
  // vfo_bw_10: number;
  // vfo_freq_10: number;
  // vfo_squelch_10: number;
  // vfo_bw_11: number;
  // vfo_freq_11: number;
  // vfo_squelch_11: number;
  // vfo_bw_12: number;
  // vfo_freq_12: number;
  // vfo_squelch_12: number;
  // vfo_bw_13: number;
  // vfo_freq_13: number;
  // vfo_squelch_13: number;
  // vfo_bw_14: number;
  // vfo_freq_14: number;
  // vfo_squelch_14: number;
  // vfo_bw_15: number;
  // vfo_freq_15: number;
  // vfo_squelch_15: number;
  id: number | undefined;
}


export interface Station {
  name: string;
  status: StationStatus;
  groupId: string;
  groupName: string;
  uptime: number;
  serial: string;
  location: string;
  connected: boolean;
  power: boolean | null | undefined;
  battery: number | null | undefined;
  uplink_a: boolean | null | undefined;
  uplink_b: boolean | null | undefined;
  temperature_run: number | null | undefined;
  temperature_gs: number | null | undefined;
  humidity_gs: number | null | undefined;
  humidity_run: number | null | undefined;
  uptime_run: number | null | undefined;
  uptime_gs: number | null | undefined;
  conf?: StationConfig;
  daq?: DaqConfig;
  id: number | undefined;
}


@Component({
  selector: 'app-stations',
  templateUrl: './stations.component.html',
  styleUrls: ['./stations.component.scss']
})
export class StationsComponent implements AfterViewInit {
  opened: boolean = true;
  items: Station[] = [];
  done = false;
  paginator: Paginator|undefined;

  constructor(protected router: Router, private snackBar: MatSnackBar,
              private loadService: LoaderService, private apiService: ApiService) {
  }

  ngAfterViewInit() {
    this.getStations();
  }

  handlePageEvent(event: PageEvent) {
    this.getStations(event.pageIndex);
  }

  getStations(pageIndex: number|undefined = undefined) {
    this.loadService.startLoading();
    this.apiService.getStations(pageIndex).pipe(finalize(() => {
      this.loadService.stopLoading();
    })).subscribe({
      next: (response: HttpResponse<ApiResponse<Station[]>>) => {
        if (response?.body?.data?.objects) {
          // console.log("data:", response.body.data);
          this.items = response.body.data.objects;
        }
        if (response?.body?.data?.paginator) {
          this.paginator = response?.body.data.paginator;
        }
        this.done = true;
      },
      error: (error: any) => {
        console.error('Error getting stations:', error);
        this.snackBar.open('Щось пішло не так!', 'Ок', {duration: 7000});
      }
    });
  }

  protected readonly LINK_MAIN = LINK_MAIN;
  protected readonly LINK_STATIONS = LINK_STATIONS;
  protected readonly LINK_GROUP_NEW = LINK_GROUP_NEW;
}
