import { Component } from '@angular/core';
import {ApiService} from "../api.service";
import {AsyncProgressBarService} from "./async-progress-bar.service";

@Component({
  selector: 'app-async-progress-bar',
  templateUrl: './async-progress-bar.component.html',
  styleUrls: ['./async-progress-bar.component.scss']
})
export class AsyncProgressBarComponent {
  constructor(protected progressBarService: AsyncProgressBarService) {

  }
}
