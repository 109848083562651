import {HttpClient, HttpHeaders} from '@angular/common/http';
import {Observable} from "rxjs";


export abstract class HttpService {

  protected http: HttpClient;

  protected constructor(http: HttpClient) {
    this.http = http;
  }

  protected patch(url: string, body: any, headers?: HttpHeaders, observe?: any): any {
   return this.http.patch(url, body, { headers: this.headers(headers), observe: this.observe(observe) });
  }

  protected get(url: string, headers?: HttpHeaders, observe?: any, responseType?: any): Observable<any> {
    return this.http.get(url, { headers: this.headers(headers),
                                observe: this.observe(observe),
                                responseType: this.responseType(responseType) });
  }

  protected post(url: string, body: any, headers?: HttpHeaders, observe?: any): any {
    return this.http.post(url, body, { headers: this.headers(headers), observe: this.observe(observe) });
  }

  protected put(url: string, body: any, headers?: HttpHeaders, observe?: any): any {
    return this.http.put(url, body, { headers: this.headers(headers), observe: this.observe(observe) });
  }

  protected del(url: string, headers?: HttpHeaders, observe?: any): any {
    return this.http.delete(url, { headers: this.headers(headers), observe: this.observe(observe) });
  }

  private headers(headers?: HttpHeaders): HttpHeaders {
    if (headers) {
      return headers;
    }
    return new HttpHeaders({'Content-Type': 'application/json'});
  }

  private observe(observe?: any) {
    return HttpService.isEmpty(observe) ? 'response' : observe;
  }

  private responseType(responseType?: any) {
    return HttpService.isEmpty(responseType) ? 'json' : responseType;
  }

  public static isEmpty(value?: any): boolean {
    return value === undefined || value === null || value.length === 0;
  }
}
