<h2 mat-dialog-title>Новий</h2>
<mat-dialog-content class="mat-typography">
  <p>Створити комплекс</p>
  <form #form="ngForm" [formGroup]="createForm">
    <mat-form-field class="w-100" appearance="outline" floatLabel="always">
      <mat-label>Ім'я</mat-label>
      <input matInput formControlName="name"
             class="text-right" placeholder="Перемога-001">
      <mat-error *ngIf="createForm.getError('required', ['name'])">
        Обов'язкове поле
      </mat-error>
    </mat-form-field>
  </form>
</mat-dialog-content>
<mat-dialog-actions align="end">
  <button mat-button color="warn" [disabled]="!createForm.valid" (click)="submit()">Створити</button>
  <button mat-button mat-dialog-close>Скасувати</button>
</mat-dialog-actions>
