import { Pipe, PipeTransform } from '@angular/core';
import {StationStatus} from "../stations/stations.component";

@Pipe({name: 'uptimeToString'})
export class AppStationUptimePipe implements PipeTransform {

  transform(value: number|null|undefined): string {
    if (value === null || value === undefined) {
      return '--';
    }
    const days = Math.floor(value / 86400); // (60 * 60 * 24);
    if (days >= 1) {
      return days + 'д';
    }
    const hours = Math.floor(value / 3600); // (60 * 60)
    if (hours >= 1) {
      return hours + 'г';
    }
    const minutes = Math.floor(value / 60);
    if (minutes >= 1) {
      return minutes + 'х';
    }
    return `${Math.floor(value)}с`;
  }
}
