import {inject, Injectable} from "@angular/core";
import {ActivatedRouteSnapshot, Router, RouterStateSnapshot, UrlTree} from "@angular/router";
import {AuthService} from "./auth/auth.service";
import {Observable, Subscriber} from "rxjs";
import {ROUTER_SIGN_IN} from "./auth/auth.urls";


export const authGuard = (route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean|UrlTree> => {
  const authService = inject(AuthService);
  const router: Router = inject(Router);
  return new Observable((subscriber: Subscriber<boolean|UrlTree>): void => {
    // console.log("next: ", encodeURI(state.url));
    const next: string = decodeURIComponent(state.url);
    if (authService.isAuth()) {
      return subscriber.next(true);
    }
    subscriber.next(router.parseUrl(ROUTER_SIGN_IN + `?next=${next}`));
  });
};
