import {debounceTime, Observable, Subscriber, Subscription} from "rxjs";
import * as GeographicLib from 'geographiclib-geodesic'

const geod = GeographicLib.Geodesic.WGS84;

export enum PositionType {
  OFF = 0,
  ACTIVE = 1,
  PASSIVE = 2
}

export class PositionPin {

  L: any;
  map: any;
  marker: any;
  divIcon: any;
  accuracy: any;
  heading: any;
  lastCoords: GeolocationCoordinates|undefined;

  isOn: boolean = false;
  isFocusOn: boolean = false;
  isDisplayed: boolean = false;
  isMoving: boolean = false;
  public status: PositionType;
  isInit: boolean = false;

  positionObservable: Observable<any>;
  positionSubscriber: Subscriber<any>|undefined;
  positionSubscription: Subscription|undefined;
  positionFocusInterval: any;

  constructor(L: any, map: any) {
    this.L = L;
    this.map = map;
    this.accuracy = this.L.circle([0, 0], {color: "#337ab7", fillColor: "#337ab7", radius: 1, weight: 1, opacity: 0.5, fillOpacity: 0.1});
    this.divIcon = new this.L.divIcon({
      className: 'my-location',
      html: `<div class="outer-circle"><div class="inner-circle"></div></div>`
    });
    this.marker = this.L.marker([0, 0], {icon: this.divIcon});
    this.status = PositionType.OFF;

    this.positionObservable = new Observable(observer => {
      this.positionSubscriber = observer;
    }).pipe(debounceTime(5000));
  }

  refreshLocation(flyTo: boolean = false) {
    if (this.lastCoords === undefined) {
      return;
    }
    this.accuracy.setLatLng([this.lastCoords.latitude, this.lastCoords.longitude]);
    this.accuracy.setRadius(this.lastCoords.accuracy);
    this.marker.setLatLng([this.lastCoords.latitude, this.lastCoords.longitude]);

    if (this.isOn && !this.isDisplayed) {
      this.accuracy.addTo(this.map);
      this.marker.addTo(this.map);
      this.isDisplayed = true;
    }
    if (this.isFocusOn && !this.isMoving && this.isDisplayed) {
      if (!this.isInit) {
        this.map.fitBounds(this.accuracy.getBounds(), {padding: [50, 50], animate: true,
                                                       duration: 1, noMoveStart: true});
        this.isInit = true;
      } else if (flyTo){
        this.map.flyTo(this.marker.getLatLng(), this.map.getZoom(), {
          animate: true, duration: 1,
          noMoveStart: true
        });
      }
    }
    this.positionSubscriber?.next(this.lastCoords);
  }

  checkIfWeNeedToFly(center: any, newPoint: GeolocationCoordinates, meters: number) {
    const result = geod.Inverse(center.lat, center.lng, newPoint.latitude, newPoint.longitude);
    if (result.s12 !== undefined) {
      return result.s12 > meters;
    }
    return false;
  }

  onDeviceOrientation(event: DeviceOrientationEvent) {
    this.heading = event.alpha;
    if (this.heading) {
      const rotate = (360 - this.heading + 225) % 360;
      this.divIcon.options.html = `<div class="outer-circle"><div class="inner-circle searching"></div></div>`
                                + `<div class="heading" style="transform: rotate(${rotate}deg)"><div></div></div>`;
      if (!this.isMoving && this.isOn) {
        this.marker.remove();
        this.marker.addTo(this.map);
      }
    }
  }

  onLocationFound(position: GeolocationPosition) {
    this.lastCoords = position.coords;
    if (this.heading) {
      const rotate = (360 - this.heading + 225) % 360;
      this.divIcon.options.html = `<div class="outer-circle"><div class="inner-circle searching"></div></div>`
                                + `<div class="heading" style="transform: rotate(${rotate}deg)"><div></div></div>`;
    } else {
      this.divIcon.options.html = `<div class="outer-circle"><div class="inner-circle"></div></div>`;
    }
    if (!this.isMoving) {
      this.refreshLocation();
    }
  }

  displayLocation() {
    if (this.isOn && !this.isDisplayed) {
      this.isDisplayed = true;
      this.accuracy.addTo(this.map);
      this.marker.addTo(this.map);
    }
  }

  hideLocation() {
    if (this.isDisplayed) {
      this.isDisplayed = false;
      this.accuracy.remove();
      this.marker.remove();
    }
  }

  toggle() {
    if (this.status === PositionType.OFF || this.status === PositionType.PASSIVE) {
      this.isOn = true;
      this.isFocusOn = true;
      this.map.options.scrollWheelZoom = 'center';
      this.map.options.touchZoom = 'center';
      this.divIcon.options.html = `<div class="outer-circle">`
                                + `<div class="inner-circle searching"></div>`
                                + `</div>`;
      this.createFocusWatcher();
      if (this.status === PositionType.OFF) {
        this.isInit = false;
      }
      if (this.status === PositionType.PASSIVE) {
        this.refreshLocation(true);
      }
      this.status = PositionType.ACTIVE;
    } else if (this.status === PositionType.ACTIVE) {
      this.status = PositionType.OFF;
      this.isOn = false;
      this.hideLocation();
      this.cancelFocusWatcher();
    }
    return this.isOn;
  }

  createFocusWatcher() {
    if (this.positionFocusInterval === undefined) {
      this.positionFocusInterval = setInterval(() => {
        if (this.isFocusOn && !this.isMoving) {
          if (this.lastCoords) {
            if (this.checkIfWeNeedToFly(this.map.getCenter(), this.lastCoords, 5)) {
              this.map.flyTo([this.lastCoords.latitude, this.lastCoords.longitude], this.map.getZoom(), {
                animate: true, duration: 1,
                noMoveStart: true
              });
            }
          };
        }
      }, 5000);
    }
  }

  cancelFocusWatcher() {
    if (this.positionFocusInterval !== undefined) {
      clearInterval(this.positionFocusInterval);
      this.positionFocusInterval = undefined;
    }
  }

  onDestroy() {
    this.accuracy.remove();
    this.marker.remove();
    this.positionSubscription?.unsubscribe();
  }

  public onZoomStart() {
    this.isMoving = true;
  }

  public onZoomEnd() {
    this.isMoving = false;
    this.refreshLocation();
  }

  public onMoveStart() {
    this.isFocusOn = false;
    this.isMoving = true;
    this.cancelFocusWatcher();
    if (this.status === PositionType.ACTIVE) {
      this.status = PositionType.PASSIVE;
    }
    this.map.options.scrollWheelZoom = true;
    this.map.options.touchZoom = true;
  }

  public onMoveEnd() {
    this.isMoving = false;
    this.refreshLocation();
  }
}
