import {Component, Inject} from "@angular/core";
import {MAT_DIALOG_DATA, MatDialogModule, MatDialogRef} from "@angular/material/dialog";
import {MatButtonModule} from "@angular/material/button";
import {MatFormFieldModule} from "@angular/material/form-field";
import {MatInputModule} from "@angular/material/input";
import {NgIf} from "@angular/common";
import {FormControl, FormGroup, ReactiveFormsModule, Validators} from "@angular/forms";


interface ApproveDialogData {
  title: string;
  paragraph: string;
}


@Component({
  selector: 'app-approve-dialog',
  template: `
    <h2 mat-dialog-title>{{ title }}</h2>
    <mat-dialog-content class="mat-typography">
      <p>{{ paragraph }}</p>
    </mat-dialog-content>
    <mat-dialog-actions align="end">
      <button mat-button color="warn" [mat-dialog-close]="true">Так</button>
      <button mat-button mat-dialog-close>Ні</button>
    </mat-dialog-actions>

  `,
  standalone: true,
  imports: [MatDialogModule, MatButtonModule, MatFormFieldModule, MatInputModule, NgIf, ReactiveFormsModule],
})
export class DialogApprove {
  title: string;
  paragraph: string;
  constructor(@Inject(MAT_DIALOG_DATA) protected data: ApproveDialogData) {
    this.title = data.title;
    this.paragraph = data.paragraph;
  }
}
