<div class="app-content">
  <app-navbar>
    <div class="app-content-container">
<!--      <app-breadcrumbs>-->
<!--        <ul>-->
<!--          <li><a mat-button disabled>Головна</a></li>-->
<!--        </ul>-->
<!--        <a extra mat-flat-button color="primary"><span></span>Створити</a>-->
<!--      </app-breadcrumbs>-->
      <div class="container main-page">
        <div class="row">
          <div class="col-lg-6">
            <mat-card>
              <mat-card-header>
                <mat-card-title>Пеленгатори:</mat-card-title>
              </mat-card-header>
              <mat-card-content>
                <p>Всього: {{ generalInfo?.stations?.total || '--' }}</p>
                <mat-divider></mat-divider>
              </mat-card-content>
              <mat-card-actions align="end">
                <a [routerLink]="LINK_STATIONS" mat-button>Перейти до всіх</a>
              </mat-card-actions>
            </mat-card>
          </div>
          <div class="col-lg-6">
            <mat-card>
              <mat-card-header>
                <mat-card-title>Комплекси:</mat-card-title>
              </mat-card-header>
              <mat-card-content>
                <p>Всього: {{ generalInfo?.groups?.total || '--' }}</p>
                <mat-divider></mat-divider>
              </mat-card-content>
              <mat-card-actions align="end">
                <a [routerLink]="LINK_GROUPS" mat-button>Перейти до всіх</a>
              </mat-card-actions>
            </mat-card>
          </div>
        </div>
        <div class="row">
          <div class="col-md-12">
            <div *ngIf="init && stations.length < 1" class="hero-text">
              <mat-card>
                <mat-card-content>
                  <p class="hero-p">Для того щоб з'явилась інформація про крайні пеленгатори, відкрийте сторінку будь якого пеленгатора.</p>
                </mat-card-content>
              </mat-card>
            </div>
            <div *ngIf="stations.length > 0" class="hero-text">
              <mat-card>
                <mat-card-content>
                  <p>Крайні пеленгатори:</p>
                </mat-card-content>
              </mat-card>
            </div>
            <app-station-preview matRipple *ngFor="let item of stations"
                                 [routerLink]="LINK_STATIONS + '/' + item.id"
                                 [id]="item.id"
                                 [name]="item.name"
                                 [status]="item.status"
                                 [groupId]="item.groupId"
                                 [groupName]="item.groupName"
                                 [uptime]="item.uptime_run"
                                 [serial]="item.serial"
                                 [frequency]="item.conf?.center_freq"
                                 [location]="item.location"
                                 [connected]="item.connected"
                                 [power]="item.power"
                                 [battery]="item.battery"
                                 [temperature_run]="item.temperature_run"
                                 [temperature_gs]="item.temperature_gs"
                                 [uplink_a]="item.uplink_a"
                                 [uplink_b]="item.uplink_b"/>
          </div>
        </div>
        <div class="row">
          <div class="col-md-12">
            <div *ngIf="init && groups.length < 1" class="hero-text">
              <mat-card>
                <mat-card-content>
                  <p class="hero-p">Для того щоб з'явилась інформація про крайні комплекси, відкрийте сторінку будь якого комплексу.</p>
                </mat-card-content>
              </mat-card>
            </div>
            <div *ngIf="groups.length > 0" class="hero-text">
              <mat-card>
                <mat-card-content>
                  <p>Крайні комплекси:</p>
                </mat-card-content>
              </mat-card>
            </div>
            <app-group-preview matRipple *ngFor="let item of groups"
                               [routerLink]="LINK_GROUPS + '/' + item.id"
                               [id]="item.id"
                               [name]="item.name"
                               [frequency]="item.conf.center_freq"
                               [stations]="item.stations"/>
          </div>
        </div>
      </div>
    </div>
<!--    <div class="app-content-footer"></div>-->
  </app-navbar>
</div>
