import { Injectable } from '@angular/core';
import { Location } from '@angular/common';
import { Router } from '@angular/router';
import {HttpClient, HttpResponse} from '@angular/common/http';
import {HttpService} from "./http.service";
import {map, Observable, of, Subject, Subscriber} from "rxjs";
import {StorageService} from "./storage.service";
import {
  API_GENERAL,
  API_GET_GROUPS,
  API_GET_STATIONS, API_GET_STATIONS_FREE, API_GROUP,
  API_GROUP_CONFIG, API_GROUP_STATION, API_SERVER_TIMESTAMP,
  API_STATION,
  API_STATION_CONFIG, API_STATION_DAQ, API_STATION_RESTART, WEB_SOCKET_DOWNLOAD_HISTORY
} from "./app.urls";
import {DaqConfig, Station, StationConfig} from "./stations/stations.component";
import {Group, GroupConfig} from "./groups/groups.component";
import {Paginator} from "./common/app.paginator";
import {WebSocketService} from "./common/websocket.service";
import {AsyncProgressBarService} from "./async-progress-bar/async-progress-bar.service";


export interface ApiResponseStatus {
  code: number;
  message: string;
}

export interface ApiResponseData<T> {
  object?: T
  objects?: T,
  paginator?: Paginator
}


export interface ApiResponse<T> {
  status: ApiResponseStatus;
  data?: ApiResponseData<T>;
}


export interface ApiResponseTOTP {
  totp: string;
}



@Injectable()
export class ApiService extends HttpService {

  private storage: Storage;

  /* services */
  private location: Location;
  private router: Router;

  protected groupsPage = 0;
  protected stationsPage = 0;
  protected stationsFreePage = 0;

  constructor(httpClient: HttpClient, location: Location, router: Router,
              private storageService: StorageService,
              private progressBarService: AsyncProgressBarService) {
    super(httpClient);
    this.location     = location;
    this.router       = router;
    this.storage      = localStorage;
  }

  public patchStation(id: number, data: any): Observable<HttpResponse<ApiResponse<StationConfig>>> {
    return this.patch(API_STATION + '/' + id, data).pipe(map(
      (response: HttpResponse<ApiResponse<Station>>) => {
        const data = response.body;
        if (data?.status.code === 200) {
          return response;
        }
        throw new Error('Bad server response!');
      }
    ));
  }

  public patchGroup(id: number, data: any): Observable<HttpResponse<ApiResponse<Group>>> {
    return this.patch(API_GROUP + '/' + id, data).pipe(map(
      (response: HttpResponse<ApiResponse<Group>>) => {
        const data = response.body;
        if (data?.status.code === 200) {
          return response;
        }
        throw new Error('Bad server response!');
      }
    ));
  }

  public patchGroupConfig(id: number, data: any): Observable<HttpResponse<ApiResponse<GroupConfig>>> {
    return this.patch(API_GROUP_CONFIG + '/' + id, data).pipe(map(
      (response: HttpResponse<ApiResponse<GroupConfig>>) => {
        const data = response.body;
        if (data?.status.code === 200) {
          return response;
        }
        throw new Error('Bad server response!');
      }
    ));
  }

  public patchStationDaq(id: number, data: any): Observable<HttpResponse<ApiResponse<DaqConfig>>> {
    return this.patch(API_STATION_DAQ + '/' + id, data).pipe(map(
      (response: HttpResponse<ApiResponse<DaqConfig>>) => {
        const data = response.body;
        if (data?.status.code === 200) {
          return response;
        }
        throw new Error('Bad server response!');
      }
    ));
  }

  public patchStationConfig(id: number, data: any): Observable<HttpResponse<ApiResponse<StationConfig>>> {
    return this.patch(API_STATION_CONFIG + '/' + id, data).pipe(map(
      (response: HttpResponse<ApiResponse<StationConfig>>) => {
        const data = response.body;
        if (data?.status.code === 200) {
          return response;
        }
        throw new Error('Bad server response!');
      }
    ));
  }

  public restartStation(id: number): Observable<HttpResponse<ApiResponse<Station>> | undefined> {
    return this.get(API_STATION_RESTART + '/' + id).pipe(map(
      (response: HttpResponse<ApiResponse<any>>) => {
        const data = response.body;
        if (data?.status.code === 200) {
          return response;
        }
        throw new Error('Bad server response!');
      }
    ));
  }

  public getGroupIds(ids: number[]): Observable<HttpResponse<ApiResponse<Group[]>>> {
    return this.get(`${API_GET_GROUPS}?ids=${ids}`).pipe(map(
      (response: HttpResponse<ApiResponse<Group[]>>) => {
        const data = response.body;
        if (data?.status.code === 200) {
          return response;
        }
        throw new Error('Bad server response!');
      }
    ));
  }

  public getGeneralInfo(): Observable<HttpResponse<ApiResponse<any>>> {
    return this.get(API_GENERAL).pipe(map(
      (response: HttpResponse<ApiResponse<any>>) => {
        const data = response.body;
        if (data?.status.code === 200) {
          return response;
        }
        throw new Error('Bad server response!');
      }
    ));
  }

  public getStationIds(ids: number[]): Observable<HttpResponse<ApiResponse<Station[]>>> {
    return this.get(`${API_GET_STATIONS}?ids=${ids}`).pipe(map(
      (response: HttpResponse<ApiResponse<Station[]>>) => {
        const data = response.body;
        if (data?.status.code === 200) {
          return response;
        }
        throw new Error('Bad server response!');
      }
    ));
  }

  public getStation(id: number | undefined): Observable<HttpResponse<ApiResponse<Station>> | undefined> {
    if (id !== undefined) {
      return this.get(API_STATION + '/' + id).pipe(map(
        (response: HttpResponse<ApiResponse<Station>>) => {
          const data = response.body;
          if (data?.status.code === 200) {
            return response;
          }
          throw new Error('Bad server response!');
        }
      ));
    }
    return of(undefined);
  }

  public deleteGroup(id: number | undefined): Observable<HttpResponse<ApiResponse<Group>> | undefined> {
    if (id !== undefined) {
      return this.del(API_GROUP + '/' + id).pipe(map(
        (response: HttpResponse<ApiResponse<any>>) => {
          const data = response.body;
          if (data?.status.code === 200) {
            return response;
          }
          throw new Error('Bad server response!');
        }
      ));
    }
    return of(undefined);
  }

  public getGroup(id: number | undefined): Observable<HttpResponse<ApiResponse<Group>> | undefined> {
    if (id !== undefined) {
      return this.get(API_GROUP + '/' + id).pipe(map(
        (response: HttpResponse<ApiResponse<Group>>) => {
          const data = response.body;
          if (data?.status.code === 200) {
            return response;
          }
          throw new Error('Bad server response!');
        }
      ));
    }
    return of(undefined);
  }

  public groupDelStation(groupId: number, stationId: number): Observable<HttpResponse<ApiResponse<any>>> {
    return this.del(`${API_GROUP_STATION}/${groupId}/${stationId}`).pipe(map(
      (response: HttpResponse<ApiResponse<any>>) => {
        const data = response.body;
        if (data?.status.code === 200) {
          return response;
        }
        throw new Error('Bad server response!');
      }
    ));
  }

  public groupAddStation(groupId: number, stationId: number): Observable<HttpResponse<ApiResponse<any>>> {
    return this.post(`${API_GROUP_STATION}/${groupId}/${stationId}`, null).pipe(map(
      (response: HttpResponse<ApiResponse<any>>) => {
        const data = response.body;
        if (data?.status.code === 200) {
          return response;
        }
        throw new Error('Bad server response!');
      }
    ));
  }

  public getFreeStations(pageIndex: number|undefined = undefined): Observable<HttpResponse<ApiResponse<Station[]>>> {
    this.stationsFreePage = pageIndex = pageIndex !== undefined? pageIndex : this.stationsFreePage;
    return this.get(`${API_GET_STATIONS_FREE}?page=${pageIndex}`).pipe(map(
      (response: HttpResponse<ApiResponse<Station[]>>) => {
        const data = response.body;
        if (data?.status.code === 200) {
          return response;
        }
        throw new Error('Bad server response!');
      }
    ));
  }

  public getStations(pageIndex: number|undefined = undefined): Observable<HttpResponse<ApiResponse<Station[]>>> {
    this.stationsPage = pageIndex = pageIndex !== undefined? pageIndex : this.stationsPage;
    return this.get(`${API_GET_STATIONS}?page=${pageIndex}`).pipe(map(
      (response: HttpResponse<ApiResponse<Station[]>>) => {
        const data = response.body;
        if (data?.status.code === 200) {
          return response;
        }
        throw new Error('Bad server response!');
      }
    ));
  }

  public createGroup(name: string): Observable<HttpResponse<ApiResponse<Group>>> {
    return this.post(API_GET_GROUPS, {name: name}).pipe(map(
      (response: HttpResponse<ApiResponse<Group>>) => {
        const data = response.body;
        if (data?.status.code === 200) {
          return response;
        }
        throw new Error('Bad server response!');
      }
    ));
  }

  public getGroups(pageIndex: number|undefined = undefined): Observable<HttpResponse<ApiResponse<Group[]>>> {
    this.groupsPage = pageIndex = pageIndex !== undefined? pageIndex : this.groupsPage;
    return this.get(`${API_GET_GROUPS}?page=${pageIndex}`).pipe(map(
      (response: HttpResponse<ApiResponse<Group[]>>) => {
        const data = response.body;
        if (data?.status.code === 200) {
          return response;
        }
        throw new Error('Bad server response!');
      }
    ));
  }

  public getUTCTimestamp() {
    return new Date().getTime();
  }

  public getTimeOffset() {
    const start = new Date().getTime();
    return new Observable<number>(subscriber => {
      this.getServerTimestamp().subscribe(
        (next:HttpResponse<ApiResponse<number>>) => {
          const offset = new Date().getTime() - start;
          if (next.body?.data?.object) {
            const timestamp = this.getUTCTimestamp() - offset;
            subscriber.next(next.body?.data?.object - timestamp);
          } else {
            subscriber.next(0);
          }
          subscriber.complete();
        }
      );
    });
  }

  public getServerTimestamp() {
    return this.get(`${API_SERVER_TIMESTAMP}`).pipe(map(
      (response: HttpResponse<ApiResponse<number>>) => {
        const data = response.body;
        if (data?.status.code === 200) {
          return response;
        }
        throw new Error('Bad server response!');
      }
    ));
  }

  public downloadHistory(serials: string[], writable: any) {
    setTimeout(async () => {
      const subject = new Subject<any>();
      this.progressBarService.addAsyncJob(subject);
      const session = this.storageService.getSession();
      const socket = new WebSocket(WEB_SOCKET_DOWNLOAD_HISTORY + '?session=' + session);
      socket.onopen = () => {
        socket.send(JSON.stringify({serials}));
      };
      socket.onclose = async () => {
        await writable.close();
        subject.complete();
      };
      socket.onmessage = async (event: MessageEvent<any>) => {
        await writable.ready;
        await writable.write(event.data);
      };
    }, 0);
  }
  public downloadHistoryBlob(serials: string[], filename: string|undefined = undefined) {
    setTimeout(async () => {
      const blobs: Blob[] = [];
      const subject = new Subject<any>();
      this.progressBarService.addAsyncJob(subject);
      const session = this.storageService.getSession();
      const socket = new WebSocket(WEB_SOCKET_DOWNLOAD_HISTORY + '?session=' + session);
      socket.onopen = () => {
        socket.send(JSON.stringify({serials}));
      };
      socket.onclose = async () => {
        subject.next(new Blob(blobs, {type: 'plain/text'}));
        subject.complete();
      };
      socket.onmessage = async (event: MessageEvent<string>) => {
        blobs.push(new Blob([event.data]));
      };
      subject.subscribe({next: (blob: Blob) => {
          const blobUrl = window.URL.createObjectURL(blob);
          const link = document.createElement('a');
          link.href = blobUrl;
          link.setAttribute('download', filename || 'doa-log.csv');
          document.body.appendChild(link);
          link.click();
          document.body.removeChild(link);
          // clean up Url
          window.URL.revokeObjectURL(blobUrl);
      }})
    }, 0);
  }

}
