import {Component, Inject, Input, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {PlotlyComponent} from "angular-plotly.js/lib/plotly.component";
import {KSPParsed, StationComponent} from "../station.component";
import {Observable, Subscription} from "rxjs";
import {PlotlyService} from "angular-plotly.js";
import {theta} from "../../../common/theta";
import {ParentInterface} from "./parent-interface";
import {HttpResponse} from "@angular/common/http";
import {ApiResponse} from "../../../api.service";
import {StationConfig} from "../../stations.component";
import {GroupConfig} from "../../../groups/groups.component";


interface Cache {
  x: number[];
  y: number[];
  z: number[][];
  minAmplitude: number;
  maxAmplitude: number;
  plotsInit: boolean;
  frequency: number;
  vfoFrequency: number;
  vfoBandwidth: number;
}

@Component({
  selector: 'app-station-spectrum',
  templateUrl: './station-spectrum.component.html',
  styleUrls: ['./station-spectrum.component.scss']
})
export class StationSpectrumComponent implements ParentInterface {

  constructor(@Inject(StationComponent) protected parent: StationComponent) {
  }

  public setNewVfoFrequency(vfoFrequency: number): Observable<HttpResponse<ApiResponse<StationConfig|GroupConfig>>> {
    return this.parent.setNewVfoFrequency(vfoFrequency);
  }

  public getConfig(): StationConfig|undefined {
    return this.parent.getConfig();
  }

}
