<!--<div class="mat-elevation-z8" style="height: 100%">-->
<mat-card class="login-hero" *ngIf="currentStep == step.CREDS">
    <mat-card-header>
        <mat-card-title>Реєстрація</mat-card-title>
    </mat-card-header>
    <div class="placeholder-1"></div>
    <mat-card-content>
        <form [formGroup]="loginForm" (ngSubmit)="onSubmit()">
            <p>
                <mat-form-field appearance="outline" class="width-full">
                    <mat-label>Електронна пошта</mat-label>
                    <input matInput placeholder="me@example.com" formControlName="login">
                    <mat-icon matSuffix>email</mat-icon>
                    <mat-error *ngIf="loginForm.invalid && loginForm.controls.login.invalid">
                      {{getErrorMessage(loginForm.controls.login)}}
                    </mat-error>
                </mat-form-field>
            </p>
            <p>
                <mat-form-field appearance="outline" class="width-full">
                    <mat-label>Пароль</mat-label>
                    <input matInput [type]="passwordVisible ? 'text': 'password'" formControlName="password">
                    <button type="button" mat-icon-button matSuffix (click)="passwordVisible = !passwordVisible"
                            [attr.aria-label]="'Сховати пароль'" [attr.aria-pressed]="!passwordVisible">
                        <mat-icon>{{passwordVisible ? 'visibility': 'visibility_off'}}</mat-icon>
                    </button>
                    <mat-error *ngIf="loginForm.invalid && loginForm.controls.password.invalid">
                      {{getErrorMessage(loginForm.controls.password)}}
                    </mat-error>
                </mat-form-field>
            </p>
            <p>
              <mat-form-field appearance="outline" class="width-full">
                <mat-label>Пароль знову</mat-label>
                <input matInput [type]="passwordVisibleAgain ? 'text': 'password'" formControlName="passwordAgain">
                <button type="button" mat-icon-button matSuffix (click)="passwordVisibleAgain = !passwordVisibleAgain"
                        [attr.aria-label]="'Сховати пароль'" [attr.aria-pressed]="!passwordVisibleAgain">
                  <mat-icon>{{passwordVisibleAgain ? 'visibility': 'visibility_off'}}</mat-icon>
                </button>
                <mat-error *ngIf="loginForm.invalid && loginForm.controls.passwordAgain.invalid">
                  {{getErrorMessage(loginForm.controls.passwordAgain)}}
                </mat-error>
              </mat-form-field>
            </p>
            <p>
                <button mat-flat-button color="primary" class="width-full hero-submit"
                        [disabled]="!loginForm.valid || loading">Далі</button>
            </p>
            <div class="placeholder-1"></div>
        </form>
    </mat-card-content>
    <mat-card-footer class="overflow-hidden">
      <mat-progress-bar *ngIf="loading" mode="indeterminate" class="progress-bar"></mat-progress-bar>
    </mat-card-footer>
</mat-card>
<mat-card class="login-hero" *ngIf="currentStep == step.OTP && qr.value">
    <mat-card-header>
      <mat-card-title>OTP реєстрація</mat-card-title>
      <mat-card-subtitle class="text-center">Google Authenticator</mat-card-subtitle>
    </mat-card-header>
    <div class="placeholder-1"></div>
    <mat-card-content>
      <form [formGroup]="otpForm" (ngSubmit)="onSubmit()">
        <div class="qr-holder">
          <qrcode [value]="qr.value"/>
        </div>
        <div class="placeholder-1"></div>
        <mat-form-field appearance="outline" class="width-full">
          <mat-label>Одноразовий код</mat-label>
          <input matInput [type]="'text'" formControlName="otp">
          <mat-error *ngIf="otpForm.invalid && otpForm.controls.otp.invalid">
            {{getErrorMessage(otpForm.controls.otp)}}
          </mat-error>
        </mat-form-field>
        <p>
            <button mat-flat-button color="primary" class="width-full hero-submit" type="submit"
                    [disabled]="!otpForm.valid || loading">Завершити реєстрацію</button>
        </p>
        <div class="placeholder-1"></div>
      </form>
    </mat-card-content>
    <mat-card-footer class="overflow-hidden">
        <mat-progress-bar *ngIf="loading" mode="indeterminate" class="progress-bar"></mat-progress-bar>
    </mat-card-footer>
</mat-card>
<!--</div>-->
