import { Component } from '@angular/core';
import {LINK_MAIN} from "../app.urls";

@Component({
  selector: 'app-settings',
  templateUrl: './settings.component.html',
  styleUrls: ['./settings.component.scss']
})
export class SettingsComponent {

  protected readonly LINK_MAIN = LINK_MAIN;
}
