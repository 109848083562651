import {Station} from "../stations/stations.component";
import {WebSocketService} from "../common/websocket.service";
import {StorageService} from "../storage.service";
import {Subscription} from "rxjs";
import * as GeographicLib from 'geographiclib-geodesic'
import {IntersectService} from "./intersect.service";
import {StationsDoAService} from "../stations-doa.service";
import {Inject} from "@angular/core";
import {ApiService} from "../api.service";


const geod = GeographicLib.Geodesic.WGS84;

export interface DoAPacket {
    adc_overdrive: number;
    antType: string;
    compassOffset: number;
    conf: number;  // float
    doaArray: string;
    freq: number;
    gpsBearing: number;
    gps_timestamp: number;
    latency: number;
    latitude: number;
    longitude: number;
    num_corr_sources: number;
    power: number;
    processing_time: number;
    radioBearing: number;
    snr_db: number;
    station_id: string;
    tStamp: number;
}

export interface DoAWSData {
  doa: DoAPacket;
}


interface Cache {
  heading: {
    visible: boolean;
    value: number|undefined;
    lastLatLngs: any;
  };
  bearing: {
    visible: boolean;
    value: number|undefined;
    lastLatLngs: any;
    interval: any;
  };
}


export class StationPin {

  L: any;
  map: any;
  station: Station|undefined;
  divIcon: any;
  marker: any;
  subscription: Subscription|undefined = undefined;
  heading: any;
  bearing: any;
  inverse: any;
  cache: Cache = {
    heading: {
      visible: false,
      value: undefined,
      lastLatLngs: undefined,
    },
    bearing: {
      interval: undefined,
      visible: false,
      value: undefined,
      lastLatLngs: undefined,
    }
  }

  constructor(L: any, map: any, private storageService: StorageService,
              private intersectService: IntersectService|undefined,
              private doaService: StationsDoAService,
              private apiService: ApiService) {
    this.L = L;
    this.map = map;
    this.divIcon = new this.L.divIcon({
      className: 'eter-pin',
      // html: `<div class="name">${station.name}</div><div class="pin"></div>`
    });
    this.marker = this.L.marker([0, 0], {icon: this.divIcon});
    this.heading = new this.L.polyline([], {
      color: 'silver', weight: 3, opacity: 0.5, smoothFactor: 1
    });
    this.bearing = new this.L.polyline([], {
      color: 'red', weight: 3, opacity: 0.75, smoothFactor: 1
    });
    this.inverse = new this.L.polyline([], {
      color: '#55aaff', weight: 3, opacity: 0.5, smoothFactor: 1
    });
    this.heading.addTo(this.map);
    this.bearing.addTo(this.map);
    this.inverse.addTo(this.map);
  }

  getPoints(startLat: number, startLon: number, azimuth: number, distance: number = 5, resolution: number = 5) {
    const latLongs: any = [[startLat, startLon]];
    for (let i = resolution; i <= distance; i += resolution) {
      let r = geod.Direct(startLat, startLon, azimuth, i * 1000);
      latLongs.push([r.lat2, r.lon2]);
    }
    return latLongs;
  }

  setBearing(bearing:number) {
    clearInterval(this.cache.bearing.interval);
    this.bearing.options.opacity = 0.75;
    this.bearing.remove();
    this.bearing.addTo(this.map);
    this.cache.bearing.visible = true;
    this.cache.bearing.value = bearing;
    let latLng = this.marker.getLatLng();
    const lineLatLngs = this.getPoints(latLng.lat, latLng.lng, bearing, 120, 5)
    this.cache.bearing.lastLatLngs = lineLatLngs;
    this.bearing.setLatLngs(lineLatLngs);
    // TODO(s1z): This is obsolete, remove this and make a web-worker
    //            to handle server calculated zone of interests!
    // if (this.station?.id !== undefined) {
    //   this.intersectService?.addEvent({
    //     timestamp: new Date().getTime(),
    //     stationId: this.station?.id,
    //     coords: this.cache.bearing.lastLatLngs
    //   });
    // }
  }

  setHeading(heading: number) {
    if (this.cache.heading.value !== heading) {
      this.cache.heading.value = heading;
      let latLng = this.marker.getLatLng();
      this.heading.setLatLngs(this.getPoints(latLng.lat, latLng.lng, heading, 120, 5));
      if (!this.cache.heading.visible) {
        this.heading.addTo(this.map);
        this.cache.heading.visible = true;
      }
    }
  }

  parseKSP(ksp: any) {
    for (let packet of ksp) {
      let [key, value] = packet;
      switch (key) {
        case "DoA Max":
          this.setBearing(value);
          break;
        case "location":
          this.setHeading(value.heading);
      }
    }
    // console.log("ksp:", ksp);
  }

  parseDoA(doa: DoAPacket) {
    // update position ???
    // this.marker.setLatLng([doa.latitude, doa.longitude]);
    this.setBearing(doa.radioBearing);
  }

  setStation(station: Station) {
    this.station = station;
    if (this.station.id) {
      this.intersectService?.addStation(this.station.id);
    }
    this.divIcon.options.html = `<div class="name"><div>${station.name}</div></div><div class="pin"></div>`;
    this.marker.setLatLng([station.conf?.latitude, station.conf?.longitude]);
    this.marker.remove();
    this.marker.addTo(this.map);

    this.clearBearing();
    this.clearHeading();

    if (this.station?.conf) {
      this.setHeading(this.station.conf.heading);
    }

    this.subscription?.unsubscribe();
    this.subscription = this.doaService.subscribeStation(this.station.serial)?.subscribe(
        (message: string) => {
          const data: any = JSON.parse(message);
          const wsData: DoAWSData | null = data[station.serial];
          if (wsData !== null) {
            this.parseDoA(wsData.doa);
          } else {
            this.clearBearing();
          }
        }
    );
  }

  clearHeading() {
    this.heading.remove();
    this.cache.heading.visible = false;
    this.cache.heading.value = undefined;
  }

  clearBearing() {
    if (this.cache.bearing.visible) {
      this.cache.bearing.interval = setInterval(() => {
        this.bearing.options.opacity -= 0.015;
        this.bearing.remove();
        if (this.bearing.options.opacity <= 0) {
          this.bearing.options.opacity = 0;

          clearInterval(this.cache.bearing.interval);
        }
        this.bearing.addTo(this.map);
      }, 25);
      this.cache.bearing.visible = false;
      this.cache.bearing.value = undefined;
    }
  }

  clear() {
    // this.websocket?.stop();
    this.subscription?.unsubscribe();
    if (this.station) {
      this.doaService.unsubscribeStation(this.station.serial);
    }
    this.marker.remove();
    this.clearHeading();
    this.clearBearing();
    if (this.station?.id) {
      this.intersectService?.removeStation(this.station.id);
    }
  }

}
