<!-- SETTINGS -->
<div *ngIf="groupConfig">
    <div class="container-fluid mw-2k">
      <div class="row align-items-center">
        <div class="col-6 col-sm-6 col-md-6 col-lg-6 col-xl-6 col-xxl-6">
          <div class="p-15-05em" [formGroup]="form">
            <mat-slide-toggle formControlName="overwriteSettings"
                              aria-label="Переписати налаштування"
                              color="primary">Змінити всі</mat-slide-toggle>
          </div>
        </div>
        <div class="col-6 col-sm-6 col-md-6 col-lg-6 col-xl-6 col-xxl-6 text-right">
          <div class="p-15-05em" *ngIf="parent.item?.id">
            <button mat-button aria-label="More actions"
                    *ngIf="parent.item?.id" [matMenuTriggerFor]="more">Більше дій</button>
            <mat-menu #more="matMenu">
              <button mat-menu-item (click)="downloadHistory()">
                <mat-icon>cloud_download</mat-icon>
                <span>Завантажити пеленги</span>
              </button>
              <button mat-menu-item (click)="deleteGroup()">
                <mat-icon>delete_forever</mat-icon>
                <span>Видалити</span>
              </button>
            </mat-menu>
          </div>
        </div>
      </div>
      <mat-divider></mat-divider>

      <form #formSimple="ngForm" [formGroup]="form" (ngSubmit)="save()">
        <div class="row">
          <div class="d-none d-xxl-flex col-xxl-2"></div>
          <!-- General -->
          <div class="col-sm-12 col-md-6 col-lg-4 col-xl-3 col-xxl-2 p-1em">
            <div>
              <h3>Загальні:</h3>
            </div>
            <div class="pb-1em">
              <mat-form-field class="w-100" appearance="outline" floatLabel="always">
                <mat-label>Назна комплексу</mat-label>
                <input matInput formControlName="name"
                       placeholder="Пеленгатор-1">
                <mat-error *ngIf="form.getError('required', ['name'])">Введіть правильне значення</mat-error>
                <!--<mat-hint>Ім'я</mat-hint>-->
              </mat-form-field>
            </div>
          </div>

          <!-- Receiver -->
          <div *ngIf="group && group.stations.length"
               class="col-sm-12 col-md-6 col-lg-4 col-xl-3 col-xxl-2 p-1em">
            <div>
              <h3>Параметри прийому:</h3>
            </div>
            <div class="pb-1em">
              <mat-form-field floatLabel="always" class="w-100" appearance="outline">
                <mat-label>Центральна Частота</mat-label>
                <input matInput formControlName="center_freq"
                       placeholder="412.123" value="" required>
                <span matTextSuffix>МГц</span>
                <mat-error *ngIf="form.getError('required', ['center_freq'])">Обов'язкове поле</mat-error>
              </mat-form-field>
            </div>
            <div class="pb-1em">
              <mat-form-field class="w-100" floatLabel="always" appearance="outline">
                <mat-label>Підсилення</mat-label>
                <mat-select required formControlName="uniform_gain">
                  <mat-option *ngFor="let item of gainParams" [value]="item.value">{{ item.label }}</mat-option>
                </mat-select>
                <mat-error *ngIf="form.getError('required', ['uniform_gain'])">Обов'язкове поле</mat-error>
              </mat-form-field>
            </div>
            <div class="pb-1em">
              <mat-form-field floatLabel="always" class="w-100" appearance="outline">
                <mat-label>Робоча Частота (VFO)</mat-label>
                <input matInput required formControlName="vfo_freq_0"
                       placeholder="412.123" required>
                <span matTextSuffix>МГц</span>
                <mat-error *ngIf="form.getError('required', ['vfo_freq_0'])">Обов'язкове поле</mat-error>
              </mat-form-field>
            </div>
            <div class="pb-1em">
              <mat-form-field floatLabel="always" class="w-100" appearance="outline">
                <mat-label>Чутливість РЧ (VFO Squelch)</mat-label>
                <input matInput formControlName="vfo_squelch_0"
                       placeholder="-50" required>
                <span matTextSuffix>Дб</span>
                <mat-error *ngIf="form.getError('required', ['vfo_squelch_0'])">Обов'язкове поле</mat-error>
              </mat-form-field>
            </div>
            <div class="pb-1em">
              <mat-form-field class="w-100" floatLabel="always" appearance="outline">
                <mat-label>Тип роботи РЧ</mat-label>
                <mat-select formControlName="vfo_mode" required>
                  <mat-option *ngFor="let item of vfoParams" [value]="item.value">{{ item.label }}</mat-option>
                </mat-select>
                <mat-error *ngIf="form.getError('required', ['vfo_mode'])">Обов'язкове поле</mat-error>
              </mat-form-field>
            </div>
          </div>

          <!-- DOA -->
          <div *ngIf="group && group.stations.length"
               class="col-sm-12 col-md-6 col-lg-4 col-xl-3 col-xxl-2 p-1em">
            <div>
              <h3>Параметри пеленгації:</h3>
            </div>
            <div class="pb-1em">
              <mat-form-field class="w-100" appearance="outline">
                <mat-label>Антенний масив</mat-label>
                <mat-select formControlName="ant_arrangement" required>
                  <!-- TODO:(s1z): Remove hardcode -->
                  <mat-option value="ULA">Лінійний (ULA)</mat-option>
                  <mat-option value="UCA">Круговий (UCA)</mat-option>
                  <mat-option value="Custom" disabled>Інший (Custom)</mat-option>
                </mat-select>
                <mat-error *ngIf="form.getError('required', ['ant_arrangement'])">Обов'язкове поле</mat-error>
              </mat-form-field>
            </div>

            <div class="pb-1em" *ngIf="form.controls['ant_arrangement']?.value !== 'Custom'">
              <mat-form-field class="w-100" appearance="outline" floatLabel="always">
                <mat-label *ngIf="form.controls['ant_arrangement']?.value  === 'ULA'">Відстань між антенами</mat-label>
                <mat-label *ngIf="form.controls['ant_arrangement']?.value  === 'UCA'">Радіус масиву</mat-label>
                <input formControlName="ant_spacing_meters" matInput
                       placeholder="0.314">
                <span matTextSuffix>м</span>
                <mat-error *ngIf="form.getError('required', ['ant_spacing_meters'])">Обов'язкове поле</mat-error>
              </mat-form-field>
            </div>
            <div *ngIf="form.controls['ant_arrangement']?.value === 'ULA'">
              <div class="pb-1em">
                <mat-form-field class="w-100" appearance="outline">
                  <mat-label>Напрямок</mat-label>
                  <mat-select formControlName="ula_direction" required>
                    <mat-option value="Both">Всі</mat-option>
                    <mat-option value="Forward">Фронт</mat-option>
                    <mat-option value="Backward">Тил</mat-option>
                  </mat-select>
                  <mat-error *ngIf="form.getError('required', ['ula_direction'])">Обов'язкове поле</mat-error>
                </mat-form-field>
              </div>
            </div>
            <div>
              <mat-divider></mat-divider>
              <div class="p-15-1em mw-100">
                <mat-slide-toggle formControlName="en_doa"
                                  class="mw-100" color="primary">DoA (Визначення Напрямку)</mat-slide-toggle>
              </div>
              <mat-divider></mat-divider>
            </div>

          </div>

          <!-- Visualization -->
          <!-- TODO(s1z): Fix style here -->
          <div *ngIf="group && group.stations.length"
               class="col-sm-12 col-md-6 col-lg-4 col-xl-3 col-xxl-2 p-1em overflow-hidden">
            <div>
              <h3>Відображення:</h3>
            </div>
            <div class="pb-1em">
              <mat-form-field class="w-100" appearance="outline" floatLabel="always">
                <mat-label>Граф тип</mat-label>
                <mat-select formControlName="doa_fig_type" required>
                  <mat-option value="Compass">Компас</mat-option>
                  <mat-option value="Polar">Полар</mat-option>
                  <mat-option value="Linear">Лінійний</mat-option>
                </mat-select>
                <mat-error *ngIf="form.getError('required', ['doa_fig_type'])">Обов'язкове поле</mat-error>
              </mat-form-field>
            </div>
            <div class="pb-1em">
              <mat-form-field class="w-100" appearance="outline" floatLabel="always">
                <mat-label>Полоса пропускання (VFO BW)</mat-label>
                <span matTextSuffix>Гц</span>
                <input matInput formControlName="vfo_bw_0" class="" placeholder="12000">
                <mat-error *ngIf="form.getError('required', ['vfo_bw_0'])">Обов'язкове поле</mat-error>
              </mat-form-field>
            </div>
          </div>

        </div>
      </form>

    </div>
</div>

