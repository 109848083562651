import {
  AfterViewInit,
  ChangeDetectorRef,
  Component,
  EventEmitter, Inject,
  Input,
  OnDestroy,
  OnInit,
  ViewChild
} from '@angular/core';
import {LINK_MAIN, LINK_STATIONS, ROUTE_STATION, ROUTE_STATIONS} from "../../app.urls";
import {Station, StationConfig, StationStatus} from "../stations.component";
import {ActivatedRoute, Router, RouterOutlet} from "@angular/router";
import {LoaderService} from "../../loader.service";
import {MAT_DIALOG_DATA, MatDialog, MatDialogModule} from "@angular/material/dialog";
import {MatButtonModule} from "@angular/material/button";
import {AbstractControl, Form, FormControl, FormGroup, ValidationErrors, ValidatorFn, Validators} from "@angular/forms";
import {StationSettingsComponent} from "./station-settings/station-settings.component";
import {StationSpectrumComponent} from "./station-spectrum/station-spectrum.component";
import {ApiResponse, ApiService} from "../../api.service";
import {HttpResponse} from "@angular/common/http";
import {finalize, observable, Observable, Observer, Subject, Subscription} from "rxjs";
import {WebSocketService} from "../../common/websocket.service";
import {StorageService} from "../../storage.service";
import {ParentInterface} from "./station-spectrum/parent-interface";
import {CommonModule} from "@angular/common";


interface MetricsWSMessage {
  run: {t: number|null,
        h: number|null,
        u: number|null},
  gs: {t: number|null,
       h: number|null,
       p: boolean|null,
       b: number|null,
       u: number|null}
}


interface KSPParsedMetrics {
  run: {
    temperature: number|null;
    humidity: number|null;
    uptime: number|null;
  };
  gs: {
    power: boolean|null;
    battery: number|null;
    temperature: number|null;
    humidity: number|null;
    uptime: number|null;
  }
}

export interface KSPLocation {
  heading: number|undefined;
  latitude: number|undefined;
  longitude: number|undefined;
}

export interface KSPParsed {
  realLatency: number|undefined;
  timestampService: number|undefined;
  centerFrequency: number|undefined;
  vfoFrequency: number|undefined;
  maxAmplitude: number|undefined;
  vfoBandwidth: number|undefined;
  doaMax: number|undefined;
  doaConfidence: number|undefined;
  updateRate: number|undefined;
  latency: number|undefined;
  spectrum: any;
  doaResult: number[]|undefined;
  metrics: KSPParsedMetrics;
  location: KSPLocation|undefined;
}


@Component({
  selector: 'app-station-info',
  templateUrl: './station-info.component.html',
  standalone: true,
  imports: [MatDialogModule, MatButtonModule, CommonModule],
})
export class DialogStationInfo {
  protected subscription: Subscription|undefined;
  constructor(@Inject(MAT_DIALOG_DATA) protected kspParsed: KSPParsed) {
  }
}

@Component({
  selector: 'app-station',
  templateUrl: './station.component.html',
  styleUrls: ['./station.component.scss']
})
export class StationComponent implements OnInit, OnDestroy, AfterViewInit {

  protected readonly LINK_STATIONS = LINK_STATIONS;
  protected readonly LINK_MAIN = LINK_MAIN;
  protected itemId: number | undefined;
  public item: Station | undefined;
  protected activeTab: number| null = null;
  protected settingsComponent: StationSettingsComponent | undefined;

  protected form: FormGroup | undefined;
  protected webSocket: WebSocketService;
  public webSocketMsgObservable: Observable<any>|undefined;
  public kspSpectrumObservable: Observable<any>|undefined;
  protected kspSpectrumObserver: Observer<any>|undefined;
  protected webSocketMsgSubscription: Subscription|undefined;
  protected kspParsed: KSPParsed = {
    timestampService: undefined,
    realLatency: undefined,
    vfoBandwidth: undefined,
    centerFrequency: undefined,
    vfoFrequency: undefined,
    maxAmplitude: undefined,
    doaMax: undefined,
    doaConfidence: undefined,
    updateRate: undefined,
    latency: undefined,
    spectrum: undefined,
    doaResult: undefined,
    location: {
      heading: undefined,
      longitude: undefined,
      latitude: undefined,
    },
    metrics: {
      run: {
        temperature: null,
        humidity: null,
        uptime: null
      },
      gs: {
        power: null,
        battery: null,
        temperature: null,
        humidity: null,
        uptime: null
      }
    }
  };
  private timestampOffset: number = 0;

  constructor(private route: ActivatedRoute, private router: Router,
              private cdr: ChangeDetectorRef, private apiService: ApiService,
              public dialog: MatDialog, private loaderService: LoaderService,
              private storageService: StorageService) {
    this.webSocket = new WebSocketService(storageService);
    this.getTimeOffset();
  }

  getTimeOffset() {
    const start = new Date().getTime();
    this.apiService.getServerTimestamp().subscribe(
      next => {
        const offset = new Date().getTime() - start;
        if (next.body?.data?.object) {
          const timestamp = this.getUTCTimestamp() - offset;
          this.timestampOffset = next.body?.data?.object - timestamp;
        }
      }
    );
  }

  ngOnInit() {
    this.itemId = this.route.snapshot.params['itemId'];
    if (this.itemId) {
      this.storageService.addLastStation(this.itemId);
    }
    this.kspSpectrumObservable = new Observable((observer: Observer<any>) => {
      this.kspSpectrumObserver = observer;
    });
  }

  ngOnDestroy() {
    this.webSocketMsgSubscription?.unsubscribe();
    this.webSocket.stop();
  }

  ngAfterViewInit(){
    setTimeout(() => {
      this.loadItem();
      // window.onfocus = () => {
      //   this.webSocket?.restart();
      // };
      // window.onblur = () => {
      //   this.webSocket.stop();
      // };
    });
  }

  showInfo() {
    const dialogRef = this.dialog.open(DialogStationInfo, {data: this.kspParsed});
    // dialogRef.afterClosed().subscribe(result => {
    //   console.log(`Dialog result: ${result}`);
    // });
  }

  parseMetrics(message: MetricsWSMessage) {
    this.kspParsed.metrics.run.temperature = message.run.t;
    this.kspParsed.metrics.run.humidity = message.run.h;
    this.kspParsed.metrics.gs.temperature = message.gs.t;
    this.kspParsed.metrics.gs.humidity = message.gs.h;
    this.kspParsed.metrics.gs.power = message.gs.p;
    this.kspParsed.metrics.gs.battery = message.gs.b;
  }

  getUTCTimestamp() {
    return new Date().getTime();
  }

  parseKSP(ksp: any[]) {
    const kspParsed: KSPParsed = {
      timestampService: undefined,
      realLatency: undefined,
      vfoBandwidth: undefined,
      centerFrequency: undefined,
      vfoFrequency: undefined,
      maxAmplitude: undefined,
      doaMax: undefined,
      doaConfidence: undefined,
      updateRate: undefined,
      latency: undefined,
      spectrum: undefined,
      doaResult: undefined,
      location: {
        heading: undefined,
        longitude: undefined,
        latitude: undefined,
      },
      metrics: {
        run: {
          temperature: null,
          humidity: null,
          uptime: null
        },
        gs: {
          power: null,
          battery: null,
          temperature: null,
          humidity: null,
          uptime: null
        }
      }
    };
    for (let data of ksp) {
      const [name, value] = data;
      // console.log(`name:${name}, value: ${value}`);
      switch (name) {
        case "ts":
          const localTs = this.getUTCTimestamp() + this.timestampOffset;
          this.kspParsed.realLatency = localTs - value;
          kspParsed.timestampService = value;
          break;
        case "max_amplitude":
          this.kspParsed.maxAmplitude = value;
          kspParsed.maxAmplitude = value;
          break;
        case "DoA Max":
          this.kspParsed.doaMax = value;
          kspParsed.doaMax = value;
          break;
        case "update_rate":
          this.kspParsed.updateRate = value * 1000;
          kspParsed.updateRate = value * 1000;  // convert to ms
          break;
        case "latency":
          this.kspParsed.latency = value;
          kspParsed.latency = value;
          break;
        case "DoA Confidence":
          this.kspParsed.doaConfidence = value;
          kspParsed.doaConfidence = value;
          break;
        case "center_frequency":
          this.kspParsed.centerFrequency = value;
          kspParsed.centerFrequency = value;
          break;
        case "vfo_freq":
          this.kspParsed.vfoFrequency = value[0];
          kspParsed.vfoFrequency = value[0];
          break;
        case "vfo_bw":
          this.kspParsed.vfoBandwidth = value[0];
          kspParsed.vfoBandwidth = value[0];
          break;
        case "spectrum":
          this.kspParsed.spectrum = value;
          kspParsed.spectrum = value;
          break;
        case "DoA Result":
          this.kspParsed.doaResult = value;
          kspParsed.doaResult = value;
          break;
        case "location":
          this.kspParsed.location ? this.kspParsed.location.latitude = value.latitude : null;
          this.kspParsed.location ? this.kspParsed.location.longitude = value.longitude : null;
          this.kspParsed.location ? this.kspParsed.location.heading = value.heading : null;
          kspParsed.location = value;
          break;
      }
    }
    this.kspSpectrumObserver?.next(kspParsed);
  }

  loadItem() {
    this.loaderService.startLoading();
    if (this.itemId !== undefined) {
      this.loaderService.startLoading();
      this.apiService.getStation(this.itemId).pipe(finalize(() => {
        this.loaderService.stopLoading()
      })).subscribe({
        next: (response: HttpResponse<ApiResponse<Station>>|undefined) => {
          const item = response?.body?.data?.object;
          if (item) {
            this.item = item;
            this.renderItem(item);
            this.webSocketMsgObservable = this.webSocket.subscribeStation(item.serial);
            this.webSocketMsgSubscription = this.webSocketMsgObservable.subscribe(
              (message: any) => {
                if (message.ksp) {
                  this.parseKSP(message.ksp);
                } else if (message.run) {
                  this.parseMetrics(message);
                }
              }
            )
          }
        },
        error: (response: any) => {}
      });
    }
    this.loaderService.stopLoading();
  }

  renderItem(item: Station) {
    this.item = item;
    this.item.status = this.item.connected ? StationStatus.OKAY : StationStatus.FAIL;
    this.cdr.detectChanges();
  }

  save() {
    this.settingsComponent?.save();
  }

  onOutletLoaded(component: StationSettingsComponent | StationSpectrumComponent) {
    if (component instanceof StationSettingsComponent) {
      this.settingsComponent = component;
      console.log("parent receiverForm:", component.form);
      this.activeTab = 0;
      this.form = component.form;
    } else {
      this.activeTab = 1;
      this.form = undefined;
    }
  }

  public setNewVfoFrequency(vfoFrequency: number): Observable<HttpResponse<ApiResponse<StationConfig>>> {
    return new Observable<HttpResponse<ApiResponse<StationConfig>>>(observable => {
      if (this.itemId) {
        this.loaderService.startLoading();
        this.apiService.patchStationConfig(this.itemId, {vfo_freq_0: vfoFrequency}).pipe(
          finalize(() => this.loaderService.stopLoading())
        ).subscribe({
          next: (response: HttpResponse<ApiResponse<StationConfig>>) => {
            if (this.item?.conf && response.body?.data?.object) {
              this.item.conf = response.body.data.object;
            }
            observable.next(response);
          },
          complete: () => {
            observable.complete();
          }
        })
      } else {
        observable.error('itemId is null!');
      }
    });

  }

  onNavTabChange($event: any) {
    this.loaderService.startLoading();
    this.activeTab = $event.index;
    if (this.itemId) {
      switch ($event.index) {
        case 1:
          this.router.navigate([ROUTE_STATIONS, this.itemId, 'spectrum']);
          break;
        case 0:
        default:
          this.router.navigate([ROUTE_STATIONS, this.itemId, 'settings']);
          break;
      }
    }
    setTimeout(() => {
      this.loaderService.stopLoading();
    }, 500);
  }
  getConfig() {
    return this.item?.conf;
  }
}
