<div *ngFor="let notification of notifications"
     class="notification mat-elevation-z8"
     [ngSwitch]="notification.type"
     (click)="remove(notification)">

  <div *ngSwitchCase="type.PRIMARY" class="primary">
    {{ notification.subject }}<br>{{ notification.message }}
  </div>

  <div *ngSwitchCase="type.SUCCESS" class="success">
    {{ notification.subject }}<br>{{ notification.message }}
  </div>

  <div *ngSwitchCase="type.WARNING" class="warning">
    {{ notification.subject }}<br>{{ notification.message }}
  </div>

  <div *ngSwitchCase="type.INFO" class="info">
    {{ notification.subject }}<br>{{ notification.message }}
  </div>

  <div *ngSwitchCase="type.ERROR" class="danger">
    {{ notification.subject }}<br>{{ notification.message }}
  </div>

</div>
