import {Component, Input} from '@angular/core';
import {Station} from "../../stations/stations.component";
import {LINK_GROUPS, LINK_STATIONS} from "../../app.urls";

@Component({
  selector: 'app-group-preview',
  templateUrl: './group-preview.component.html',
  styleUrls: ['./group-preview.component.scss']
})
export class GroupPreviewComponent {
  @Input() id: number|undefined = undefined;
  @Input() name: string|undefined = undefined;
  @Input() frequency: number|undefined = undefined;
  @Input() stations: Station[] = [];
  protected readonly LINK_GROUPS = LINK_GROUPS;
}
