import {NgModule} from '@angular/core';
import {BrowserModule} from '@angular/platform-browser';
import {HttpClientModule} from '@angular/common/http';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {RouterModule} from '@angular/router';

import {AuthService} from './auth.service';

import {SignInComponent} from './sign-in/sign-in.component';

/* Material */
import {MatButtonModule} from '@angular/material/button';
import {MatInputModule} from '@angular/material/input';
import {MatProgressSpinnerModule} from '@angular/material/progress-spinner';
import {authRoutes} from "./auth.routes";
import {MatCardModule} from "@angular/material/card";
import {MatProgressBarModule} from "@angular/material/progress-bar";
import {MatDividerModule} from "@angular/material/divider";
import {MatIconModule} from "@angular/material/icon";
import {StorageService} from "../storage.service";
import {RegisterComponent} from "./register/register.component";
import {MatSnackBarModule} from "@angular/material/snack-bar";
import {QrcodeModule} from "qrcode-angular";


@NgModule({
  declarations: [
    SignInComponent,
    RegisterComponent
  ],
  imports: [
    FormsModule,
    ReactiveFormsModule,
    BrowserModule,
    HttpClientModule,  // The Newer One. Should go after BrowserModule!
    RouterModule.forRoot(authRoutes),
    MatCardModule,
    MatProgressBarModule,
    MatDividerModule,
    MatButtonModule,
    MatInputModule,
    MatIconModule,
    MatProgressSpinnerModule,
    MatSnackBarModule,
    QrcodeModule
  ],
  providers: [AuthService, StorageService],
  exports: [RouterModule]
})
export class AuthModule {}
