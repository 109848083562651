import {Component, OnInit} from '@angular/core';
import {LoaderService} from "../loader.service";
import {Router} from "@angular/router";
import {Station} from "../stations/stations.component";
import {Group} from "../groups/groups.component";
import {StorageService} from "../storage.service";
import {ApiService} from "../api.service";
import {finalize, forkJoin} from "rxjs";
import {LINK_GROUPS, LINK_STATIONS} from "../app.urls";

interface GeneralInfo {
  stations: {
    total: number;
    // online: number;
    // offline: number
  },
  groups: {
    total: number;
  }
}


@Component({
  selector: 'app-main',
  templateUrl: './main.component.html',
  styleUrls: ['./main.component.scss']
})
export class MainComponent implements OnInit {

  init = false;
  stations: Station[] = [];
  groups: Group[] = [];
  generalInfo: GeneralInfo|undefined = undefined;

  constructor(private loadService: LoaderService,
              protected router: Router,
              protected apiService: ApiService,
              protected storageService: StorageService) {
  }

  ngOnInit() {
    this.loadLastItems();
  }

  loadLastItems() {
    const data = {
      general: this.loadGeneralInfo(),
      stations: this.loadLastStations(),
      groups: this.loadLastGroups()
    };
    this.loadService.startLoading();
    forkJoin(data).pipe(finalize(
      () => this.loadService.stopLoading())
    ).subscribe({
      next: result => {
        if (result.general.body?.data?.object) {
          this.generalInfo = result.general.body.data.object;
        }
        if (result.groups.body?.data?.objects) {
          this.groups = result.groups.body.data.objects;
        }
        if (result.stations.body?.data?.objects) {
          this.stations = result.stations.body.data.objects;
        }
        this.init = true;
      },
      error: error => {
        console.log("error:", error);
      }
    })
  }

  loadGeneralInfo() {
    return this.apiService.getGeneralInfo();
  }

  loadLastStations() {
    const stationIds = this.storageService.getLastStations();
    return this.apiService.getStationIds(stationIds)
  }

  loadLastGroups() {
    const stationIds = this.storageService.getLastGroups();
    return this.apiService.getGroupIds(stationIds)
  }

  protected readonly LINK_STATIONS = LINK_STATIONS;
  protected readonly LINK_GROUPS = LINK_GROUPS;
  protected readonly undefined = undefined;
}
