import {
  AfterContentChecked,
  AfterViewChecked,
  AfterViewInit,
  Component,
  ElementRef,
  ViewChild,
  ViewEncapsulation
} from '@angular/core';
import {MatButtonModule} from "@angular/material/button";
import {MatToolbarModule} from "@angular/material/toolbar";
import {ObserversModule} from "@angular/cdk/observers";

@Component({
  encapsulation: ViewEncapsulation.None,
  selector: 'app-breadcrumbs',
  templateUrl: './breadcrumbs.component.html',
  styleUrls: ['./breadcrumbs.component.scss'],
  imports: [
    MatButtonModule,
    MatToolbarModule
  ],
  standalone: true
})
export class BreadcrumbsComponent implements AfterViewInit {
  @ViewChild('breadcrumbsNavigation') breadcrumbsNavigation: ElementRef | undefined;

  ngAfterViewInit() {
    this.scrollRight();
  }

  scrollRight() {
    if (this.breadcrumbsNavigation !== undefined) {
      this.breadcrumbsNavigation.nativeElement.scrollTo(
        this.breadcrumbsNavigation.nativeElement.scrollWidth, 0
      );
    }
  }
}
