import {Component, Input, OnDestroy, OnInit} from '@angular/core';
import {Observable, Subscription} from "rxjs";
import {theta} from "../theta";
import {KSPParsed} from "../../stations/station/station.component";
import {ParentInterface} from "../../stations/station/station-spectrum/parent-interface";
import {PlotlyComponent} from "angular-plotly.js/lib/plotly.component";
import {Station} from "../../stations/stations.component";
import {ApiResponse, ApiService} from "../../api.service";
import {HttpResponse} from "@angular/common/http";


interface Cache {
  x: number[];
  y: number[];
  z: number[][];
  minAmplitude: number;
  maxAmplitude: number;
  plotsInit: boolean;
  frequency: number;
  vfoFrequency: number;
  vfoBandwidth: number;
}

@Component({
  selector: 'app-spectrum',
  templateUrl: './spectrum.component.html',
  styleUrls: ['./spectrum.component.scss'],
})
export class SpectrumComponent implements OnInit, OnDestroy {
  @Input() parent: ParentInterface|undefined = undefined;
  @Input() dataObservable: Observable<KSPParsed>|undefined = undefined;
  @Input() station: Station|undefined = undefined;

  protected latency: number = 0;
  private timestampOffset = 0;
  private isRunning = true;
  private packet: KSPParsed|undefined = undefined;
  private throttle: number = 100;

  protected cache: Cache = {
    x: [],
    y: [],
    z: [[]],
    minAmplitude: -80,
    maxAmplitude: -30,
    plotsInit: false,
    frequency: 0,
    vfoFrequency: 0,
    vfoBandwidth: 0
  }

  protected config = {
    scrollZoom: false,
    displaylogo: false,
    scrollmode: null,
    editable: false,
    doubleClick: false,
    displayModeBar: false,  // hide the control icons
  };
  protected doa: {data: any, layout: any} = {
    data: [{
      line: {color: "red", width: 1},
      name: "Channel 0",
      font: {color: "#eeeeee"},
      opacity: 1,
      fill: "toself",
      type: "scatterpolar",
      theta: theta,
      r: Array(360).fill(0)
    }],
    layout: {
      polar: {
        angularaxis: {
          rotation: 90,
          direction: "clockwise"
        },
        bgcolor: "#020202"
      },
      autosize: true,
      dragmode: false,
      scrollmode: 'zoom',
      font: {color: "#eeeeee"},
      showlegend: false,
      margin: {pad: 0, t: 60, l: 0, r: 0, b: 25},
      paper_bgcolor: "#020202",
      plot_bgcolor: "#020202",
    }
  }
  protected spectrum:{data: any, layout:any} = {
    data: [{
      line: {color: "red", width: 1},
      name: "Channel 0",
      opacity: 1,
      visible: true,
      // fill: "toself",
      x: [],
      y: []
    },
    {
      line: {color: "green", width: 1},
      name: "VFO-0",
      opacity: 0.5,
      visible: true,
      fill: "toself",
      x: [],
      y: []
    }
    ],
    layout: {
      autosize: true,
      dragmode: false,
      scrollmode: 'zoom',
      xaxis: {
        showgrid: false,
        zeroline: false,
      },
      yaxis: {
        range: [this.cache.minAmplitude, this.cache.maxAmplitude],
        showgrid: false,
        zeroline: false,
      },
      // height: 100,
      // title: 'A Fancy Plot',
      font: {color: "#eeeeee"},
      showlegend: false,
      margin: {pad: 0, t: 0, l: 40, r: 20, b: 0},
      paper_bgcolor: "#020202",
      plot_bgcolor: "#020202",
    }
  };
  protected waterfall: any = {
    layout: {
      annotations: false,
      autosize: true,
      dragmode: false,
      showlegend: false,
      xaxis: {
        showgrid: false,
        zeroline: false,
        showticklabels: true,
        color: "white",
        ticks: ''
      },
      yaxis: {
        showgrid: false,
        zeroline: false,
        showticklabels: false,
        ticks: ''
      },
      margin: {pad: 0, t: 0, l: 40, r: 20, b: 40},
      paper_bgcolor: "#020202",
      plot_bgcolor: "#020202",
    },
    data: []
  };

  protected dataSubsription: Subscription|undefined;
  waterfallLines = 25;

  constructor(private apiService: ApiService) {
  }

  initPlots(data: KSPParsed) {
    this.spectrum.data[0].x = this.cache.x;
    this.spectrum.data[0].y = this.cache.y;
    this.spectrum.data[1].x = this.cache.x;
    this.spectrum.data[1].y = Array(this.cache.x.length).fill(-90);

    // Init Waterfall
    for (let i = 0 ; i < this.waterfallLines - 1; i++) {
      this.cache.z.push(this.cache.y);
    }
    this.waterfall.data.push({
      showscale: false,
      colorscale: [[0.0, "#020202"], [1.0, "#ff0202"]],
      type: "heatmap",
      x: this.cache.x,
      z: Array(50).fill(Array(this.cache.x.length).fill(-90)),
      hoverinfo: 'skip'
    });
    this.cache.plotsInit = true;
  }

  renderPlots(data: KSPParsed, shouldUpdateX: boolean = false) {
    if (!this.cache.plotsInit) {
      this.initPlots(data);
    }

    this.doa.layout.title = `DoA: ${data.doaMax}° (${data.doaConfidence?.toFixed(1)})`;
    this.doa.layout.title += ` / ${(this.latency / 1000).toFixed(1)}c`;
    const config = this.parent?.getConfig();
    if (config?.doa_fig_type == "Polar") {
      this.doa.layout.polar.angularaxis.rotation = 90;
      this.doa.layout.polar.angularaxis.direction = "clockwise";
      this.doa.data[0].type = "scatterpolargl";
      // this.doa.data[0].theta = theta;
      this.doa.data[0].r = data.doaResult;
    }
    if (config?.doa_fig_type == "Linear") {
      this.doa.data[0].type = "scatter";
      this.doa.data[0].x = theta;
      this.doa.data[0].y = data.doaResult;
      this.doa.data[0].fill = "none";
    }

    // X
    if (shouldUpdateX) {
      // Spectrum
      this.spectrum.data[0].x = this.cache.x;
      // VFO
      this.renderVfoPlot(this.cache.vfoFrequency, this.cache.vfoBandwidth);
    }

    // Spectrum Y
    this.spectrum.data[0].y = this.cache.y;

    this.cache.z.push(this.cache.y);
    this.cache.z.splice(0, 1);

    this.waterfall.data[0].x = [...this.cache.x];
    this.waterfall.data[0].z = this.cache.z;
  }

  getUTCTimestamp() {
    return new Date().getTime();
  }

  getTimeOffset(): void {
    const start = new Date().getTime();
    this.apiService.getServerTimestamp().subscribe(
      (next:HttpResponse<ApiResponse<number>>) => {
        const offset = new Date().getTime() - start;
        if (next.body?.data?.object) {
          const timestamp = this.getUTCTimestamp() - offset;
          this.timestampOffset = next.body?.data?.object - timestamp;
          console.log("timestampOffset:", this.station?.serial, this.timestampOffset);
        }
      }
    );
  }

  handleData(data: KSPParsed) {
    if (data.timestampService) {
      this.latency = (this.getUTCTimestamp() + this.timestampOffset) - data.timestampService;
      if (this.latency > 1500) {
        this.throttle += 100;
      } else if (this.latency >= 500) {
        this.throttle -= 50;
        if (this.throttle < 0) {
          this.throttle = 0;
        }
      }
    }
    // VFO
    if (data.maxAmplitude && data.maxAmplitude > this.cache.maxAmplitude) {
      this.cache.maxAmplitude = data.maxAmplitude + 5;
      this.spectrum.layout.yaxis.range = [this.cache.minAmplitude,
                                          this.cache.maxAmplitude];
    }

    let shouldUpdateVFO = false;
    let shouldUpdateX = false;
    if (data.vfoBandwidth && data.vfoBandwidth !== this.cache.vfoBandwidth) {
      this.cache.vfoBandwidth = data.vfoBandwidth;
      shouldUpdateVFO = true;
    }
    // update vfo frequency
    if (data.vfoFrequency && data.vfoFrequency !== this.cache.vfoFrequency) {
      this.cache.vfoFrequency = data.vfoFrequency;
      shouldUpdateVFO = true;
    }

    if (data.centerFrequency && data.centerFrequency !== this.cache.frequency) {
      this.cache.frequency = data.centerFrequency;
    }

    if (data.spectrum && this.cache.frequency > 0) {
      this.cache.y = [...data.spectrum[1]];
      if (this.cache.x[0] !== data.spectrum[0][0] + this.cache.frequency) {
        this.cache.x = data.spectrum[0].map((x: number) => x + this.cache.frequency);
        // update X axes
        shouldUpdateX = true;
      }
      if (shouldUpdateVFO) {
        this.renderVfoPlot(this.cache.vfoFrequency, this.cache.vfoBandwidth);
      }
      this.renderPlots(data, shouldUpdateX);
    }
  }

  tryHandlePackets(oldPacket: KSPParsed|undefined = undefined) {
    // console.log("throttle:", this.station?.serial, this.throttle);
    setTimeout(() => {
      if (this.isRunning) {
        const packet = this.packet;
        if (packet !== undefined && packet !== oldPacket) {
          this.handleData(packet);
        }
        return this.tryHandlePackets(packet);
      }
    }, this.throttle);
  }

  ngOnInit() {
    this.dataSubsription = this.dataObservable?.subscribe({
      next: (data: KSPParsed) => {
        this.packet = data;
      }
    });
    this.getTimeOffset();
    this.tryHandlePackets();
  }

  ngOnDestroy() {
    this.dataSubsription?.unsubscribe();
    this.isRunning = false;
  }

  renderVfoPlot(vfoFrequency: number|undefined, vfoBandwidth: number|undefined) {
    if (vfoFrequency !== undefined && vfoBandwidth !== undefined) {
      const vfoHalfBandwidth = vfoBandwidth / 2;
      const minVfoBandwidth = vfoFrequency - vfoHalfBandwidth;
      const maxVfoBandwidth = vfoFrequency + vfoHalfBandwidth;
      const vfoY = Array(this.cache.x.length).fill(-140);
      for (let i = 0, freq; i < this.cache.x.length; i += 1) {
        freq = this.cache.x[i];
        if (freq < minVfoBandwidth || freq > maxVfoBandwidth) {
          // vfoY[i] = 0;
        } else {
          vfoY[i] = 0;
        }
      }
      this.spectrum.data[1].x = this.cache.x;
      this.spectrum.data[1].y = vfoY;
    }
  }

  setNewVfoFrequency(event: any) {
    console.log(`setNewVfoFrequency: ${event.points[0].x}`);
    if (event.points[0].x !== undefined) {
      const vfoFrequency = event.points[0].x / 1000000;
      this.parent?.setNewVfoFrequency(vfoFrequency)?.subscribe({
        next: (next: any) => {
          this.renderVfoPlot(vfoFrequency, this.cache.vfoBandwidth);
        }
      });
    }
  }
}
