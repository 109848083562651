import {EventEmitter, Injectable} from '@angular/core';
import {Notification, NotificationType} from './notification';

@Injectable()
export class NotificationService {

  private id = 0;
  public notifications = new EventEmitter<Notification>();

  constructor() {}

  public primary(message: string, subject: string = 'Повідомлення'): void {
    this.addMessage(subject, message, NotificationType.PRIMARY);
  }

  public success(message: string, subject: string = 'OK'): void {
    this.addMessage(subject, message, NotificationType.SUCCESS);
  }

  public warning(message: string, subject: string = 'Увага'): void {
    this.addMessage(subject, message, NotificationType.WARNING);
  }

  public info(message: string, subject: string = 'Інфо'): void {
    this.addMessage(subject, message, NotificationType.INFO);
  }

  public error(message: string, subject: string = 'Помилка'): void {
    this.addMessage(subject, message, NotificationType.ERROR);
  }

  private addMessage(subject: string, message: string, type: NotificationType): void {
    this.id += 1;
    this.notifications.emit({id: this.id, subject, message, type});
  }

}
