import { Observable,
         Observer } from 'rxjs';
import {WEB_SOCKET_ENDPOINT} from "../app.urls";
import {StorageService} from "../storage.service";


export class WebSocketService {
  private callback: CallableFunction|undefined;
  private messageObserver: Observer<any>|undefined;
  private messageObservable: Observable<any>;

  private socket: WebSocket|null = null;
  private session: string|null = null;

  private connectionInterval: any|null;
  private serial: string|null = null;

  constructor(private storageService: StorageService) {
    this.session = this.storageService.getSession();
    this.messageObservable = new Observable((observer: Observer<any>) => {
      this.messageObserver = observer;
    });
  }

  public start() {
    this.connect();
  }

  public stop() {
    this.disconnect();
  }

  public restart() {
    this.stop();
    this.start();
  }

  public subscribeStation(serial: string, callback: CallableFunction|undefined = undefined): Observable<any> {
    this.callback = callback;
    this.serial = serial;
    this.start();
    return this.messageObservable;
  }

  public connect() {
    if (this.session) {
      clearTimeout(this.connectionInterval);
      this.socket = new WebSocket(WEB_SOCKET_ENDPOINT + '?session=' + this.session);
      this.socket.onopen    = (event: Event) => this.onOpen(event);
      this.socket.onclose   = (event: CloseEvent) => this.onClose(event);
      this.socket.onerror   = (event: Event) => this.onError(event);
      this.socket.onmessage = (event: MessageEvent<any>) => this.onMessage(event);
    } else {
      console.log('session is undefined, reconnecting in 1 second');
      this.connectionInterval = setTimeout(() => this.connect(), 1000);
    }
  }

  public disconnect() {
    if (this.socket) {
      this.socket.onopen    = (event: Event) => {};
      this.socket.onclose   = (event: CloseEvent) => {};
      this.socket.onerror   = (event: Event) => {};
      this.socket.onmessage = (event: MessageEvent<any>) => {};
      this.socket.close();
    }
  }

  public onOpen(event: Event) {
    if (this.callback !== undefined) {
      this.callback();
    }
    this.socket?.send(JSON.stringify({serial: this.serial}));
  }

  public onClose(event: CloseEvent) {
    console.log("WebSocketService::onClose");
    this.disconnect();
    setTimeout(() => this.connect(), 1000);
  }

  public onError(event: Event) {
    console.log('WebSocketService::onError');
    this.disconnect();
    setTimeout(() => this.connect(), 1000);
  }

  public onMessage(event: MessageEvent<any>) {
    try {
      const data = JSON.parse(event.data);
      this.messageObserver?.next(data);
    } catch (e) {
      console.log('onMessage error: ' + e);
    }
  }
}
