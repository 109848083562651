import {AfterViewInit, ChangeDetectorRef, Component, Inject} from '@angular/core';
import {PageEvent} from "@angular/material/paginator";
import {Station} from "../../../stations/stations.component";
import {ApiService} from "../../../api.service";
import {finalize} from "rxjs";
import {LoaderService} from "../../../loader.service";
import {GroupComponent} from "../group.component";
import {Paginator} from "../../../common/app.paginator";
import {MatSnackBar} from "@angular/material/snack-bar";

@Component({
  selector: 'app-group-add-station',
  templateUrl: './group-add-station.component.html',
  styleUrls: ['./group-add-station.component.scss']
})
export class GroupAddStationComponent implements AfterViewInit {
  protected items: Station[] = [];
  protected done = false;
  protected page: number = 0;
  protected paginator: Paginator|undefined = {
    currPage: 0,
    firstPage: 0,
    lastPage: 0,
    nextPage: 0,
    pages: [0],
    prevPage: 0,
    currPageObjects: 0,
    pageSize: 0,
    totalObjects: 0,
  };

  constructor(private apiService: ApiService,
              private loaderService: LoaderService,
              private cdr: ChangeDetectorRef,
              private snackBar: MatSnackBar,
              @Inject(GroupComponent) protected parent: GroupComponent) {
  }

  ngAfterViewInit() {
    this.getFreeStations();
  }

  removeGroupStation(groupId: number|undefined, stationId: number|undefined) {
    console.log("removeGroupStation:", groupId, " | ", stationId);
    if (groupId && stationId) {
      this.loaderService.startLoading();
      this.apiService.groupDelStation(groupId, stationId).pipe(finalize(
        () => this.loaderService.stopLoading()
      )).subscribe({
        next: value => {
          if (this.parent.item?.stations) {
            this.parent.item.stations = this.parent.item?.stations.filter(item => item.id !== stationId)
          }
          this.getFreeStations();
        },
        error: err => this.snackBar.open('Щось пішло не так!', 'Ок', {duration: 7000})
      })
    }
  }

  addGroupStation(item: Station, groupId: number|undefined, stationId: number|undefined) {
    if (this.parent.item?.stations && this.parent.item.stations.length < 3) {
      if (groupId && stationId) {
        this.loaderService.startLoading();
        this.apiService.groupAddStation(groupId, stationId).pipe(finalize(
          () => this.loaderService.stopLoading()
        )).subscribe({
          next: value => {
            this.items = this.items.filter(item => item.id != stationId);
            this.parent.item?.stations?.push(item);
          },
          error: err => this.snackBar.open('Щось пішло не так!', 'Ок', {duration: 7000})
        })
      }
    }
  }

  getFreeStations(pageIndex: number|undefined = undefined) {
    this.loaderService.startLoading();
    this.apiService.getFreeStations(pageIndex).pipe(finalize(
      () => this.loaderService.stopLoading()
    )).subscribe({
      next: response => {
        this.items = response.body?.data?.objects || [];
        this.paginator = response.body?.data?.paginator;
        console.log('items:', this.items);
        this.cdr.detectChanges();
      }
    })
  }

  handlePageEvent(event: PageEvent) {
    this.getFreeStations(event.pageIndex);
  }

}
