import { HttpErrorResponse,
         HttpEvent,
         HttpHandler,
         HttpInterceptor,
         HttpRequest } from '@angular/common/http';
import {map, Observable} from 'rxjs';
import { catchError } from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import {StorageService} from "./storage.service";
import {NotificationService} from "./notification/notification.service";
import {MatSnackBar} from "@angular/material/snack-bar";


export const SESSION_HEADER = "Authorization";


@Injectable()
export class AuthorizationInterceptor implements HttpInterceptor {

  constructor(private storageService: StorageService, private router: Router,
              private snackBar: MatSnackBar) {}

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {

    if (request.headers.getAll('NoIntercept')) {
      return this.wrapNext(request, next);
    }

    const session = this.storageService.getSession();

    if (session && !request.headers.get(SESSION_HEADER)) {
      request = request.clone({ headers: request.headers.set(SESSION_HEADER, `Bearer ${session}`) });
    }

    return this.wrapNext(request, next);
  }

  wrapNext(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>>{
    return next.handle(request).pipe(catchError((e: HttpErrorResponse) => {
      throw this.error(e);
    }));
  }

  private error(e: HttpErrorResponse): any {
    try {
      // const error = e.error as Response;
      // console.log("error handler:", JSON.stringify(error.status));
      if (e.error.status.code === 401) {
        this.snackBar.open('Сессія завершена!', 'Ок', {duration: 7000})
        this.storageService.clearSession();
        this.router.navigateByUrl('/').then();
        return;
      }
    } catch (e) {
      console.log('Server or connection error!');
    }
    return e;
  }

}
