import {NgModule} from '@angular/core';
import {RouterModule, Routes} from '@angular/router';
import {authGuard} from "./app.guard";
import {MainComponent} from "./main/main.component";
import {GroupsComponent} from "./groups/groups.component";
import {
  ROUTE_GROUP_ID, ROUTE_GROUP_NEW,
  ROUTE_GROUPS,
  ROUTE_MAIN,
  ROUTE_MAP,
  ROUTE_SCANNERS, ROUTE_SETTINGS,
  ROUTE_STATION,
  ROUTE_STATIONS
} from "./app.urls";
import {StationsComponent} from "./stations/stations.component";
import {MapComponent} from "./map/map.component";
import {StationComponent} from "./stations/station/station.component";
import {StationSettingsComponent} from "./stations/station/station-settings/station-settings.component";
import {StationSpectrumComponent} from "./stations/station/station-spectrum/station-spectrum.component";
import {ScannersComponent} from "./scanners/scanners.component";
import {GroupSpectrumComponent} from "./groups/group/group-spectrum/group-spectrum.component";
import {GroupSettingsComponent} from "./groups/group/group-settings/group-settings.component";
import {GroupComponent} from "./groups/group/group.component";
import {GroupAddStationComponent} from "./groups/group/group-add-station/group-add-station.component";
import {SettingsComponent} from "./settings/settings.component";


const routes: Routes = [
  {path: ROUTE_MAP, component: MapComponent, canActivate: [authGuard]},
  {path: ROUTE_STATIONS, component: StationsComponent, canActivate: [authGuard]},
  {path: ROUTE_STATION, component: StationComponent, canActivate: [authGuard], children:[
      {path: '', redirectTo: 'settings', pathMatch: "full"},
      {path: 'settings', component: StationSettingsComponent},
      {path: 'spectrum', component: StationSpectrumComponent}
  ]},
  {path: ROUTE_GROUPS, component: GroupsComponent, canActivate: [authGuard]},
  {path: ROUTE_GROUP_ID, component: GroupComponent, canActivate: [authGuard], children:[
      {path: '', redirectTo: 'settings', pathMatch: "full"},
      {path: 'add-station', component: GroupAddStationComponent},
      {path: 'settings', component: GroupSettingsComponent},
      {path: 'spectrum', component: GroupSpectrumComponent}
  ]},
  {path: ROUTE_SETTINGS, component: SettingsComponent, canActivate: [authGuard]},
  {path: ROUTE_SCANNERS, component: ScannersComponent, canActivate: [authGuard]},
  {path: ROUTE_MAIN, component: MainComponent, canActivate: [authGuard]}
];


@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
