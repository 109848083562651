<h1 class="text-center p-05em margin-0">{{ station?.name || "Station" }}:</h1>
<plotly-plot [data]="doa.data"
             [layout]="doa.layout"
             [config]="config"
             [useResizeHandler]="false"
             [style]="{width: '100%', height:'350px'}">
</plotly-plot>
<plotly-plot [data]="spectrum.data"
             [layout]="spectrum.layout"
             [config]="config"
             [useResizeHandler]="false"
             (plotlyClick)="setNewVfoFrequency($event)"
             [style]="{width: '100%', height:'250px'}"/>
<plotly-plot [data]="waterfall.data"
             [layout]="waterfall.layout"
             [config]="config"
             [useResizeHandler]="false"
             [style]="{width: '100%', height:'250px'}"/>
