import {AfterViewInit, Component, NgZone, OnDestroy, OnInit} from '@angular/core';
import {LoaderService} from "../loader.service";
import {Router} from "@angular/router";
import {LINK_GROUPS, LINK_MAIN, LINK_MAP, LINK_STATIONS} from "../app.urls";
import * as GeographicLib from 'geographiclib-geodesic'
import {environment} from "../../environments/environment";
import {Paginator} from "../common/app.paginator";
import {finalize} from "rxjs";
import {HttpResponse} from "@angular/common/http";
import {ApiResponse, ApiService} from "../api.service";
import {Group} from "../groups/groups.component";
import {PageEvent} from "@angular/material/paginator";
import {StorageService} from "../storage.service";
import {StationPin} from "./station-pin";
import {MatDialog} from "@angular/material/dialog";
import {DialogInfo} from "../common/info.component";
import {PositionPin, PositionType} from "./position-pin";
import {IntersectService} from "./intersect.service";
import {StationsDoAService} from "../stations-doa.service";


@Component({
  selector: 'app-map',
  templateUrl: './map.component.html',
  styleUrls: ['./map.component.scss']
})
export class MapComponent implements AfterViewInit, OnDestroy {
  L: any = (<any>window).L;
  map: any
  paginator: Paginator = {
    currPage: 0,
    firstPage: 0,
    lastPage: 0,
    nextPage: 0,
    pages: [0],
    prevPage: null,
    currPageObjects: 0,
    pageSize: 0,
    totalObjects: 0,
  };
  groups: Group[] = [];
  lastGroupId: number|undefined = undefined;
  activeGroup: Group|undefined = undefined;
  stationPins: StationPin[] = [];

  position: PositionPin|undefined = undefined;
  positionWatcher: number = -1;

  geoInit = false;
  watcherInit = false;

  intersectService: IntersectService|undefined;

  constructor(private loadService: LoaderService, protected router: Router,
              private doaService: StationsDoAService,
              private ngZone: NgZone,
              private apiService: ApiService,
              private storageService: StorageService,
              private dialog: MatDialog) {
    this.lastGroupId = this.storageService.getLastGroupId();
  }

  ngAfterViewInit() {
    this.map = this.L.map('map', {touchZoom: true, zoomAnimation: true,
                                  zoomControl: false, minZoom: 1, maxZoom: 20,
                                  zoomDelta: 0.25, zoomSnap: 0.25}).setView([50.4501, 30.5234], 5);
    this.L.tileLayer(environment.MAP_TILE, {maxZoom: 20}).addTo(this.map);

    const intersection = this.L.marker([0, 0]);
    this.intersectService = new IntersectService(this.L, this.map, intersection);

    setTimeout(() => {
      this.getGroups();
      this.position = new PositionPin(this.L, this.map);
      this.map.on("dragstart", () => this.position?.onMoveStart());
      this.map.on("dragend", () => this.position?.onMoveEnd());
      this.map.on("zoomstart", () => this.position?.onZoomStart());
      this.map.on("zoomend", () => this.position?.onZoomEnd());
      window.addEventListener("deviceorientationabsolute",
                              (event) => this.onDeviceOrientation(event), true);
    });
  }

  ngOnDestroy() {
    for (let pin of this.stationPins) {
      pin.clear();
    }
    this.position?.onDestroy();
    this.clearGeoWatcher();
  }

  setActiveGroup(group: Group) {
    this.activeGroup = group;
    this.storageService.setLastGroupId(group.id);
    const latLngs = [];
    for (let i = 0; i < group.stations.length; i++) {
      let pin;
      const station = group.stations[i];
      if (i < this.stationPins.length) {
        pin = this.stationPins[i];
      } else {
        pin = new StationPin(this.L, this.map, this.storageService, this.intersectService,
                             this.doaService, this.apiService);
        this.stationPins.push(pin);
      }
      if (station.conf?.latitude !== undefined && station.conf?.longitude !== undefined) {
        latLngs.push([station.conf.latitude, station.conf.longitude]);
      }
      pin.setStation(group.stations[i]);
    }
    if (latLngs.length > 0) {
      this.map.fitBounds(latLngs, {paddingTopLeft: [30, 30], paddingBottomRight: [30, 30]});
    } else {
      this.map.fitWorld({maxZoom: 20});
    }
    // clear unused pins
    for (let i = group.stations.length; i < this.stationPins.length; i++) {
      this.stationPins[i].clear();
    }
  }

  public getGroups(pageIndex: number|undefined = undefined) {
    this.loadService.startLoading();
    this.apiService.getGroups(pageIndex).pipe(finalize(() => {
      this.loadService.stopLoading();
    })).subscribe({
      next: (response: HttpResponse<ApiResponse<Group[]>>) => {
        if (response.body?.data?.objects) {
          this.groups = response.body?.data.objects;
        }
        if (response.body?.data?.paginator) {
          this.paginator = response.body?.data.paginator;
        }
        for (let group of this.groups) {
          if (group.id === this.lastGroupId) {
            this.setActiveGroup(group);
          }
        }
      },
      error: (error: any) => {}
    });
  }

  handlePageEvent(event: PageEvent) {
    this.getGroups(event.pageIndex);
  }

  zoomIn() {
    this.map.zoomIn(0.5);
  }

  zoomOut() {
    this.map.zoomOut(0.5);
  }

  clearGeoWatcher() {
    if (this.positionWatcher !== -1) {
      navigator.geolocation.clearWatch(this.positionWatcher);
      this.watcherInit = false;
    }
  }

  toggleMyLocation() {
    if (this.position?.toggle()) {
      if (!this.geoInit) {
        this.geoInit = true;
        navigator.geolocation.getCurrentPosition(
          position => this.onLocationFound(position),
          () => this.onLocationError()
        );
      }
      if (!this.watcherInit) {
        this.watcherInit = true;
        this.positionWatcher = navigator.geolocation.watchPosition(
          position => this.onLocationFound(position)
        );
      }
    } else {
      this.clearGeoWatcher();
    }
  }

  public onLocationError() {
    // show notification regarding location error!
    this.dialog.open(DialogInfo, {data: {title: 'Моє положення',
                                         paragraphs: ['Не вдалось знайти ваше місцезнаходження']}});
    if (this.positionWatcher !== -1) {
      navigator.geolocation.clearWatch(this.positionWatcher);
    }
  }

  public onLocationFound(position: GeolocationPosition) {
    this.position?.onLocationFound(position);
  }

  public onDeviceOrientation(event: any) {
    this.position?.onDeviceOrientation(event);
  }

  protected readonly LINK_MAIN = LINK_MAIN;
  protected readonly LINK_GROUPS = LINK_GROUPS;
  protected readonly LINK_STATIONS = LINK_STATIONS;
  protected readonly LINK_MAP = LINK_MAP;
  protected readonly undefined = undefined;
}
