import {AfterViewInit, ChangeDetectorRef, Component} from '@angular/core';
import {LINK_GROUPS, LINK_MAIN, ROUTE_GROUPS,} from "../../app.urls";
import {ActivatedRoute, Router} from "@angular/router";
import {LoaderService} from "../../loader.service";
import {MatDialog} from "@angular/material/dialog";
import {FormGroup} from "@angular/forms";
import {GroupSettingsComponent} from "./group-settings/group-settings.component";
import {GroupSpectrumComponent} from "./group-spectrum/group-spectrum.component";
import {StationStatus} from "../../stations/stations.component";
import {Group, GroupConfig} from "../groups.component";
import {finalize, Observable} from "rxjs";
import {HttpResponse} from "@angular/common/http";
import {ApiResponse, ApiService} from "../../api.service";
import {GroupAddStationComponent} from "./group-add-station/group-add-station.component";
import {StorageService} from "../../storage.service";


@Component({
  selector: 'app-group',
  templateUrl: './group.component.html',
  styleUrls: ['./group.component.scss']
})
export class GroupComponent implements AfterViewInit {

  protected status: StationStatus = StationStatus.UNKNOWN;
  protected readonly LINK_MAIN = LINK_MAIN;
  protected itemId: number | undefined;
  public item: Group | undefined;
  protected activeTab: number = 1;
  protected settingsComponent: GroupSettingsComponent | undefined;

  protected form: FormGroup | undefined;

  constructor(private route: ActivatedRoute, private router: Router,
              private apiService: ApiService, private cdr: ChangeDetectorRef,
              public dialog: MatDialog, private loaderService: LoaderService,
              private storageService: StorageService) {
  }

  public loadGroup() {
    this.itemId = this.route.snapshot.params['itemId'];
    if (this.itemId) {
      this.storageService.addLastGroup(this.itemId);
    }

    this.loaderService.startLoading();
    this.apiService.getGroup(this.itemId).pipe(finalize(() => {
      this.loaderService.stopLoading()
    })).subscribe({
      next: (response: HttpResponse<ApiResponse<Group>>|undefined) => {
        const item = response?.body?.data?.object;
        if (item) {
          this.renderItem(item);
        }
      },
      error: (response: any) => {}
    });
  }

  ngAfterViewInit(){
    this.loadGroup();
  }

  renderItem(item: Group) {
    this.item = item;

    let status = this.item.stations.length > 0 ? StationStatus.OKAY : StationStatus.NEW;
    for (let station of this.item.stations) {
      if (!station.connected) {
        status = StationStatus.WARNING;
        break;
      }
    }
    this.status = status;
    if (this.settingsComponent) {
      this.settingsComponent.renderConfig(this.item);
    }
    this.cdr.detectChanges();
  }

  onOutletLoaded(component: GroupSettingsComponent | GroupSpectrumComponent | GroupAddStationComponent) {
    if (component instanceof GroupAddStationComponent){
      console.log("GroupAddStationComponent");
      this.activeTab = 0;
      this.form = undefined;
    } else if (component instanceof GroupSettingsComponent) {
      this.activeTab = 1;
      this.form = component.form;
      this.settingsComponent = component;
      if (this.settingsComponent && this.item) {
        this.settingsComponent.renderConfig(this.item);
      }
    } else {
      this.activeTab = 2;
      this.form = undefined;
    }
    this.cdr.detectChanges();
  }

  save() {
    this.settingsComponent?.save();
  }

  public getConfig() {
    return this.item?.conf;
  }

  public setNewVfoFrequency(vfoFrequency: number): Observable<HttpResponse<ApiResponse<GroupConfig>>>|undefined {
    if (this.item?.conf.id) {
      return this.apiService.patchGroupConfig(this.item?.conf.id, {vfo_freq_0: vfoFrequency});
    }
    return undefined;
  }

  onNavTabChange($event: any) {
    this.activeTab = $event.index;
    this.cdr.detectChanges();
    if (this.itemId) {
      switch ($event.index) {
        case 0:
          this.router.navigate([ROUTE_GROUPS, this.itemId, 'add-station']);
          break;
        case 1:
        default:
          this.router.navigate([ROUTE_GROUPS, this.itemId, 'settings']);
          break;
        case 2:
          this.router.navigate([ROUTE_GROUPS, this.itemId, 'spectrum']);
          if (this.settingsComponent && this.item) {
            this.settingsComponent.renderConfig(this.item);
          }
          break;
      }
    }
  }

  protected readonly LINK_GROUPS = LINK_GROUPS;
}
