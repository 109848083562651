export interface Notification {
  id: number;
  subject: string;
  message: string;
  type: NotificationType;
}

export enum NotificationType {
  PRIMARY = 0,
  SUCCESS = 1,
  WARNING = 2,
  INFO    = 3,
  ERROR   = 4,
}
