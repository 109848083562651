<div class="group-header">
  <div class="group-name">
    <mat-icon>device_hub</mat-icon>
    <span>{{ name }}</span>
  </div>
  <!--<div class="group-frequency">
    <mat-icon>wifi_tethering</mat-icon>
    <span>{{ frequency }}</span>
  </div>-->
</div>
<!--
<div *ngFor="let station of stations" class="station">
  <div class="station-status-block">
    <div class="station-status">
      <div class="status-text">
        <span class="{{ station.status | statusToClassString }}"><span>{{station.status | statusToString}}</span></span>
      </div>
    </div>
    <div class="station-uptime"><mat-icon>access_time</mat-icon><span>{{station.uptime}}</span></div>
  </div>
  <div class="station-info-block">
    <div class="station-info-name"><span>{{ station.name }}</span></div>
    <div class="station-info-other">
      <span class="sub-icon-text">
      <mat-icon>wifi_tethering</mat-icon>
      <span>{{ station.frequency }} МГц</span>
    </span>
      <span class="sub-icon-text">
      <mat-icon>location_on</mat-icon>
      <span>{{ station.location }}</span>
    </span>
    </div>
  </div>
  <div class="station-box-block">
    <div class="station-box-a-info">
      <div class="status-text"><span class="success"><span>RUN</span><mat-icon class="internet">thermostat</mat-icon><span>{{ station.temperature_run || '-&#45;&#45;' }} Cº</span></span></div>
    </div>
    <div class="station-box-b-info">
      <div class="status-text"><span [class.success]="station.uplink_run"><mat-icon class="internet">language</mat-icon><span>A</span></span></div>
      <div class="status-text"><span [class.success]="station.uplink_gs"><mat-icon class="internet">language</mat-icon><span>B</span></span></div>
      <div class="status-text"><span [class]="station.power === true ? 'success' : (station.power === false ? 'error': '')"><mat-icon class="internet">bolt</mat-icon></span></div>
      <div class="status-text"><span class="success"><span>{{ station.battery || '-&#45;&#45;' }}%</span></span></div>
      <div class="status-text"><span class="success"><span>GS</span><mat-icon class="internet">thermostat</mat-icon><span>{{ station.temperature_gs || '-&#45;&#45;'}} Cº</span></span></div>
    </div>
  </div>
</div>
-->

<app-station-preview *ngFor="let item of stations" class="station"
                     [id]="item.id"
                     [name]="item.name"
                     [status]="item.status"
                     [groupId]="item.groupId"
                     [hideGroupName]="true"
                     [groupName]="item.groupName"
                     [uptime]="item.uptime_run"
                     [serial]="item.serial"
                     [frequency]="item.conf?.center_freq"
                     [location]="item.location"
                     [connected]="item.connected"
                     [power]="item.power"
                     [battery]="item.battery"
                     [temperature_run]="item.temperature_run"
                     [temperature_gs]="item.temperature_gs"
                     [uplink_a]="item.uplink_a"
                     [uplink_b]="item.uplink_b"/>

<div *ngIf="stations.length < 1" class="blank-list">
  <span>В цьому комплексі шє немає жодного пеленгатора</span>
</div>
