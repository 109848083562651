import { Injectable } from '@angular/core';
import { Location } from '@angular/common';
import { Router } from '@angular/router';
import {
  HttpClient, HttpContext, HttpHeaders,
  HttpParams, HttpRequest, HttpResponse
} from '@angular/common/http';
import {HttpService} from "../http.service";
import {API_SIGN_IN, API_SIGN_OUT, API_SIGN_UP, API_SIGN_UP_FIN} from "./auth.urls";
import {map, Observable, of, switchMap, throwError} from "rxjs";
import {StorageService} from "../storage.service";
import {catchError} from "rxjs/operators";
import {ApiResponse, ApiResponseTOTP} from "../api.service";


export interface ApiResponseStatus {
  code: number;
  message: string
}

export interface ApiResponseSignInData {
  token: string;
  is_superuser: boolean;
  permissions: string[]
}

export interface ApiResponseSignIn {
  status: ApiResponseStatus
  data: ApiResponseSignInData
}


@Injectable()
export class AuthService extends HttpService {

  private storage: Storage;

  /* services */
  private location: Location;
  private router: Router;

  constructor(httpClient: HttpClient, location: Location, router: Router,
              private storageService: StorageService) {
    super(httpClient);
    this.location     = location;
    this.router       = router;
    this.storage      = localStorage;
  }

  public isAuth(): boolean {
    return this.storageService.getSession() !== null;
  }

  public signOut() {
    const headers: HttpHeaders = new HttpHeaders().set('NoIntercept', '');
    return this.get(API_SIGN_OUT, headers).pipe(map(
      (response: HttpResponse<ApiResponseSignIn>) => {
        const data = response.body;
        if (data?.status.code === 200) {
          console.log("okay, returning response");
          this.storageService.removeSession();
          return response;
        }
        throw new Error('Bad server response!');
      }
    ));
  }

  public signIn(username: string, password: string, otp: string) {
    const headers: HttpHeaders = new HttpHeaders().set('NoIntercept', '');
    return this.post(API_SIGN_IN, {username, password, otp}, headers).pipe(switchMap(
      (value: HttpResponse<ApiResponseSignIn>, _: number) => {
        const data = value.body;
        if (data?.data.is_superuser !== undefined) {
          // this.saveIs
          this.storageService.setSuperuser(data.data.is_superuser);
        }
        if (data?.data.token) {
          this.storageService.setSession(data.data.token);
        }
        if (data?.data.permissions) {
          // this.storageService.
        }
        return of(data);
      }
    ), catchError((error: any, _: Observable<any>) => {
      if (error?.error?.status?.message) {
        throw error.error.status.message;
      }
      console.error(error);
      throw undefined;
    }));
  }

  public checkTokenExists(token: string): Observable<boolean> {
    const headers: HttpHeaders = new HttpHeaders().set('NoIntercept', '');
    return this.get(`${API_SIGN_UP}/${token}`, headers).pipe(switchMap(
      (response: HttpResponse<ApiResponseSignIn>) => {
        return new Observable<boolean>(subscriber => {
          const data = response.body;
          subscriber.next(data?.status.code === 200);
          subscriber.complete();
        });
      }
    ),
    catchError((error: any, _: Observable<any>) => {
      if (error?.error?.status?.message) {
        throw error.error.status.message;
      }
      console.error(error);
      throw undefined;
    }));
  }

  public finishSignUn(token: string, otp: string): Observable<boolean> {
    const headers: HttpHeaders = new HttpHeaders().set('NoIntercept', '');
    return this.post(`${API_SIGN_UP_FIN}/${token}`, {otp: otp}, headers).pipe(
      switchMap(
        (response: HttpResponse<ApiResponse<ApiResponseTOTP|undefined>>) => {
          return new Observable<boolean>(subscriber => {
            const data = response.body;
            subscriber.next(data?.status.code === 200);
            subscriber.complete();
          });
        }
      ),
      catchError((error: any, _: Observable<any>) => {
        if (error?.error?.status?.message) {
          throw error.error.status.message;
        }
        console.error(error);
        throw undefined;
      })
    );
  }

  public signUn(token: string, username: string, password: string): Observable<string|undefined> {
    const headers: HttpHeaders = new HttpHeaders().set('NoIntercept', '');
    return this.post(`${API_SIGN_UP}/${token}`, {username, password}, headers).pipe(
      switchMap(
        (response: HttpResponse<ApiResponse<ApiResponseTOTP|undefined>>) => {
          return new Observable(subscriber => {
            const data = response.body;
            subscriber.next(data?.data?.object?.totp);
            subscriber.complete();
          });
        }
      ),
      catchError((error: any, _: Observable<any>) => {
        if (error?.error?.status?.message) {
          throw error.error.status.message;
        }
        console.error(error);
        throw undefined;
      })
    );
  }
}
