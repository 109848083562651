import {AfterViewInit, Component, CUSTOM_ELEMENTS_SCHEMA, HostListener, NgZone, OnDestroy, OnInit} from '@angular/core';
import {MatToolbarModule} from "@angular/material/toolbar";
import {MatIconModule} from "@angular/material/icon";
import {MatButtonModule} from "@angular/material/button";
import {Router, RouterLink, RouterLinkActive} from "@angular/router";
import {MatListModule} from "@angular/material/list";
import {MatDrawerMode, MatSidenavModule} from "@angular/material/sidenav";
import {debounce, debounceTime, fromEvent, map, Observable, Subject, Subscription} from "rxjs";
import {AsyncPipe, NgIf, NgOptimizedImage} from "@angular/common";
import {MatMenuModule} from "@angular/material/menu";
import {LINK_STATIONS, LINK_GROUPS, LINK_MAP, LINK_MAIN, LINK_SCANNERS, LINK_SETTINGS} from "../app.urls";
import {MatRippleModule} from "@angular/material/core";
import {AuthService} from "../auth/auth.service";
import {LoaderService} from "../loader.service";
import {ROUTER_SIGN_IN} from "../auth/auth.urls";
import {StorageService} from "../storage.service";
import {DialogInfo} from "../common/info.component";
import {MatDialog} from "@angular/material/dialog";
import {environment} from "../../environments/environment";

@Component({
  selector: 'app-navbar',
  templateUrl: './navbar.component.html',
  styleUrls: ['./navbar.component.scss'],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
  imports: [
    MatToolbarModule,
    MatButtonModule,
    MatIconModule,
    RouterLink,
    MatListModule,
    MatSidenavModule,
    RouterLinkActive,
    NgIf,
    MatMenuModule,
    MatRippleModule,
    AsyncPipe,
    NgOptimizedImage
  ],
  standalone: true
})
export class NavbarComponent implements OnDestroy, OnInit {
  drawerMode: MatDrawerMode = 'over';
  drawerButtonVisible: boolean = true;
  drawerOpened: boolean = true;
  resize: Subscription|undefined;

  constructor(private ngZone: NgZone, private router: Router, private storageService: StorageService,
              private authService: AuthService, private loaderService: LoaderService,
              private dialog: MatDialog) {
    this.drawerOpened = this.storageService.getDrawerVisible();
  }


  ngOnInit() {
    this.onResize();
    this.resize = fromEvent(window, 'resize')
        .pipe(map((i: any) => i), debounceTime(250))
        .subscribe((event) => this.onResize(event));
  }

  ngOnDestroy() {
    this.resize?.unsubscribe();
  }

  onResize(event: any|undefined = undefined) {
    const target = event === undefined ? window : event.target;
    this.drawerMode = target.innerWidth >= 768 ? 'side': 'over';
  }

  drawerToggle(opened: boolean|undefined = undefined) {
    this.drawerOpened = opened === undefined ? !this.drawerOpened : opened;
    this.storageService.setDrawerVisible(this.drawerOpened);
  }

  showInfo() {
    this.dialog.open(DialogInfo, {
      data: {title: 'Інформація', paragraphs: [`ETER Cloud v${environment.VERSION}`]}
    });
  }

  logout() {
    console.log("logout");
    this.loaderService.startLoading()
    this.authService.signOut().subscribe({
      next: (_: any) => {
        console.log("next");
        return this.router.navigateByUrl(ROUTER_SIGN_IN).then();
      },
      error: (_: any) => {
        console.log("error")
      },
      complete: () => {
        console.log("complete");
        this.loaderService.stopLoading();
      }
    });
  }

  protected readonly LINK_MAIN = LINK_MAIN;
  protected readonly LINK_STATIONS = LINK_STATIONS;
  protected readonly LINK_GROUPS = LINK_GROUPS;
  protected readonly LINK_MAP = LINK_MAP;
  protected readonly LINK_SCANNERS = LINK_SCANNERS;
  protected readonly LINK_SETTINGS = LINK_SETTINGS;
}
