import {MatPaginatorIntl} from "@angular/material/paginator";

const uaRangeLabel = (page: number, pageSize: number, length: number) => {
  if (length == 0 || pageSize == 0) { return `0 з ${length}`; }

  length = Math.max(length, 0);

  const startIndex = page * pageSize;

  // If the start index exceeds the list length, do not try and fix the end index to the end.
  const endIndex = startIndex < length ?
    Math.min(startIndex + pageSize, length) :
    startIndex + pageSize;

  return `${startIndex + 1} - ${endIndex} з ${length}`;
}


export function uaPaginatorIntl() {
  const paginatorIntl = new MatPaginatorIntl();
  paginatorIntl.firstPageLabel = "Перша сторінка";
  paginatorIntl.lastPageLabel = "Крайня сторінка";
  paginatorIntl.itemsPerPageLabel = "Максимум на сторінці";
  paginatorIntl.nextPageLabel = 'Наступна сторінка';
  paginatorIntl.previousPageLabel = 'Попередня сторінка';
  paginatorIntl.getRangeLabel = uaRangeLabel;
  return paginatorIntl;
}


export interface Paginator {
  currPage: number;
  firstPage: number | null;
  lastPage: number | null;
  nextPage: number | null;
  pages: number[];
  prevPage: number | null;
  currPageObjects: number;
  pageSize: number;
  totalObjects: number;
}
