import {
  AfterViewInit,
  ChangeDetectorRef,
  Component, Inject,
  OnDestroy,
  OnInit,
  ViewChild,
  ViewEncapsulation
} from '@angular/core';
import {LoaderService} from "../../../loader.service";
import {FormBuilder, FormControl, FormGroup, Validators} from "@angular/forms";
import {ActivatedRoute, Router} from "@angular/router";
import {debounceTime, finalize, forkJoin, Subscription} from "rxjs";
import {Group, GroupConfig} from "../../groups.component";
import {GroupComponent} from "../group.component";
import {NotificationService} from "../../../notification/notification.service";
import {LINK_GROUPS} from "../../../app.urls";
import {DialogApprove} from "../../../common/approve.component";
import {MatDialog} from "@angular/material/dialog";
import {MatSnackBar} from "@angular/material/snack-bar";
import {ApiService} from "../../../api.service";
import {Station, StationConfig} from "../../../stations/stations.component";
import {DialogInfo} from "../../../common/info.component";
import {environment} from "../../../../environments/environment";


export type ReceiverSettingsInterface = "shmem";
export type ReceiverSettingsArrayType = "ULA" | "UCA" | "Custom";
export type ReceiverSettingsUlaDirection = "Both" | "Backward" | "Forward";
export type ReceiverSettingsDoaMethod = "MUSIC" | "Bartlett" | "Capon" | "MEM" | "TNA";
export type ReceiverSettingsDecorelation = "Off" | "FBA" | "TOEP" | "FBSS" | "FBTOEP";
export type ReceiverSettingsDoaFigType = "Compass" | "Linear" | "Polar";
export type ReceiverSettingsDoaDataFormat = "Kraken App"
                                          | "Kraken Pro Local"
                                          | "Kraken Pro Remote"
                                          | "Eter Cloud"
                                          | "Kerberos App"
                                          | "DF Aggregator"
                                          | "RDF Mapper"
                                          | "Full POST";
export type ReceiverSettingsLocationSource = "Static" | "None" | "gpsd";
export type ReceiverSettingsSpectrumCalc = "Single" | "All";
export type ReceiverSettingsVfoMode = "Standard" | "Auto";

interface ReceiverSettings {
  center_freq: number;
  uniform_gain: number;
  en_doa: boolean;
  ant_arrangement: ReceiverSettingsArrayType;
  ula_direction: ReceiverSettingsUlaDirection;
  ant_spacing_meters: number;
  doa_fig_type: ReceiverSettingsDoaFigType;
  vfo_mode: ReceiverSettingsVfoMode;
  vfo_bw_0: number;
  vfo_freq_0: number;
  vfo_squelch_0: number;
}


@Component({
  encapsulation: ViewEncapsulation.None,
  selector: 'app-group-settings',
  templateUrl: './group-settings.component.html',
  styleUrls: ['./group-settings.component.scss']
})
export class GroupSettingsComponent implements OnDestroy, AfterViewInit {

  protected groupConfig: GroupConfig | undefined;
  protected group: Group | undefined;

  protected watcher: Subscription | undefined;
  protected watcherFrequency: Subscription | undefined;
  protected watcherVfoFrequency: Subscription | undefined;

  public form: FormGroup = new FormGroup({
    overwriteSettings: new FormControl(false),

    name: new FormControl(null, [Validators.required]),

    center_freqDiff: new FormControl(),
    center_freq: new FormControl(null, [Validators.required]),

    uniform_gainDiff: new FormControl(),
    uniform_gain: new FormControl(null, [Validators.required]),

    en_doaDiff: new FormControl(),
    en_doa: new FormControl('', [Validators.required]),

    ant_arrangementDiff: new FormControl(),
    ant_arrangement: new FormControl('', [Validators.required]),

    ula_directionDiff: new FormControl(),
    ula_direction: new FormControl('', [Validators.required]),

    ant_spacing_metersDiff: new FormControl(),
    ant_spacing_meters: new FormControl('', [Validators.required]),

    doa_fig_typeDiff: new FormControl(),
    doa_fig_type: new FormControl('', [Validators.required]),

    vfo_modeDiff: new FormControl(),
    vfo_mode: new FormControl('', [Validators.required]),

    vfo_bw_0Diff: new FormControl(),
    vfo_bw_0: new FormControl('', [Validators.required]),

    vfo_freq_0Diff: new FormControl(),
    vfo_freq_0: new FormControl('', [Validators.required]),

    vfo_squelch_0Diff: new FormControl(),
    vfo_squelch_0: new FormControl('', [Validators.required]),

  });

  protected gainParams = [
      {"label": "0 dB", "value": 0},
      {"label": "0.9 dB", "value": 0.9},
      {"label": "1.4 dB", "value": 1.4},
      {"label": "2.7 dB", "value": 2.7},
      {"label": "3.7 dB", "value": 3.7},
      {"label": "7.7 dB", "value": 7.7},
      {"label": "8.7 dB", "value": 8.7},
      {"label": "12.5 dB", "value": 12.5},
      {"label": "14.4 dB", "value": 14.4},
      {"label": "15.7 dB", "value": 15.7},
      {"label": "16.6 dB", "value": 16.6},
      {"label": "19.7 dB", "value": 19.7},
      {"label": "20.7 dB", "value": 20.7},
      {"label": "22.9 dB", "value": 22.9},
      {"label": "25.4 dB", "value": 25.4},
      {"label": "28.0 dB", "value": 28.0},
      {"label": "29.7 dB", "value": 29.7},
      {"label": "32.8 dB", "value": 32.8},
      {"label": "33.8 dB", "value": 33.8},
      {"label": "36.4 dB", "value": 36.4},
      {"label": "37.2 dB", "value": 37.2},
      {"label": "38.6 dB", "value": 38.6},
      {"label": "40.2 dB", "value": 40.2},
      {"label": "42.1 dB", "value": 42.1},
      {"label": "43.4 dB", "value": 43.4},
      {"label": "43.9 dB", "value": 43.9},
      {"label": "44.5 dB", "value": 44.5},
      {"label": "48.0 dB", "value": 48.0},
      {"label": "49.6 dB", "value": 49.6}
  ];

  protected vfoParams: { label: string, value: string }[] = [
    {label: "Стандартний", value: "Standard"},
    {label: "Авто (Макс)", value: "Auto"}
  ];

  constructor(private route: ActivatedRoute, private router: Router,
              private formBuilder: FormBuilder,
              private loaderService: LoaderService,
              private cdr: ChangeDetectorRef,
              private apiService: ApiService,
              private notificationService: NotificationService,
              private snackBar: MatSnackBar,
              public dialog: MatDialog,
              @Inject(GroupComponent) protected parent: GroupComponent) {
  }

  ngOnDestroy() {
    this.destroyWatchers();
  }

  downloadHistory() {
    let timestamp = new Date().getTime();
    if (window.hasOwnProperty("showSaveFilePicker")) {
      (window as any).showSaveFilePicker({
        startIn: 'downloads',
        suggestedName: `${this.group?.name || 'log'}-${timestamp}.csv`
      }).then((handle: any) => handle.createWritable().then(
        (writable: any) => {
          if (this.group?.stations) {
            const serials = this.group.stations.map((station: Station) => {return station.serial});
            this.apiService.downloadHistory(serials, writable);
          } else {
            this.snackBar.open('Не вдалось отримати комплекс ID!', 'Ок', {duration: 5000});
          }
        }
      ));
    } else {
      if (this.group?.stations) {
        const serials = this.group?.stations.map((station: Station) => {return station.serial});
        this.dialog.open(DialogInfo, {
          data: {
            title: 'Історія пеленгації',
            paragraphs: [
              `Увага, ваш пристрій не підтримує FileAPI!`,
              'Спочатку додаток завантажить інформацію на носій пристрою.',
              'Після цього ви побачите вікно з параметрами збереження.'
            ]
          }
        }).afterClosed().subscribe({
          next: (next: boolean|undefined) => {
            if (next) {
              this.apiService.downloadHistoryBlob(
                serials, `${this.group?.name || 'log'}-${timestamp}.csv`
              );
            }
          }
        });
      }
    }
  }

  deleteGroup() {
    this.dialog.open(DialogApprove,
      {data: {title:"Видалити", paragraph: "Ви впевнені що ви хочете це зробити?"}}
    ).afterClosed().subscribe({next: value => {
      if (value) {
        this.loaderService.startLoading();
        if (this.group?.id) {
          this.apiService.deleteGroup(this.group.id).pipe(finalize(() => {
            this.loaderService.stopLoading();
          })).subscribe({
            next: _ => {
              this.snackBar.open('Комплекс видалено!', 'Ок', {duration: 7000});
              this.router.navigateByUrl(LINK_GROUPS);
            },
            error: err => this.snackBar.open('Щось пішло не так!', 'Ок', {duration: 7000})
          })
        }
      }
    }});
  }

  groupConfigFromStationConfig(id: number, name: string, stationConfig?: StationConfig): GroupConfig|undefined {
    return stationConfig ? {
      id: id,
      name: name,
      center_freq: stationConfig.center_freq,
      uniform_gain: stationConfig.uniform_gain,
      en_doa: stationConfig.en_doa,
      ant_arrangement: stationConfig.ant_arrangement,
      ula_direction: stationConfig.ula_direction,
      ant_spacing_meters: stationConfig.ant_spacing_meters,
      array_offset: stationConfig.array_offset,
      compass_offset: stationConfig.compass_offset,
      doa_fig_type: stationConfig.doa_fig_type,
      vfo_mode: stationConfig.vfo_mode,
      vfo_bw_0: stationConfig.vfo_bw_0,
      vfo_freq_0: stationConfig.vfo_freq_0,
      vfo_squelch_0: stationConfig.vfo_squelch_0
    } : undefined;
  }

  public renderConfig(group: Group) {
    this.loaderService.startLoading();
    const patch: {[key: string]: any} = {};
    const diffs: any = {};
    this.group = group;
    let firstStationConfig: GroupConfig|undefined;
    if (group.stations.length > 0) {
      const firstStation = group.stations[0];
      firstStationConfig = this.groupConfigFromStationConfig(group.conf.id, group.name, firstStation.conf);
      for (const item of group.stations) {
        if (item.conf?.center_freq !== firstStationConfig?.center_freq) {
          patch['center_freqDiff'] = true;
          diffs.center_freq = true;
        }
        if (item.conf?.uniform_gain !== firstStationConfig?.uniform_gain) {
          patch['uniform_gainDiff'] = true;
          diffs.uniform_gain = true;
        }
        if (item.conf?.en_doa !== firstStationConfig?.en_doa) {
          patch['en_doaDiff'] = true;
          diffs.en_doa = true;
        }
        if (item.conf?.ant_arrangement !== firstStationConfig?.ant_arrangement) {
          patch['ant_arrangementDiff'] = true;
          diffs.ant_arrangement = true;
        }
        if (item.conf?.ula_direction !== firstStationConfig?.ula_direction) {
          patch['ula_directionDiff'] = true;
          diffs.ula_direction = true;
        }
        if (item.conf?.ant_spacing_meters !== firstStationConfig?.ant_spacing_meters) {
          patch['ant_spacing_metersDiff'] = true;
          diffs.ant_spacing_meters = true;
        }
        if (item.conf?.doa_fig_type !== firstStationConfig?.doa_fig_type) {
          patch['doa_fig_typeDiff'] = true;
          diffs.doa_fig_type = true;
        }
        if (item.conf?.vfo_mode !== firstStationConfig?.vfo_mode) {
          patch['vfo_modeDiff'] = true;
          diffs.vfo_mode = true;
        }
        if (item.conf?.vfo_bw_0 !== firstStationConfig?.vfo_bw_0) {
          patch['vfo_bw_0Diff'] = true;
          diffs.vfo_bw_0 = true;
        }
        if (item.conf?.vfo_freq_0 !== firstStationConfig?.vfo_freq_0) {
          patch['vfo_freq_0Diff'] = true;
          diffs.vfo_freq_0 = true;
        }
        if (item.conf?.vfo_squelch_0 !== firstStationConfig?.vfo_squelch_0) {
          patch['vfo_squelch_0Diff'] = true;
          diffs.vfo_squelch_0 = true;
        }
      }
      this.groupConfig = firstStationConfig;
      this.form.patchValue({...firstStationConfig});
      this.form.patchValue(patch);
      this.createWatchers(diffs);
      // this.cdr.detectChanges();
    } else {
      this.form.controls['overwriteSettings'].disable();
      this.groupConfig = group.conf;
    }
    this.form.patchValue(group);
    this.cdr.detectChanges();
    this.loaderService.stopLoading();
  }

  ngAfterViewInit() {
  }

  save(): void {
    if (this.parent.item) {
      this.loaderService.startLoading();
      const dataRawForm = this.form.getRawValue();

      let updateGroup: any = {};
      updateGroup.name = dataRawForm.name;

      let updateGroupConfig: any = {};
      if (dataRawForm.overwriteSettings) {
          updateGroupConfig = {...dataRawForm};
      } else {
          if (!dataRawForm.center_freqDiff) {
              updateGroupConfig.center_freq = dataRawForm.center_freq;
          }
          if (!dataRawForm.uniform_gainDiff) {
              updateGroupConfig.uniform_gain = dataRawForm.uniform_gain;
          }
          if (!dataRawForm.en_doaDiff) {
              updateGroupConfig.en_doa = dataRawForm.en_doa;
          }
          if (!dataRawForm.ant_arrangementDiff) {
              updateGroupConfig.ant_arrangement = dataRawForm.ant_arrangement;
          }
          if (!dataRawForm.ula_directionDiff) {
              updateGroupConfig.ula_direction = dataRawForm.ula_direction;
          }
          if (!dataRawForm.ant_spacing_metersDiff) {
              updateGroupConfig.ant_spacing_meters = dataRawForm.ant_spacing_meters;
          }
          if (!dataRawForm.doa_fig_typeDiff) {
              updateGroupConfig.doa_fig_type = dataRawForm.doa_fig_type;
          }
          if (!dataRawForm.vfo_modeDiff) {
              updateGroupConfig.vfo_mode = dataRawForm.vfo_mode;
          }
          if (!dataRawForm.vfo_bw_0Diff) {
              updateGroupConfig.vfo_bw_0 = dataRawForm.vfo_bw_0;
          }
          // TODO(s1z): fix this if the diff vs center_freq > 2.4 / 2
          if (!dataRawForm.vfo_freq_0Diff) {
              updateGroupConfig.vfo_freq_0 = dataRawForm.vfo_freq_0;
          }
          if (!dataRawForm.vfo_squelch_0Diff) {
              updateGroupConfig.vfo_squelch_0 = dataRawForm.vfo_squelch_0;
          }
      }
      if (this.groupConfig?.id) {
        let observables = [];
        if (updateGroup.name != this.parent.item.name) {
          observables.push(this.apiService.patchGroup(this.parent.item.id, updateGroup));
        }
        observables.push(this.apiService.patchGroupConfig(this.groupConfig.id, updateGroupConfig));
        forkJoin(observables).pipe(finalize(() => {
          this.loaderService.stopLoading();
          this.parent.loadGroup();
          this.form.markAsPristine();
        })).subscribe({
          next: _ => this.snackBar.open('Збережено!', 'Ок', {duration: 7000}),
          error: _ => this.snackBar.open('Щось пішло не так!!', 'Ок', {duration: 7000}),
        });
      }
    }
  }

  setAntArrangement(diffs: any = {}) {
    const overwriteSettings = this.form.controls['overwriteSettings']?.value;
    const isCustomAnt = this.form.controls['ant_arrangement']?.value === "Custom";
    for (const field of Object.keys(diffs)) {
      if (!overwriteSettings) {
        this.form.controls[field]?.disable();
      } else {
        this.form.controls[field]?.enable();
      }
    }
    if (isCustomAnt) {
      if (!overwriteSettings) {
        this.form.controls['ant_arrangement']?.disable();
      } else {
        this.form.controls['ant_arrangement']?.enable();
      }
    }
  }

  createWatchers(diffs: any = {}) {
    this.setAntArrangement(diffs);
    if (this.watcher !== undefined) {
      this.watcher.unsubscribe();
    }
    this.watcher = this.form.controls['overwriteSettings']?.valueChanges.pipe(debounceTime(100)).subscribe((proMode) => {
      this.setAntArrangement(diffs);
    });
    this.watcherFrequency = this.form.controls["center_freq"]?.valueChanges.pipe(debounceTime(100)).subscribe(data => {
      if (!this.form.controls["vfo_freq_0Diff"]?.value) {
        this.form.controls["vfo_freq_0"]?.patchValue(data, {emitEvent: false});
      }
    });
    this.watcherVfoFrequency = this.form.controls['vfo_freq_0']?.valueChanges.pipe(debounceTime(100)).subscribe(data => {
      if (!this.form.controls["center_freqDiff"]?.value) {
        this.form.controls["center_freq"]?.patchValue(data, { emitEvent: false});
      }
    });
  }

  destroyWatchers() {
    this.watcher?.unsubscribe();
    this.watcherFrequency?.unsubscribe();
    this.watcherVfoFrequency?.unsubscribe();
  }

}
