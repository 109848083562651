<div class="container-fluid">
  <div class="row">
    <div class="col-md-6">
      <div><h3 class="p-1-0em margin-0">Додані пеленгатори:</h3></div>
      <mat-paginator
                     [showFirstLastButtons]="true"
                     aria-label="Оберіть сторінку"
                     class="paginator-no-text"/>
      <div mat-ripple class="station-small"
           *ngFor="let item of parent.item?.stations"
           (click)="removeGroupStation(parent.item?.id,  item.id)">
        <span>{{ item.serial }} </span>
        <span>{{ item.name }}</span>
      </div>
    </div>
    <div class="col-md-6">
      <div><h3 class="p-1-0em margin-0">Доступні пеленгатори:</h3></div>
      <mat-paginator *ngIf="paginator && paginator.totalObjects > 0"
                     [length]="paginator.totalObjects"
                     [pageIndex]="paginator.currPage"
                     [pageSize]="paginator.pageSize"
                     (page)="handlePageEvent($event)"
                     [showFirstLastButtons]="true"
                     aria-label="Оберіть сторінку"
                     class="paginator-no-text"/>
      <div mat-ripple class="station-small"
           *ngFor="let item of items"
           (click)="addGroupStation(item, parent.item?.id,  item.id)">
        <span>{{ item.serial }}</span>
        <span>{{ item.name }}</span>
      </div>
    </div>
  </div>
</div>
