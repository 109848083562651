import { Component } from '@angular/core';

@Component({
  selector: 'app-root',
  template: `
    <router-outlet/>
    <app-notification/>
    <app-async-progress-bar/>
  `
})
export class AppComponent {

}
