
import { Injectable } from '@angular/core';
import { HttpClient,
         HttpResponse } from '@angular/common/http';
import {last, Observable} from 'rxjs';
import {
  DRAWER_NAME,
  IS_SUPER_USER,
  LAST_GROUP_IDS,
  LAST_MAP_GROUP_ID,
  LAST_STATION_IDS,
  SESSION_ID
} from "./auth/constants";
import {HttpService} from "./http.service";


@Injectable()
export class StorageService extends HttpService {

  private storage: Storage;

  constructor(http: HttpClient) {
    super(http);
    this.storage = localStorage;
  }

  public setDrawerVisible(isVisible: boolean): void {
    this.storage.setItem(DRAWER_NAME, isVisible ? 'yes': 'no');
  }

  public getDrawerVisible(): boolean {
    return this.storage.getItem(DRAWER_NAME) === 'yes';
  }

  public setItem(key: string, value: string): void {
    this.storage.setItem(key, value);
  }

  public setSuperuser(isSuperuser: boolean) {
    this.storage.setItem(IS_SUPER_USER, isSuperuser ? "yes" : "no");
  }

  public getSuperuser(): boolean {
    return this.storage.getItem(IS_SUPER_USER) == "yes";
  }

  public setSession(sessionId: string): void {
    this.storage.setItem(SESSION_ID, sessionId);
  }

  public getSession(): string | null {
    return this.storage.getItem(SESSION_ID);
  }

  public clearSession(): void {
    this.storage.removeItem(SESSION_ID);
  }

  public hasSession(): boolean {
    return !!this.getSession();
  }

  public removeSession(): void {
    return this.storage.removeItem(SESSION_ID);
  }

  public getLastGroups(): number[] {
    const ids = this.storage.getItem(LAST_GROUP_IDS);
    if (ids !== null) {
      return JSON.parse(ids);
    }
    return [];
  }

  public setLastGroups(ids: number[]) {
    this.storage.setItem(LAST_GROUP_IDS, JSON.stringify(ids));
  }

  public addLastGroup(groupId: number) {
    const ids = this.getLastGroups();
    const oldIndex = ids.indexOf(groupId);
    if (oldIndex !== -1) {
      ids.splice(oldIndex, 1);
    }
    ids.splice(0, 0, groupId);
    ids.splice(2, ids.length - 2);
    this.setLastGroups(ids);
  }

  public getLastStations(): number[] {
    const ids = this.storage.getItem(LAST_STATION_IDS);
    if (ids !== null) {
      return JSON.parse(ids);
    }
    return [];
  }

  public getLastGroupId(): number|undefined {
    const id = this.storage.getItem(LAST_MAP_GROUP_ID);
    if (id !== null) {
      return parseInt(id);
    }
    return undefined;
  }


  public setLastGroupId(id: number): void {
    this.storage.setItem(LAST_MAP_GROUP_ID, id.toString());
  }

  public setLastStations(ids: number[]) {
    this.storage.setItem(LAST_STATION_IDS, JSON.stringify(ids));
  }

  public addLastStation(stationId: number) {
    const ids = this.getLastStations();
    const oldIndex = ids.indexOf(stationId);
    if (oldIndex !== -1) {
      ids.splice(oldIndex, 1);
    }
    ids.splice(0, 0, stationId);
    ids.splice(3, ids.length - 3);
    this.setLastStations(ids);
    console.log(`ids: ${ids}`);
  }

}
