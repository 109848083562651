import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';

import { AppRoutingModule } from './app-routing.module';
import {AuthModule} from "./auth/auth.module";
import { AppComponent } from './app.component';
import {BrowserAnimationsModule} from "@angular/platform-browser/animations";
import { MainComponent } from './main/main.component';
import {NotificationModule} from "./notification/notification.module";
import {LoaderService} from "./loader.service";
import {NavbarComponent} from "./navbar/navbar.component";
import {MatSidenavModule} from "@angular/material/sidenav";
import {MatListModule} from "@angular/material/list";
import {MatButtonModule} from "@angular/material/button";
import { GroupsComponent } from './groups/groups.component';
import {MatToolbarModule} from "@angular/material/toolbar";
import {StationsComponent} from "./stations/stations.component";
import {MapComponent} from "./map/map.component";
import { BreadcrumbsComponent } from './breadcrumbs/breadcrumbs.component';
import {MatIconModule} from "@angular/material/icon";
import {MatCardModule} from "@angular/material/card";
import {MatRippleModule} from "@angular/material/core";
import { StationPreviewComponent } from './stations/station-preview/station-preview.component';
import {AppStationStatusClassPipe, AppStationStatusPipe} from "./common/app.station-status.pipe";
import { StationComponent } from './stations/station/station.component';
import {MatTabsModule} from "@angular/material/tabs";
import {MatInputModule} from "@angular/material/input";
import {MatSelectModule} from "@angular/material/select";
import {MatDialogModule} from "@angular/material/dialog";
import {FormsModule, ReactiveFormsModule} from "@angular/forms";
import {StationSettingsComponent} from "./stations/station/station-settings/station-settings.component";
import { StationSpectrumComponent } from './stations/station/station-spectrum/station-spectrum.component';
import {MatSlideToggleModule} from "@angular/material/slide-toggle";
import { ScannersComponent } from './scanners/scanners.component';
import {GroupPreviewComponent} from "./groups/group-preview/group-preview.component";
import {GroupSettingsComponent} from "./groups/group/group-settings/group-settings.component";
import {GroupSpectrumComponent} from "./groups/group/group-spectrum/group-spectrum.component";
import {GroupComponent} from "./groups/group/group.component";
import {MatRadioModule} from "@angular/material/radio";
import {MatCheckboxModule} from "@angular/material/checkbox";
import * as PlotlyJS from 'plotly.js-dist-min';
import { PlotlyModule } from 'angular-plotly.js';
import {HTTP_INTERCEPTORS, HttpClientModule, HttpClientXsrfModule} from "@angular/common/http";
import {AuthorizationInterceptor} from "./authorization.interceptor";
import {ApiService} from "./api.service";
import {AppStationUptimePipe} from "./common/app.station-uptime.pipe";
import {MatMenuModule} from "@angular/material/menu";
import { GroupAddStationComponent } from './groups/group/group-add-station/group-add-station.component';
import {MatPaginatorIntl, MatPaginatorModule} from "@angular/material/paginator";
import {uaPaginatorIntl} from "./common/app.paginator";
import {SpectrumComponent} from "./common/station-spectrum/spectrum.component";
import {MatSnackBarModule} from "@angular/material/snack-bar";
import { SettingsComponent } from './settings/settings.component';
import {MatProgressBarModule} from "@angular/material/progress-bar";
import { AsyncProgressBarComponent } from './async-progress-bar/async-progress-bar.component';
import {AsyncProgressBarService} from "./async-progress-bar/async-progress-bar.service";
import {StationsDoAService} from "./stations-doa.service";


PlotlyModule.plotlyjs = PlotlyJS;

@NgModule({
  declarations: [
    AppStationStatusPipe,
    AppStationStatusClassPipe,
    AppStationUptimePipe,
    // App
    AppComponent,
    // Main
    MainComponent,
    // Map
    MapComponent,
    // Stations
    StationsComponent,
    // Station
    StationPreviewComponent,
    StationComponent,
    StationPreviewComponent,
    StationSettingsComponent,
    StationSpectrumComponent,
    // Groups
    GroupsComponent,
    // Group
    GroupPreviewComponent,
    GroupComponent,
    GroupPreviewComponent,
    GroupSettingsComponent,
    GroupSpectrumComponent,
    // Scanners
    ScannersComponent,
    GroupAddStationComponent,
    SpectrumComponent,
    SettingsComponent,
    // settings
    SettingsComponent,
    AsyncProgressBarComponent,
  ],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    AppRoutingModule,
    NotificationModule,
    AuthModule,
    NavbarComponent,
    MatSidenavModule,
    MatButtonModule,
    MatIconModule,
    MatListModule,
    MatToolbarModule,
    BreadcrumbsComponent,
    MatCardModule,
    MatRippleModule,
    MatTabsModule,
    MatInputModule,
    MatSelectModule,
    MatDialogModule,
    ReactiveFormsModule,
    MatSlideToggleModule,
    FormsModule,
    MatRadioModule,
    MatCheckboxModule,
    PlotlyModule,
    MatMenuModule,
    MatPaginatorModule,
    MatSnackBarModule,
    MatProgressBarModule
  ],
  providers: [
    ApiService,
    LoaderService,
    AsyncProgressBarService,
    StationsDoAService,
    { provide: MatPaginatorIntl, useValue: uaPaginatorIntl() },
    { provide: HTTP_INTERCEPTORS, useClass: AuthorizationInterceptor, multi: true},
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
