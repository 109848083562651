<div class="app-content">
  <app-navbar>
    <div class="app-content-breadcrumbs">
      <app-breadcrumbs>
        <ul>
          <li><a mat-button [routerLink]="LINK_MAIN">Головна</a></li>
          <li><a mat-button disabled>Пеленгатори</a></li>
        </ul>
        <a disabled extra mat-mini-fab color="primary" aria-label="Add" class="d-sm-flex d-md-none">
          <mat-icon>add</mat-icon>
        </a>

        <a disabled extended extra mat-fab color="primary" aria-label="Add" class="d-none d-md-flex">
          <mat-icon>add</mat-icon>
          Додати
        </a>
      </app-breadcrumbs>
    </div>
    <div class="app-content-container">
      <div class="page">
        <mat-paginator *ngIf="paginator && paginator.totalObjects > paginator.pageSize"
                       [length]="paginator.totalObjects"
                       [pageIndex]="paginator.currPage"
                       [pageSize]="paginator.pageSize"
                       (page)="handlePageEvent($event)"
                       [showFirstLastButtons]="true"
                       aria-label="Оберіть сторінку"/>
        <app-station-preview matRipple *ngFor="let item of items"
                             [routerLink]="LINK_STATIONS + '/' + item.id"
                             [id]="item.id"
                             [name]="item.name"
                             [status]="item.status"
                             [groupId]="item.groupId"
                             [groupName]="item.groupName"
                             [uptime]="item.uptime_run"
                             [serial]="item.serial"
                             [frequency]="item.conf?.center_freq"
                             [location]="item.location"
                             [connected]="item.connected"
                             [power]="item.power"
                             [battery]="item.battery"
                             [temperature_run]="item.temperature_run"
                             [temperature_gs]="item.temperature_gs"
                             [uplink_a]="item.uplink_a"
                             [uplink_b]="item.uplink_b"/>
        <mat-paginator *ngIf="paginator && paginator.totalObjects > paginator.pageSize"
                       [length]="paginator.totalObjects"
                       [pageIndex]="paginator.currPage"
                       [pageSize]="paginator.pageSize"
                       (page)="handlePageEvent($event)"
                       [showFirstLastButtons]="true"
                       aria-label="Оберіть сторінку"/>
        <div *ngIf="!done" class="no-items-memo">
          <div class="p-05em">
            <span>Завантажуємо, зачекайте...</span>
          </div>
        </div>
        <div *ngIf="items.length < 1 && done" class="no-items-memo">
          <div class="p-05em">
            <span>У вас ще немає жодного пеленгатора, або немає доступу до них.</span>
          </div>
          <h1 class="bummer">:(</h1>
        </div>
      </div>
<!--      <div class="page no-items">-->
<!--        <div>-->
<!--          You dont have groups yet!-->
<!--        </div>-->
<!--      </div>-->
    </div>
    <!--<div class="app-content-footer">hello! im footer</div>-->
  </app-navbar>
</div>
