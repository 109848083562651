<div class="app-content">
  <app-navbar>
    <div class="app-content-breadcrumbs">
      <app-breadcrumbs>
        <ul>
          <li><a mat-button [routerLink]="LINK_MAIN">Головна</a></li>
          <li><a mat-button disabled>Налаштування</a></li>
        </ul>
      </app-breadcrumbs>
    </div>
    <div class="app-content-container">
      <div class="page">
        <div class="tab-nav-header">
          <div class="tab-nav-item-name">
            <span class="item-name">
              <span>
                <span>Привіт, хакере :)</span>
              </span>
            </span>
          </div>
        </div>
        <mat-divider></mat-divider>
      </div>
    </div>
    <!--<div class="app-content-footer">hello! im footer</div>-->
  </app-navbar>
</div>
