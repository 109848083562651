import {environment} from "../environments/environment";

export const ROUTE_MAIN = '';
export const ROUTE_STATIONS = 'stations';
export const ROUTE_STATION = `${ROUTE_STATIONS}/:itemId`;
export const ROUTE_STATION_SETTINGS = `${ROUTE_STATIONS}/:itemId/settings`;
export const ROUTE_GROUPS = 'groups';
export const ROUTE_GROUP_NEW = `${ROUTE_GROUPS}/new`;
export const ROUTE_GROUP_ID = `${ROUTE_GROUPS}/:itemId`;
export const ROUTE_MAP = 'map';
export const ROUTE_SCANNERS = 'scanners';
export const ROUTE_SETTINGS = 'settings';

export const LINK_MAIN = `/${ROUTE_MAIN}`;
export const LINK_STATIONS = `/${ROUTE_STATIONS}`;
export const LINK_GROUPS = `/${ROUTE_GROUPS}`;
export const LINK_GROUP_NEW = `/${ROUTE_GROUP_NEW}`;
export const LINK_MAP = `/${ROUTE_MAP}`;
export const LINK_SCANNERS = `/${ROUTE_SCANNERS}`;
export const LINK_SETTINGS = `/${ROUTE_SETTINGS}`;


export const BACKEND_API = environment.BACKEND_API;
export const BACKEND_API_WS = environment.BACKEND_API_WS;
export const API_GENERAL = `${BACKEND_API}/api/v1/general`;
export const API_GET_STATIONS = `${BACKEND_API}/api/v1/stations`;
export const API_GET_STATIONS_FREE = `${BACKEND_API}/api/v1/stations-free`;
export const API_STATION = `${BACKEND_API}/api/v1/station`;
export const API_STATION_RESTART = `${BACKEND_API}/api/v1/station-restart`;
export const API_STATION_CONFIG = `${BACKEND_API}/api/v1/station-config`;
export const API_STATION_DAQ = `${BACKEND_API}/api/v1/station-config-daq`;
export const API_GET_GROUPS = `${BACKEND_API}/api/v1/groups`;
export const API_GROUP = `${BACKEND_API}/api/v1/group`;
export const API_GROUP_STATION = `${BACKEND_API}/api/v1/group/station`;
export const API_GROUP_CONFIG = `${BACKEND_API}/api/v1/group-config`;
export const API_SERVER_TIMESTAMP = `${BACKEND_API}/api/v1/server-timestamp`;
export const WEB_SOCKET_ENDPOINT = `${BACKEND_API_WS}/api/v1/ws`;
export const WEB_SOCKET_ENDPOINT_v1_1 = `${BACKEND_API_WS}/api/v1.1/ws`;
export const WEB_SOCKET_ENDPOINT_DOA_v1_1 = `${BACKEND_API_WS}/api/v1.1/doa`;
export const WEB_SOCKET_DOWNLOAD_HISTORY = `${BACKEND_API_WS}/station/api/v1/doa-history`;
