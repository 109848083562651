<mat-toolbar class="mat-elevation-z6 app-nav-panel">
  <button *ngIf="drawerButtonVisible" mat-icon-button aria-label="Menu button" (click)="drawerToggle(); ">
    <mat-icon>menu</mat-icon>
  </button>
  <a mat-button [routerLink]="LINK_MAIN">
    <span class="logo">ETER</span>
  </a>
  <span class="spacer"></span>
  <button mat-icon-button aria-label="Notifications" disabled="disabled">
    <mat-icon>notifications</mat-icon>
  </button>
  <button mat-icon-button aria-label="Console" disabled="disabled">
    <mat-icon>terminal</mat-icon>
  </button>
  <button mat-icon-button aria-label="More actions"
          [matMenuTriggerFor]="menu">
    <mat-icon>more_vert</mat-icon>
  </button>
  <mat-menu #menu="matMenu">
    <a mat-menu-item disabled>
      <mat-icon>settings</mat-icon>
      <span>Налаштування</span>
    </a>
    <button mat-menu-item (click)="showInfo()">
      <mat-icon>info</mat-icon>
      <span>Інформація</span>
    </button>
    <button mat-menu-item (click)="logout()">
      <mat-icon>account_circle</mat-icon>
      <span>Вийти</span>
    </button>
  </mat-menu>
</mat-toolbar>
<mat-drawer-container class="black-backdrop">
  <mat-drawer [mode]="drawerMode" (openedChange)="drawerToggle($event)" autoFocus="false" [opened]="drawerOpened" class="app-content-drawer mat-elevation-z20">
    <mat-list>
      <a [routerLink]="[LINK_STATIONS]" mat-list-item matRipple routerLinkActive="active">Пеленгатори</a>
      <a [routerLink]="[LINK_GROUPS]" mat-list-item matRipple routerLinkActive="active">Комплекси</a>
      <a [routerLink]="[LINK_MAP]" mat-list-item matRipple routerLinkActive="active">Мапа</a>
      <a mat-list-item matRipple routerLinkActive="active" disabled>Сканери</a>
    </mat-list>
  </mat-drawer>
  <mat-drawer-content><ng-content></ng-content></mat-drawer-content>
</mat-drawer-container>
