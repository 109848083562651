// export const BACKEND_API = 'https://eter.robustdev.com';
import {environment} from "../../environments/environment";

export const BACKEND_API = environment.BACKEND_API;

// The name of AUTH_TOKEN in Storage
export const AUTH_TOKEN         = 'token';
export const PERMISSION_TOKEN   = 'permissions';
export const BIND_TOKEN         = 'bind';
export const IS_SUPERUSER_TOKEN = 'is_superuser';

export const STATUS_SIGNED_IN   = 1;
export const STATUS_SIGNED_OUT  = 2;

export const ROUTER_SIGN_IN     = 'auth/sign-in';
export const ROUTER_SIGN_UP = 'auth/sign-up/:token';
export const ROUTER_SIGN_OUT    = 'auth/sign-out';
export const ROUTER_FORBIDDEN   = 'auth/forbidden';
export const ROUTER_SETTINGS    = 'settings';

export const PAGE_SIGN_IN       = `/${ROUTER_SIGN_IN}`;
export const PAGE_SIGN_UP = `/${ROUTER_SIGN_UP}`;
export const PAGE_SIGN_OUT      = `/${ROUTER_SIGN_OUT}`;
export const PAGE_FORBIDDEN     = `/${ROUTER_FORBIDDEN}`;
export const PAGE_SETTINGS      = `/${ROUTER_SETTINGS}`;

export const API_SIGN_IN           = `${BACKEND_API}/api/v1/sign-in`;
export const API_SIGN_UP           = `${BACKEND_API}/api/v1/sign-up`;
export const API_SIGN_UP_FIN       = `${BACKEND_API}/api/v1/sign-up-fin`;
export const API_SIGN_OUT          = `${BACKEND_API}/api/v1/sign-out`;
export const API_SETTINGS          = `${BACKEND_API}/api/v1/settings`;


export const ANONYMOUS_PAGES   = [PAGE_SIGN_IN, PAGE_SIGN_OUT];
