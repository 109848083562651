<h2 mat-dialog-title>Інформація</h2>
<mat-dialog-content class="mat-typography">
  <div class="container-fluid">
    <div class="row">
      <div class="col-sm-12 col-md-6">
        <div class="container-fluid">
          <div class="row">
            <div class="col text-center p-05em">
              <p class="margin-0">Система ціфрової обробки:</p>
            </div>
          </div>
          <div class="row"><div class="col-6 col-sm-6 text-right"><span>Оновлення:</span></div><div class="col-6 col-sm-6"><span>{{ kspParsed.updateRate?.toFixed(0) || '--' }} мс</span></div></div>
          <div class="row"><div class="col-6 col-sm-6 text-right"><span>Обробка:</span></div><div class="col-6 col-sm-6"><span>{{ kspParsed.latency || '--' }} мс</span></div></div>
          <div class="row"><div class="col-6 col-sm-6 text-right"><span>Затримка:</span></div><div class="col-6 col-sm-6"><span>{{ kspParsed.realLatency || '--' }} мс</span></div></div>
          <div class="row"><div class="col-6 col-sm-6 text-right"><span>Поточний сигнал:</span></div><div class="col-6 col-sm-6"><span>{{ kspParsed.maxAmplitude?.toFixed(2) }} дБ</span></div></div>
          <div class="row"><div class="col-6 col-sm-6 text-right"><span>Поточний кут:</span></div><div class="col-6 col-sm-6"><span>{{ kspParsed.doaMax }} °</span></div></div>
          <div class="row"><div class="col-6 col-sm-6 text-right"><span>Впевненість:</span></div><div class="col-6 col-sm-6"><span>{{ kspParsed.doaConfidence?.toFixed(2) }}</span></div></div>
          <div class="row" *ngIf="kspParsed?.location">
            <div class="col text-center p-05em">
              <p class="margin-0">Місцезнаходження:</p>
            </div>
          </div>
          <div class="row"><div class="col-6 col-sm-6 text-right"><span>Широта:</span></div><div class="col-6 col-sm-6"><span>{{ kspParsed.location?.latitude }}</span></div></div>
          <div class="row"><div class="col-6 col-sm-6 text-right"><span>Довгота:</span></div><div class="col-6 col-sm-6"><span>{{ kspParsed.location?.longitude }}</span></div></div>
          <div class="row"><div class="col-6 col-sm-6 text-right"><span>Напрямок:</span></div><div class="col-6 col-sm-6"><span>{{ kspParsed.location?.heading }}°</span></div></div>
        </div>
      </div>
      <div class="col-sm-12 col-md-6">
        <div class="container-fluid">
          <div class="row">
            <div class="col text-center p-05em">
              <p class="margin-0">Метрика RUN:</p>
            </div>
          </div>
          <div class="row">
            <div class="row"><div class="col-6 col-sm-6 text-right"><span>Температура:</span></div><div class="col-6 col-sm-6"><span>{{ kspParsed.metrics.run.temperature?.toFixed(0) || '--' }} C°</span></div></div>
            <div class="row"><div class="col-6 col-sm-6 text-right"><span>Вологість:</span></div><div class="col-6 col-sm-6"><span>{{ kspParsed.metrics.run.humidity?.toFixed(0) || '--' }} %</span></div></div>
          </div>
          <div class="row">
            <div class="col text-center p-05em">
              <p class="margin-0">Метрика GS:</p>
            </div>
          </div>
          <div class="row">
            <div class="row"><div class="col-6 col-sm-6 text-right"><span>Температура:</span></div><div class="col-6 col-sm-6"><span>{{ kspParsed.metrics.gs.temperature?.toFixed(0) }} C°</span></div></div>
            <div class="row"><div class="col-6 col-sm-6 text-right"><span>Вологість:</span></div><div class="col-6 col-sm-6"><span>{{ kspParsed.metrics.gs.humidity?.toFixed(0) }} %</span></div></div>
            <div class="row"><div class="col-6 col-sm-6 text-right"><span>Живлення:</span></div><div class="col-6 col-sm-6"><span>{{ kspParsed.metrics.gs.power !== null ? (kspParsed.metrics.gs.power? 'Так': 'Ні') : '--' }}</span></div></div>
            <div class="row"><div class="col-6 col-sm-6 text-right"><span>Батарея:</span></div><div class="col-6 col-sm-6"><span>{{ kspParsed.metrics.gs.battery }} %</span></div></div>
          </div>
        </div>
      </div>
    </div>
  </div>
</mat-dialog-content>
<mat-dialog-actions align="end">
  <button mat-button mat-dialog-close>OK</button>
</mat-dialog-actions>
